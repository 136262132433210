import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function AuthCallback() {

  const location = useLocation();

  const [code,setCode] = useState();

    useEffect(() => {
        const code = new URLSearchParams(location.search).get('code');
        setCode(code);
    },[])

    
  useEffect(() => {
    if (code != null) {
      window?.opener?.postMessage({ code: code, source: window.location.href });
      setTimeout(() => {
        window.close();
      }, 500);
    }
  }, [code]);

  return (
    <div>
        <div>Auth Code : {code}</div>
    </div>
  )
}
