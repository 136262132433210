import { axios } from "../core/axios.interceptor";

const baseURL = "secrets";

export async function getProxySecret() {
    const response = await axios.get(`/${baseURL}`);
    return response;
}

export async function generateProxySecret() {
    const response = await axios.post(`/${baseURL}`);
    return response;
}

export async function regenerateProxySecret() {
    const response = await axios.post(`/${baseURL}/regenerate`);
    return response;
}
