import { useEffect, useState } from "react";
import TestData from "../../../../components/data-collections/test-data/test-data";
import * as collectionService from '../../../../services/data-collection.service';
import _ from 'lodash';
import { flatten } from 'flat';
import { RestServiceConstants } from "../../../../constants/rest-service-constant";
import { flattenData } from "./test-data-utils";

const  TestDataController = (props) => {

    const { state, setState, resetErrorMessages, handleAuthorizeAgain, isAuthorizeAgain, setIsAuthorizeAgain ,
        selectedConnection, getColumnSchema, setIsTestedData, isChangedDataParams, setIsChangedDataParams, isShowHeader, isTestedData, setIsShowHeader,
        showSaveCollection, setIsTestedAfterSaveAppears} = props;

    const [authorizeAgain,setAuthorizeAgain] = useState(false);

    const { sourceNamePart4 , sysCollectionTypeId} = state;

    const [activeTab,setActiveTab] = useState(0);

    useEffect(() => {
        if (state.isStepCollectionParameter && state?.sysCollectionTypeId != 7) {
            getColumnSchema(null);
        }

        setState((prevState) => {
            return {
                ...prevState, isStepCollectionParameter: false,
                // testedDataColumnKeys: [], testedDataList: [],
                // testedDataTransformedList: [], testedDataTransformedColumneKeys: []
            }
        });

        // if(state?.testedDataList && state?.testedDataList.length == 0){
        //     setIsTestedData(false);
        // }
    }, [])

    useEffect(() => {
        if(isChangedDataParams){
            setIsTestedData(false)
        }
    },[isChangedDataParams])

    // it tests the connection of data collection
    const testDataCollection = () => {

        setState((prevState) => { return { ...prevState, isTestingConnection: true, testedDataList: [], testedDataColumnKeys:[] } });
        resetErrorMessages();
        setIsTestedData(false);
        setIsChangedDataParams(false);

        let testDataCollectionModal = {
            connectionUId: state.connectionUId,
            sysCollectionTypeId: state.sysCollectionTypeId,
            sourceName: state.sourceName,
            restRequestMethod: state.restRequestMethod,
            restRequestIsAsync: true,
            restBody: state.restBody,
            restSendAsCD: state.restSendAsCD,
            collectionParameters: state.collectionParameters,
            collectionColumns: state.collectionColumnSchema,
            uId : state.uId,
            returnFlatternData : state.sysCollectionTypeId === 7 ? !(state.returnRawData) : false,
            sourceNamePart4 : state.sourceNamePart4
        }

        collectionService.testDataCollection(testDataCollectionModal)
            .then((response) => {

                let errorMessage = '';
                if (response?.hasError){
                    errorMessage = response?.errorMessage;
                } 
                else if (response?.data?.errorMessage && response?.data?.data == null){
                    errorMessage = response?.data?.errorMessage;
                }
                else if(response?.data?.data == null && !response?.hasError && !response?.data?.errorMessage){
                    errorMessage = "The collection was tested successfully but data is not available for the given parameters.";
                }
                else if (response?.data?.data) {
                    if(response?.data?.statusCode == "Unauthorized"){
                        var authType = selectedConnection?.parameters.filter((i) => i.parameterType == RestServiceConstants.AUTH_TYPE)[0].parameterValue;
                        var grantType = selectedConnection?.parameters.filter((i) => i.parameterType == RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE)[0].parameterValue;
                        if(authType == "OAuth 2.0" && (grantType == "authorization_code" || grantType == "authorization_code_with_pkce")){
                            setIsAuthorizeAgain(true);
                            errorMessage = "Couldn't establish a secure connection!";
                        }
                    }
                    else if(response?.data?.statusCode == "Forbidden"){
                        setIsTestedData(true);
                            setIsTestedAfterSaveAppears(true);
                        errorMessage = response?.data?.errorMessage;
                    }
                    else if(response?.data?.data?.length === 0 || Object.keys(response?.data?.data).length === 0){
                        setIsTestedData(true);
                        setIsShowHeader(true);
                            setIsTestedAfterSaveAppears(true);
                        errorMessage = 'The collection was tested successfully but data is not available for the given parameters.';
                    }
                    else{
                        setIsTestedData(true);
                        setIsShowHeader(false);
                            setIsTestedAfterSaveAppears(true);
                        errorMessage = 'The collection was tested successfully.';

                        let testedDataColumnKeys = null;
                        let finalData;

                        if(state.returnRawData && !Array.isArray(response?.data?.data)){
                            let rawResponse = [];
                            rawResponse.push(response?.data?.data);
                            finalData = rawResponse;
                        }else{
                            finalData = response?.data?.data;
                        }

                        //finalData = flattenData(response?.data?.data)

                        // if(sysCollectionTypeId == 7){
                        //     if(sourceNamePart4){
                        //         var arrayPath = sourceNamePart4.split('.');
                        //         finalData = flattenData(response?.data?.data,arrayPath[0],arrayPath[1]);
                        //     }
                        //     else{
                        //         finalData = flattenData(response?.data?.data,"","");
                        //     }
                        // }
                        // else{
                        //     finalData = response?.data?.data;
                        // }
                        
                        if(finalData){
                            try{
    
                                if(!state.returnRawData){
                                    
                                        if (sysCollectionTypeId == 7){

                                            finalData = Object.keys(finalData).reduce((acc, key) => {
                                                finalData[key].forEach((value, index) => {
                                                if (!acc[index]) {
                                                    acc[index] = {};
                                                }
                                                // Skip rows with null values
                                                // if (value !== null) {
                                                    acc[index][key] = value;
                                                //}
                                                });
                                                return acc;
                                            }, []);
                                            
                                            testedDataColumnKeys = Object.keys(finalData[0] || {});
                                        }
                                        else {
                                            let maxPropsObject = {};
                                            let maxPropsCount = 0;

                                            finalData.forEach(obj => {
                                                const numProps = Object.keys(obj).length;
                                                if (numProps > maxPropsCount) {
                                                    maxPropsCount = numProps;
                                                    maxPropsObject = obj;
                                                }
                                            });
            
                                            testedDataColumnKeys = maxPropsObject;
                                            testedDataColumnKeys = (_.keys(testedDataColumnKeys));
                                        }

                                }

                                setState((prevState) => {
                                    return {
                                        ...prevState, testedDataList: finalData,
                                         testedDataColumnKeys: testedDataColumnKeys,
                                    }
                                });
                            }
                            catch(error){
                                console.log(error)
                                errorMessage = "The collection was tested successfully but data is not available for the given Data Json Path.";
                            }
                        }
                        else{
                            errorMessage = "The collection was tested successfully but data is not available for the given Data Json Path.";
                        }
                    }
                }
                setErrorMessage(errorMessage,false);
            })
    }

    function getDataByPath(obj, pathString) {
        const pathArray = pathString.split('.');
        try {
            return pathArray.reduce((acc, key) => {
                if (acc && typeof acc === 'object') {
                    if (Array.isArray(acc)) {
                        return acc.map(item => (item ? item[key] : null));
                    } else {
                        return acc[key];
                    }
                } else {
                    return null;
                }
            }, obj);
          } catch (error) {
            return null;
          }
    }

    // sets error message
    const setErrorMessage = (errorMessage, isTestingConnection) => {
        setState((prevState) => {
            return {
                ...prevState, testCollectionError: errorMessage,
                isTestingConnection: isTestingConnection
            }
        });
    }

    return (
        <TestData
            testDataCollection={testDataCollection}
            state={state}
            isAuthorizeAgain={isAuthorizeAgain}
            handleAuthorizeAgain={handleAuthorizeAgain}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isChangedDataParams={isChangedDataParams}
            isShowHeader={isShowHeader}
            isTestedData={isTestedData}
        />
    );
}

export default TestDataController;
