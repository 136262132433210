export const onOpen = () => {

};

export const openDialog = () => {
 
};

export const openDialogBootstrap = () => {
  
};

export const openDialogMUI = () => {
  
};

export const openDialogTailwindCSS = () => {
 
};

export const openAboutSidebar = () => {
 
};

export const run2 = () => {
 
};
