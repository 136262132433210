import { MenuItem } from "@mui/material";
import { RestServiceConstants } from "../../../../constants/rest-service-constant";
import { ASInput } from "../../../../shared/as-inputs/as-input";
import { useEffect } from "react";
import { isNullOrEmpty } from "../../../../services/data-source-validation";

export const RestAPIKey = (props) => {
    const { restApiKeyParameters, formData, isFormInvalid } = props;

    useEffect(() => {
        checkFormIsValid();
    },[formData])

    const _key = restApiKeyParameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.API_KEY_INPUTS.KEY)
    );

    const _value = restApiKeyParameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.API_KEY_INPUTS.VALUE)
    );

    const _send = restApiKeyParameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.API_KEY_INPUTS.SEND_AS)
    );

    const checkFormIsValid = () => {
        const key = (!!formData && !!formData[_key.parameterType] && formData[_key.parameterType]).value
        const value = (!!formData && !!formData[_value.parameterType] && formData[_value.parameterType]).value
        const send = (!!formData && !!formData[_send.parameterType] && formData[_send.parameterType]).value

        if(isNullOrEmpty(key) || isNullOrEmpty(value) || isNullOrEmpty(send)){
            isFormInvalid(true);
        }
        else{
            isFormInvalid(false)
        }

    };
    
    return (
        <>
            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[_key.parameterType] &&
                        formData[_key.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[_key.parameterType] &&
                        formData[_key.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ..._key,
                    displayName: _key.displayName || "Key *",
                }}
            />

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[_value.parameterType] &&
                        formData[_value.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[_value.parameterType] &&
                        formData[_value.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ..._value,
                    displayName: _value.displayName || "Value *",
                }}
            />

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[_send.parameterType] &&
                        formData[_send.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[_send.parameterType] &&
                        formData[_send.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ..._send,
                    displayName: _send.displayName || "Add Key/Value To *",
                }}
                selectInput={true}
            >
                <MenuItem value="Header">Header</MenuItem>
                <MenuItem value="Query String">Query String</MenuItem>
            </ASInput>
        </>
    );
};
