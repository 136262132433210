import { Box, Button, InputLabel, MenuItem, Modal, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as collectionService from '../../../services/data-collection.service';
import { useEffect, useState } from "react";
import { ASTextField } from "../../../shared/as-text-fields";
import moment from "moment";
import { ASButton } from "../../../shared/as-button/as-button";
import serverFunctions from "../utils2/serverFunctions.js";
import _ from "lodash"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import { ASDataCollections } from "../../../shared/menu-icons/as-data-collections";
import BoltIcon from '@mui/icons-material/Bolt';
import { ASheetPlugInLogoSvg } from "../../../constants/common-svgs";
import { AppEnum, ParameterTypeValue } from "../../../constants/app-enum";
import * as UserService from "../../../services/variable-service";
import { toast } from "react-toastify";
import '../plugin-parameters/plugin-parameters';
import SheetsOverlay from "../SheetsOverlay/sheets-overlay";
import { filterType } from "../../data-collections/column-selection-popup/column-selection-filter-type";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const CollectionFilter = (props) => {
    const { data, index } = props;

    const filterGroups = JSON.parse(data?.defaultValue)
    const isSort = data?.parameterName === "Sort" ? true : false

    const getFilterType = (value) => {
        return filterType.find((i) => i.type === "String").value.find((i) => i.value === value)

    }
    const openPopup = (sheetIndex) => {
        serverFunctions.openDialog().then().catch(alert);
      };

    return (
        <div key={index} style={{ marginTop: "5px" }}>
            <div className="filter-header">
                <div className="parameter-heading " >

                    <ArrowForwardIosIcon />{isSort ? "Sort by" : "Filter"}
                </div>

                <button onClick={openPopup}>edit</button>
            </div>
            {
                isSort ?
                    <div className="filter-box">
                        {filterGroups?.map((item, index) => {
                            return (
                                <div className="filter-value">
                                    {item.displayName} {item.value}
                                </div>
                            )
                        })}
                    </div>
                    : ""
            }
            <div className="filter-box">
                {(filterGroups?.filter(group => group?.filters?.length > 0))?.map((group, groupIndex) => (
                    <div key={groupIndex}>

                        {group?.filters?.map((item, conditionIndex) => {
                            return (
                                <div className="filter-value">
                                    <div className="" key={conditionIndex}>
                                        {item?.displayName} {getFilterType(item?.condition)?.name} 
                                         <span className="bold"> {item?.dateLabel ? item?.dateLabel : item?.valueLabel ? item?.valueLabel : item?.value}</span> 
                                        <span className="italic">{group?.filters.length !== conditionIndex + 1 ? ` ${item.operator}` : ""}</span> 

                                    </div>

                                </div>

                            )
                        })}
                        <div className="italic" style={{marginLeft:"15px"}}>{(filterGroups?.filter(group => group?.filters?.length > 0)).length !== groupIndex + 1 && group.operator}</div>
                    </div>
                    // </div>
                ))
                }
            </div>
        </div>
    )
}