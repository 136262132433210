import { func } from "prop-types";
import React, { useEffect } from "react";
import "./CheckoutPaymentArea.scss"
import { Button } from "@mui/material";
import CustomDropDown from "../../common/customDropDown/CustomDropDown";

export default function CheckoutPaymentArea(props){

    const { featureData, setDeveloperSetUp, userList , user, setUser, hoursList, hour, setHour, isDeveloperSetUp,
       developerPrice , totalPrice, onBuyPlan, pFlag, isUpdatePayment,updateSubscription,unusedAmount} = props;              

    return (
        <div className="description-box">
            <div className="plan-heading">
              <div className="plan-type">Plan Type</div>
              <div className="units">Units</div>
              <div className="amount">Amount </div>
            </div>

            {/* it id for plan description */}
            <div className="plan-discription-text">
              <div>
                <div className="plan-type_1">
                  <div>
                    {" "}
                    <div className="small-business">{featureData?.name}</div>
                    {featureData?.features?.map((item, index) => (
                      <>
                        <div className="normal-text" key={index}>
                          {item.featureDescription}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
                {!pFlag &&
                <div className="margin-view">
                  <div className="small-business">
                    Ask a consultant to Setup for you
                  </div>

                  <div className="normal-text">
                    We recommend to hire a consultant for end to end setup. {" "}
                  </div>
                  <div className="normal-text">
                    From establishing data connection to{" "}
                  </div>
                  <div className="normal-text">render data in your excel </div>
                </div>
                }
                {
                  !pFlag &&
                (isDeveloperSetUp ? 
                  <Button
                    variant="contained"
                    className="cancelButton"
                    onClick={() => setDeveloperSetUp(false)}
                  >
                    Cancel
                  </Button>
                 : 
                  <Button
                    variant="contained"
                    className="setUpButton"
                    onClick={() => setDeveloperSetUp(true)}
                  >
                    YES, I need SOMEONE to setup
                  </Button>)
                }
              </div>

              <div className={pFlag ? "units-value-update" : "units-value"}>
                <div>
                  {
                  featureData?.name == "Free" ? 
                  <div className="free">
                    <div className="free-value">{"1"}</div>
                    <div>user</div>
                  </div> :
                  <CustomDropDown
                    dropDownList={userList}
                    selectedValue={user}
                    setValue={setUser}
                  />
                  }
                </div>
                <div className="small-value">
                  {isDeveloperSetUp && (
                    <CustomDropDown
                      dropDownList={hoursList}
                      selectedValue={hour}
                      setValue={setHour}
                    />
                  )}
                </div>
              </div>
              <div className="amount-value">
                USD{" "}
                <span>
                  {featureData?.pricePeryear > 0
                    ? featureData?.pricePeryear * user
                    : 0}
                </span>{" "}
                <div className="amount-value-per-year">
                  (per year)
                  {isDeveloperSetUp && (
                    <div className="small-value-usd">
                      USD{" "}
                      <span>
                        {developerPrice > 0 ? developerPrice * hour : 0}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={pFlag ? "totalSummary-view-update" : "totalSummary-view"}>
              <div className="payment-amount-area">
                {unusedAmount > 0 && 
                <div className="sub-amount-value">
                  <div>Proration Adjustment</div>
                  <div>-{" "}USD{" "}
                  <span>
                    {unusedAmount}
                  </span>{" "}
                  </div>
                </div>}
                <div className="total-view">
                  {" "}
                  <div className="total-text">TOTAL</div>{" "}
                  <div className="font-value">
                    USD{" "}
                    <span className="total-text-value">
                      {totalPrice > 0 ? totalPrice : 0}
                    </span>
                  </div>{" "}
                </div>
                <Button variant="contained" className="varaintButton" disabled={!isUpdatePayment && pFlag} onClick={()=> pFlag ? updateSubscription() : onBuyPlan()}>
                  {pFlag ? "change subscription" : ((featureData?.name == "Free" && !isDeveloperSetUp) ? "start free Subscription" : "proceed to make payment")}
                </Button>
              </div>
            </div>
          </div>
    )
}