import { GASClient } from "gas-client";

const getSheets = () => {

}

const getActiveSheetName = () =>{

}

export const getSheetsData = () => {
 
};
 
export const addSheet = (sheetTitle) => {
 
};

export function createSheetAndAddData(data) {
  
}

export const deleteSheet = (sheetIndex) => {
  
};

export const setActiveSheet = (sheetName) => {
  
};

