import React, { useEffect, useMemo, useRef, useState } from 'react'
import "./column-selection-popup.scss"
import "./column-selection-header.scss"
import { ASButton } from '../../../shared/as-button/as-button'
import { Autocomplete, Box, Button, ButtonGroup, CardMedia, Chip, FormControl, MenuItem, OutlinedInput, Popper, Select, TextField, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import FilterPopupController from '../../../controllers/data-collections/column-selection/filter-popup/filter-popup-controller'
import MessageToaster from '../../../shared/message-toaster/message-toaster'

export default function ColumnSelectionHeader(props) {

    const { sortModal, filterModal, openSortModal, handleChangeObject, openFilterModal, closeFilterModal,
         handleSave, filterData, setFilterData, sortData, setSortData, columnsList, executeData, closeSortModal, 
         state, setState, selectedConnection, handleObjectChange, filterConfig, collectionFilterColumns, 
         closeLimitModal, openLimitModal, limitModal, limitData, setLimitData, handleAuthorizeAgain, isAuthorizeAgain, isOpenInDialog, showNativeDataVisualizer} = props;

    const isFilterSupported = !(state?.cloudCollectionObjects?.find(i => i.templateCollectionId == state.templateCollectionId)?.isFilterSupported);
    const isSortSupported = !(state?.cloudCollectionObjects?.find(i => i.templateCollectionId == state.templateCollectionId)?.isSortSupported);
    const isPagingSupported = !(state?.cloudCollectionObjects?.find(i => i.templateCollectionId == state.templateCollectionId)?.isPagingSupported);
    const isAllFilterNotSupported = (isFilterSupported && isSortSupported && isPagingSupported)

    const [searchQuery, setSearchQuery] = useState('');

    const transformGroupedData = (groupedData) => {
        return Object.entries(groupedData)
        .sort(([a], [b]) => a.localeCompare(b))
        .flatMap(([key, items]) => items.map(item => ({
            group: key,
            ...item,
        })));
    };
    
    const groupedObject = useMemo(() => {
        if (!state?.cloudCollectionObjects || state.cloudCollectionObjects.length == 0) {
            return {};
        }
        return state?.cloudCollectionObjects?.reduce((groups, item) => {
        const group = item.groupName || '';
        const subGroup = item.childGroupName || '';
        const key = subGroup ? `${group}->${subGroup}` : group;
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(item);
        return groups;
        }, {});
    }, [state?.cloudCollectionObjects]);
    
    const groupedOptions = useMemo(() => transformGroupedData(groupedObject), [groupedObject]);

    const CustomPopper = (props) => {
        return <Popper {...props} style={{ width: '', minWidth:'250px', maxWidth:'412px'}} placement="bottom-start" />;
    };

  return (
    <div className="column-selection-header-container">
        <div className="column-header-part-one">
            
            <div className="icon-image-div">
                <CardMedia
                            component="img"
                            sx={{ width: "auto !important", height: '65px' }}
                            image={`/assets/${selectedConnection?.connectionTypeIconImage}`}
                            alt={`${selectedConnection?.connectionTypeIconImage}`}
                        />
            </div>

            <div className={ showNativeDataVisualizer ? 'content-div-flex' : 'content-div' }>
                <div className="columns-selection-header-name-div">
                    <label>{selectedConnection?.connectionTypeName}{" "}{state?.collectionTypeList.filter(i => i.id == state?.sysCollectionTypeId)[0]?.typeName}</label>
                    <span>{" "}- {!showNativeDataVisualizer ? "Select Columns, Filter and Sort" : "Select Columns"}</span>
                </div>
                {
                    !showNativeDataVisualizer ? (
                                <div className="column-flex-div">
                            <div className="column-header-name-container">
                                {/* <CardMedia
                                    component="img"
                                    sx={{ width: "auto !important", height: '30px' }}
                                    image={`/assets/${selectedConnection?.connectionTypeIconImage}`}
                                    alt={`${selectedConnection?.connectionTypeIconImage}`}
                                /> */}
                                <div className="column-header-name">
                                    <span>Connect</span>

                                    <Autocomplete
                                        className="connector-name-label"
                                        PopperComponent={CustomPopper}
                                        sx={{
                                            width: '100% !important',
                                            boxShadow: 'none',
                                            marginTop: '-6px',
                                            '& .MuiInputBase-root': {
                                                padding: 0,
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                padding: 0,
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none !important',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none !important',
                                                },
                                                '& .MuiAutocomplete-input': {
                                                    color: "#16365f !important",
                                                    fontWeight: "700"
                                            }
                                            },
                                            '&:hover .MuiOutlinedInput-root': {
                                                border: '1px solid grey',
                                            },
                                            '& .MuiSelect-select': {
                                                padding: '0px 8px',
                                                marginTop: '1px'
                                            },
                                            '&::before': {
                                                borderBottom: 'none !important',
                                            },
                                            '&::after': {
                                                borderBottom: 'none !important',
                                            },
                                            '& .MuiBox-root': {
                                                width: '100%',
                                            },
                                            
                                        }}
                                        options={groupedOptions}
                                        groupBy={(option) => option.group}
                                        getOptionLabel={(option) => option.name}
                                        value={state?.cloudCollectionObjects.find(item => item.templateCollectionId === state?.templateCollectionId) || null}
                                        onChange={(event, newValue) => {
                                            handleObjectChange({ target: { value: newValue?.templateCollectionId } });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} key={option.templateCollectionId} sx={{ 
                                                whiteSpace: 'nowrap', 
                                                overflow: 'hidden', 
                                                // textOverflow: 'ellipsis',
                                                // width: '100%',
                                            }}>
                                                {option.name}
                                            </Box>
                                        )}
                                        renderGroup={(params) => (
                                            <div key={params.key}>
                                                <Box sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    // textOverflow: 'ellipsis',
                                                    // width: '100%',
                                                    fontWeight: 'bold', 
                                                    padding: '4px 8px',
                                                    color: "#16365f !important" 
                                                }}>
                                                    {params.group}
                                                </Box>
                                                {params.children}
                                            </div>
                                        )}
                                        isOptionEqualToValue={(option, value) => option.templateCollectionId === value.templateCollectionId}
                                    />
                                </div>
                            </div>
                    {isOpenInDialog && <div className='change-div-container'>
                        <Link to="#" className="change-link" onClick={() => handleChangeObject()}><span >Go Back</span></Link>
                    </div>}
                        </div>
                    ) : (
                        <></>
                    )
                }
            </div>
        </div>

        {((state?.cloudCollectionError && (state?.cloudCollectionError?.statusCode === "Unauthorized" || state?.cloudCollectionError?.httpStatus === 401 || state?.cloudCollectionError?.errorMessage === "Unauthorized")) || (state?.nativeCollectionError === "Unauthorized")) && 
            <div>
                <div className='pop-error-div'>
                    {<MessageToaster sx={{padding:"4px !important"}} errorMessage={"Unauthorized"} />}
                </div>
                <div>
                    <ASButton variant="contained" 
                            onClick={handleAuthorizeAgain} 
                            primary={true} 
                            disabled={state?.isTestingConnection}
                            sx={{ padding:"4px 13px" }}>
                                Authorize Again
                    </ASButton>
                </div>
            </div>}

        <div className="column-selection-header-btn-div">
            {!isAllFilterNotSupported && !showNativeDataVisualizer && <div className='filter-sort-btn-div'>

                <ButtonGroup variant="outlined" aria-label="Basic button group">
                    {/* <div className={`filter-div-header ${filterData?.length > 0 ? 'selected-filter-sort' : ''}`}> */}
                    <Tooltip title={isFilterSupported ? "Filter is not supported for this object" : ""}>
                        <div className={`filter-div-header ${filterData?.length > 0 ? 'selected-filter-sort' : ''}`}>
                            <Button onClick={() => openFilterModal()} disabled={isFilterSupported} 
                                    sx={{'&:disabled': {backgroundColor: '#efefef'}}}>
                                {<div>
                                    {filterData?.length > 0 ? <img src="/assets/filterWhite.svg" alt="Filter" /> : <img src="/assets/filter.svg" alt="Filter" />}
                                    <label>Filter</label>
                                </div>}
                            </Button>
                        
                            <FilterPopupController open={filterModal} 
                                            closeFilterModal={closeFilterModal}
                                            filterData={filterData}
                                            setFilterData={setFilterData}
                                            columnsList={columnsList}
                                            executeData={executeData}
                                            state={state}
                                            setState={setState}
                                            filterConfig={filterConfig}
                                            collectionFilterColumns={collectionFilterColumns}/>
                        </div>
                    </Tooltip>
                    <Tooltip title={isSortSupported ? "Sort is not supported for this object" : ""}>
                        <div className={`filter-div-header ${sortData?.length > 0 ? 'selected-sort' : ''}`}>
                            <Button onClick={() => openSortModal()} disabled={isSortSupported}
                                    sx={{'&:disabled': {backgroundColor: '#efefef'}}}>
                                    {<div>
                                        {sortData?.length > 0 ? <img src="/assets/sortWhite.svg" alt="Sort" /> : <img src="/assets/sort.svg" alt="Sort" />}
                                        <label>Sort</label>
                                    </div>}
                            </Button>
                            <FilterPopupController open={sortModal} 
                                            closeFilterModal={closeSortModal}
                                            sortData={sortData}
                                            setSortData={setSortData}
                                            columnsList={columnsList}
                                            executeData={executeData}
                                            isSort={true}
                                            state={state}
                                            setState={setState}
                                            filterConfig={filterConfig}/>
                        </div>
                    </Tooltip>
                    <Tooltip title={isPagingSupported ? "Limit is not supported for this object" : ""}>
                        <div  className={`filter-div-header ${limitData ? 'selected-sort' : ''}`}>
                            <Button onClick={() => openLimitModal()} disabled={isPagingSupported} sx={{'&:disabled': {backgroundColor: '#efefef'}}}>{<div>{limitData ? <img src="/assets/limitWhite.svg" alt="Limit" /> : <img src="/assets/limit.svg" alt="Limit" />}<label>Limit</label></div>}</Button>
                            <FilterPopupController open={limitModal} 
                                            closeLimitModal={closeLimitModal}
                                            limitData={limitData}
                                            setLimitData={setLimitData}
                                            columnsList={columnsList}
                                            executeData={executeData}
                                            isLimit={true}
                                            state={state}
                                            setState={setState}
                                            filterConfig={filterConfig}/>
                        </div>
                    </Tooltip>
                </ButtonGroup>
            </div>}
            <ASButton variant="contained" disabled={state?.cloudCollectionError || state?.nativeCollectionError ? true : false } sx={{ mr: 4 }} primary={true} onClick={() => handleSave()}>Save</ASButton>
            <div >
                {/* <Button > */}
                    {<div className='close-popup-div' onClick={() => handleChangeObject()}>
                        <img src="/assets/close.svg" alt="Close" className="close-icon"/>
                    </div>}
                {/* </Button> */}
            </div>
        </div>
    </div>
  )
}
