import { useEffect, useState } from "react";
import CollectionTypeAndObject from "../../../../components/data-collections/collection-type-and-object/collection-type-and-object";
import themeColor from "../../../../styles/_exports.module.scss";
import { RestServiceConstants } from "../../../../constants/rest-service-constant";
import { useParams } from "react-router-dom";

const CollectionTypeAndObjectController = (props) => {

    const { state, setState, prevStepRef ,selectedConnection, isRest, setIsChangedDataParams, isTestedData, setActiveStep, activeStep, isTemplateCompany, setIsTestedData, showSaveChangesButton } = props;
    const { connectionList , connectionUId } = props.state;

    const [sourceNameLabel,setSourceNameLabel] = useState("");
    const { collectionUId: collectionUId } = useParams();
    const [sendAs,setSendAs] = useState();

    const radioButtons = {
        name: "Send As",
        defaultValue: RestServiceConstants.HTTPMETHODS.SEND_AS_OPTION[0].value,
        radioButtons: RestServiceConstants.HTTPMETHODS.SEND_AS_OPTION,
        labelColor: themeColor.text_2,
        checkedLabelColor: themeColor.primaryColor,
    };

    radioButtons.defaultValue = sendAs;

    useEffect(() => {
        if(state?.restRequestMethod){
            setSendAs(state.restRequestMethod);
        }
        else{
            setSendAs(RestServiceConstants.HTTPMETHODS.SEND_AS_OPTION[0].value);
            setState((prevState) => { return { ...prevState, restRequestMethod : RestServiceConstants.HTTPMETHODS.SEND_AS_OPTION[0].value } });
        }
    },[state.restRequestMethod])

    useEffect(() => {
        if(selectedConnection?.templateConnectionId && (state.collectionTypeList.length <= 0)){
            setActiveStep(activeStep + 1);
        }
        if (selectedConnection?.templateConnectionId && (state.collectionTypeList.length == 1)){
            setState((prevState) => {
                return {
                    ...prevState, sysCollectionTypeId: state.collectionTypeList[0]?.id, uiLabel: state.collectionTypeList[0]?.uiLabel,
                    collectionColumns: collectionUId ? state?.collectionColumns : [] 
                }
            });
            setActiveStep(activeStep + 1);
        }

        setState((prevState) => { return { ...prevState, isStepCollectionParameter: false } });
        if(sendAs){
            setState((prevState) => { return { ...prevState, restRequestMethod : sendAs } });
        }
        
        if(isRest && !selectedConnection?.templateConnectionId){
            setState((prevState) => { return { ...prevState, sysCollectionTypeId : 7 } });
        }
    }, [])

    useEffect(() => {
        if(selectedConnection && isRest && !selectedConnection?.templateConnectionId){
            setSourceNameLabel(selectedConnection?.parameters.filter(i => i.parameterName == RestServiceConstants.API_ENDPOINT)[0]?.parameterValue);
        }
    }, [selectedConnection])

    // it sets the user's entered collection type id
    const onSelectCollectionTypeHandler = (sysCollectionTypeId) => {

        let uiLabel = state.collectionTypeList.filter(i => i.id === sysCollectionTypeId)[0]?.uiLabel;
        setState((prevState) => {
            return {
                ...prevState, sysCollectionTypeId: sysCollectionTypeId, uiLabel: uiLabel,
                collectionColumns: []
            }
        });
    }

    // it sets the user's entered collection type object value
    const onChangeCollectionObjectHandler = (event) => {
        setState((prevState) => { return { ...prevState, sourceName: event.target.value } });
        showSaveChangesButton(true);
        if(isTestedData){
            setIsChangedDataParams(true);
        }
    }

    const onChangeCollectionDataPathHandler = (event) => {
        setState((prevState) => { return { ...prevState, sourceNamePart4: event.target.value } });
        showSaveChangesButton(true);
        if(isTestedData){
            setIsChangedDataParams(true);
        }
    }

    const handleOnChangeSqlEditor = (value) => {
        value = value.replace(/\s+$/, '');
        setState((prevState) => {return {...prevState , sourceName : value }}) 
        showSaveChangesButton(true);
        if(isTestedData){
            setIsChangedDataParams(true);
        }
      };

    const onChangeRestRequestHandler = (event) => {
        showSaveChangesButton(true);
        setState((prevState) => { return { ...prevState, restRequestMethod: event.target.value } });
        setSendAs(event.target.value);
        if(isTestedData){
            setIsChangedDataParams(true);
        }
    }

    const onToggleDataFormat = (event) => {
        setState((prevState) => { return {
            ...prevState,
            returnRawData: event?.target?.checked,
        }});

        if(state?.testedDataList && state?.testedDataList.length > 0){
            setState((prevState) => { return { ...prevState, testedDataList: [], testedDataColumnKeys:[] } });
            setIsTestedData(false);
        }
    };

    const onChangeCollectionSubTypeHandler = (event) =>{
        setState((prevState) => { return {
            ...prevState,
            sysCollectionSubTypeId: event,
        }});
        showSaveChangesButton(true);
    }

    return (
        <CollectionTypeAndObject
            state={state}
            prevStepRef={prevStepRef}
            onSelectCollectionTypeHandler={onSelectCollectionTypeHandler}
            onChangeCollectionObjectHandler={onChangeCollectionObjectHandler}
            onChangeRestRequestHandler={onChangeRestRequestHandler}
            sourceNameLabel={sourceNameLabel}
            radioButtons={radioButtons}
            onChangeCollectionDataPathHandler={onChangeCollectionDataPathHandler}
            isRest={isRest}
            setState={setState}
			handleOnChangeSqlEditor={handleOnChangeSqlEditor}
			isCloudConnection={selectedConnection?.templateConnectionId ? true : false}
            onToggleDataFormat={onToggleDataFormat}
            isTemplateCompany={isTemplateCompany}
            onChangeCollectionSubTypeHandler={onChangeCollectionSubTypeHandler}
        />
    );
}

export default CollectionTypeAndObjectController;
