import React from "react";
import { Outlet } from "react-router-dom";

export function Connections() {
  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );

  //  <ConnectionsList sources={sources} />;
}
