import { Paper } from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";



export const SaveConnectionView = (props) => {
    const { formData, handleChange} = props;
    
    return (
      <Paper
        sx={{
          minHeight: "100px",
          width: "750px",
          padding: "20px",
        }}
      >
        <>
          <ASTextField
            className="text-field"
            label="Connection Name"
            variant="outlined"
            onChange={handleChange}
            value={!!formData && formData?.name?.value}
          />
        </>
      </Paper>
    );
  }