import { AsheetLogoExcelSvg } from "../../../constants/common-svgs";
import { ASButton } from "../../../shared/as-button/as-button";
import './successfull-connected.scss';
import { Button } from "@mui/material";

const SuccessfullConnectedView = (props) => {
    const { selectedConnection, disconnectConnection } = props;

    return (
        <>
            <div className="success-view-div">
                <label>
                    Successfully Connected to {selectedConnection?.description}
                </label>

                <div className="logo-div">
                    <span className="asheetlogo">{AsheetLogoExcelSvg}</span>
                    <img className="arrow-icon-div" src={`/assets/right-arrow.svg`} alt="" />
                    <img className="connection-logo" src={`/assets/${selectedConnection?.iconImage}`} alt={selectedConnection?.iconImage} />
                </div>

                <div className="collection-button-div">
                    <ASButton primary={true} > Add New Collection </ASButton>
                </div>

                <Button className="disconnect-button" onClick={() => disconnectConnection()}>
                    Disconnect
                </Button>
            </div>
        </>
    );
}
export default SuccessfullConnectedView;