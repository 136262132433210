import React, { useEffect, useState } from "react";
import { getDataSources } from "../../../services/data-source.service";
import { DataSourcesListView } from "../../../components/connections/connection-list/connection-list-view";
import { AppEnum } from "../../../constants/app-enum";
import { getRemainingBalance } from "../../../services/license-service";
import { useDispatch } from "react-redux";
import { setRemainingFeatureBalance } from "../../../store/slices/as-Slice";

export function ConnectionsList() {

  const [dataSources, setDataSources] = useState([]);
  const dispatch = useDispatch();
  const [responseReceived,setResponseReceived] = useState(false);

  useEffect(() => {
    dispatch(setRemainingFeatureBalance(null));

    getDataSources(null).then((res) => {
      if (res?.data) {

        setDataSources(
          res?.data.map((_e, index) => ({
            ..._e,
            key: Date.now() + index,
          }))
        )

        setResponseReceived(true);
      }
    })

    getRemainingBalance(AppEnum.FeatureType.CONNECTION_LIMIT).then((res) =>{
      if(res?.data?.featureCD != null){
        dispatch(setRemainingFeatureBalance(res.data.remainingFeatureUnit));
      }
    })
    
  }, []);

  return (
    <DataSourcesListView
      dataSources={dataSources}
      responseReceived={responseReceived}
    />
  );
}
