import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    remainingFeatureBalance: null,
    isExpandedView: false
};

// for general asheet states 
export const asSlice = createSlice({
    name: 'asState',
    initialState,
    reducers: {
        setRemainingFeatureBalance: (state, action) => {
            state.remainingFeatureBalance = action.payload;
        },
        setIsExpandedView: (state, action) => {
            state.isExpandedView = action.payload;
        },
    },
});


export const { setRemainingFeatureBalance, setIsExpandedView } = asSlice.actions;

export default asSlice.reducer;
