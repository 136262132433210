import moment from "moment";
import { AppEnum } from "../constants/app-enum";

export class DateParseVariable { 

    parseVariable = (variable) => {
        let date = "";
        switch(variable) {
            case AppEnum.SDVConstants.Today:
                date = moment();
                break;
            case AppEnum.SDVConstants.Yesterday:
                date = moment().subtract(1,"days");
                break;
            case AppEnum.SDVConstants.Tomorrow:
                date = moment().add(1,"days");
                break;
            case AppEnum.SDVConstants.StartDayOfThisWeek:
                date = moment().clone().startOf('isoWeek');
                break;
            case AppEnum.SDVConstants.EndDayOfTheWeek:
                date = moment().clone().endOf('isoWeek');
                break;
            case AppEnum.SDVConstants.PreviousBusinessDay:
                date = moment(moment().clone().startOf('isoWeek')).clone().subtract(3,"days");
                break;
            case AppEnum.SDVConstants.EndOfPreviousMonth:
                date = moment().subtract(1, 'months').endOf('month');
                break;
            case AppEnum.SDVConstants.EndOfPreviousQuarter:
                date = moment().clone().subtract(1, 'quarters').endOf('quarter');
                break;
            case AppEnum.SDVConstants.EndOfLastYear:
                date = moment().clone().subtract(1, 'years').endOf('year');
                break;
            case AppEnum.SDVConstants.StartOfThisMonth:
                date = moment().clone().startOf('month');
                break;
            case AppEnum.SDVConstants.StartOfThisQuarter:
                date = moment().clone().startOf('quarter');
                break;
            case AppEnum.SDVConstants.StartOfThisYear:
                date = moment().clone().startOf('year');
                break;
            case AppEnum.SDVConstants.EndOfThisYear:
                date = moment().clone().endOf('year');
                break;
            case AppEnum.SDVConstants.EndOfThisMonth:
                date = moment().clone().endOf('month');
                break;
            case AppEnum.SDVConstants.EndOfThisQuarter:
                date = moment().clone().endOf('quarter');
                break;
            default:
              // code block
          }
        
        return moment(date).format(AppEnum.DateFormats.Y_M_D);
    };
    
}