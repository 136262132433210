// import { BASE_API, TOKEN_SERVER_API } from "./api-url.service";
// import { http } from "./http-request.service";
import { axios } from "../core/axios.interceptor";
import { Environment } from "../core/environment";
import { tokenAxios } from "../core/tokenAPIInterceptor";

// // sends http request to token server to complete login process
// export function login(loginModel, isFormData) {
//     return http.post(TOKEN_SERVER_API, `connect/token`, loginModel, isFormData);
// }

// // saves new user's detail
// export function signUp(signupModel, isFormData) {
//     return http.post(BASE_API, `account`, signupModel, isFormData);
// }

// sends http request to token server to complete login process
export async function login(loginModel) {
    const response = await tokenAxios.post(`connect/token`, loginModel,{
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    });
    return response;
}

// saves new user's detail
export async function signUp(signupModel) {
    const response = await axios.post(`accounts`, signupModel);
    return response;
}

//sends the request to token server to clears the logged data
export async function logout(userId , clientIds) {
    const response = await tokenAxios.post(`user/logout?userId=${userId}`, clientIds);
    return response;
}

//saves new organization
export async function saveOrganization(organizationModel){
    const response = await axios.post(`accounts/organization`,organizationModel);
    return response;
}


export const handleSocialSignIn = (provider) => {
    const apiUrl = Environment.getTokenAPIUrl();
    const externalProvider = provider;
    const clientId = "webportal";
    const returnUrl = encodeURIComponent(window.location.origin + "/login-jwt-auth");

    const url = `${apiUrl}/external/challenge?provider=${externalProvider}&clientId=${clientId}&returnUrl=${returnUrl}`;
    return url;
};