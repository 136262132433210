// src/ConnectedSources.js
import React, { useEffect, useState } from 'react';
import _ from "lodash";
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import * as collectionService from '../../../services/data-collection.service';
import { truncateString } from '../../../shared/code-challanges/code-challanges';
import PluginHome from '../../../components/asheetPlugin/plugin/plugin';

const PluginController = () => {
    const [activeTab, setActiveTab] = useState('connection');
    const [connectionList, setConnectionList] = useState();
    const [dataCollections, setDatacollections] = useState();
    const location = useLocation()
    const [selectedCollection, setSelectedConnection] = useState()


    useEffect(async () => {
        if (location?.state) {
            await getUserDataCollection()
            route(location?.state)

        }

    }, [location])

    const route = (event) => {
        setSelectedConnection(event)
    }

    const getUserDataCollection = async () => {

        const data = await collectionService.getDataCollection()
        if (!data?.hasError) {
            setDatacollections(data?.data)
        }
    }



    useEffect(() => {
        getUserDataCollection()
    }, [])

    useEffect(() => {
        if (dataCollections?.length)
            onChangeTabs(activeTab)
    }, [dataCollections])

    const onChangeTabs = (name) => {
        let connectionGroupsList;
        let connectionList;

        if (name === "groupName") {
            connectionList = _.uniqBy(dataCollections, function (collection) {
                return collection?.groupName;
            });
        }
        if (name === "connection") {
            connectionList = _.uniqBy(dataCollections, function (collection) {
                return collection?.connection?.name;
            });
        };
        setActiveTab(name);
        setConnectionList(connectionList);
    }

    const handleBack = () => {
        setSelectedConnection(null);
        onChangeTabs('connection');
    }

    const getChildGroupNames = (groupName) => {
        if (!groupName || !dataCollections) {
            return [];
        }

        const subgroupNames = dataCollections
            .filter(collection => collection.groupName === groupName && collection.childGroupName.trim() !== '')
            .map(collection => collection.childGroupName);

        return [...new Set(subgroupNames)];
    };

    const collectionRenderList = (list, key) => (


        <div id={key} className="active">
            {list?.map((item) => (
                <div className='' >
                    <div onClick={() => route(item)} className="source" key={item.id}>
                        <img
                            src={`../../assets/${item.connection.connectionImageIcon}`}
                            alt={item.connection.name}
                        />
                        {key == "connection" ? <div>{truncateString(item.connection.name, 29)}</div> :
                            (
                                <div>
                                    {item[key]}
                                    <span>{truncateString(item.connection.name, 20)}</span>
                                    {getChildGroupNames(item[key]).map((subGroupName, index) => (
                                        <div
                                            className="indented subgroup"
                                            key={index}>
                                            {subGroupName}
                                            <span>
                                                {truncateString(item.connection.name, 31 - subGroupName.length)}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        <img
                            className="info-icon"
                            src={`/assets/infoIcon.svg`}
                        />
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <PluginHome
            selectedCollection={selectedCollection}
            setSelectedConnection={setSelectedConnection}
            dataCollections={dataCollections}
            activeTab={activeTab}
            collectionRenderList={collectionRenderList}
            onChangeTabs={onChangeTabs}
            connectionList={connectionList}
            handleBack={handleBack}
        />
    );
};

export default PluginController;


