export class FilterObjectState {
    field = "";
    condition = "";
    value = "";
    operator = "";
    highValue = "";
    values = [];
    displayName = "";
    dataTypeName = "";
    dateLabel = "";
    valueLabel = "";
    isMultiSelect = false;
    dateLabelValue = "";
    conditionLabel = "";
}