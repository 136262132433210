import { Box, Button, InputLabel, MenuItem, Modal, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as collectionService from '../../../services/data-collection.service';
import { useEffect, useState } from "react";
import { ASTextField } from "../../../shared/as-text-fields";
import moment from "moment";
import { ASButton } from "../../../shared/as-button/as-button";
import serverFunctions from "../utils2/serverFunctions.js";
import _ from "lodash"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import { ASDataCollections } from "../../../shared/menu-icons/as-data-collections";
import BoltIcon from '@mui/icons-material/Bolt';
import { ASheetPlugInLogoSvg } from "../../../constants/common-svgs";
import { AppEnum, ParameterTypeValue } from "../../../constants/app-enum";
import * as UserService from "../../../services/variable-service";
import { toast } from "react-toastify";
import './plugin-parameters.scss';
import SheetsOverlay from "../SheetsOverlay/sheets-overlay";

import { filterType } from "../../data-collections/column-selection-popup/column-selection-filter-type";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CollectionFilter } from "../search-parameters/FilterParameters";
import { Parameters } from "../search-parameters/parameters";
import { SearchParameterController } from "../../../controllers/asheetPlugin/search-parameter.js/search-parameters.js";
const PluginParameter = () => {
    const { collectionUId: collectionUId } = useParams();
    const [dataCollection, setDatacollection] = useState()
    const [selectedCollection, setSelectedCollection] = useState()
    const [isOpen, setIsOpen] = useState()
    const location = useLocation()
    const navigate = useNavigate()

    const handleBack = () => {
        navigate("/plugin", {
            state: selectedCollection
        })
    }

    const getCollectionDetails = async () => {

        const data = await collectionService.getCollectionDetails(collectionUId)
        setDatacollection(data?.data)
    }


    useEffect(() => {
        setSelectedCollection(location.state)
        getCollectionDetails()

    }, [])

    const inputDataType = () => {
        if (dataCollection.collectionParameters.parameterTypeCD = "fixed") {

        }
    }

    const createSheetAndAddData = (data) => {
        const closePopUp = () => {

            setTimeout(() => {
                setIsOpen(false)
            }, 2000);
        }
        serverFunctions.addSheet(data).then(closePopUp()).catch(closePopUp());
    };

    const testDataCollection = async () => {
        setIsOpen(true)

        let testDataCollectionModal = {
            connectionUId: dataCollection.connectionUId,
            sysCollectionTypeId: dataCollection.sysCollectionTypeId,
            sourceName: dataCollection.sourceName,
            restRequestMethod: dataCollection.restRequestMethod,
            restRequestIsAsync: true,
            restBody: dataCollection.restBody,
            restSendAsCD: dataCollection.restSendAsCD,
            collectionParameters: dataCollection.collectionParameters,
            collectionColumns: dataCollection.collectionColumns,
            uId: dataCollection.uId,
            returnFlatternData: dataCollection.sysCollectionTypeId === 7 ? true : false,
            sourceNamePart4: dataCollection.sourceNamePart4
        }

        const response = await collectionService.testDataCollection(testDataCollectionModal)


        let errorMessage = '';

        if (response?.data?.data) {
            if (response?.data?.statusCode == "Unauthorized") {

            }
            else if (response?.data?.statusCode == "Forbidden") {

            }
            else if (response?.data?.data?.length === 0 || Object.keys(response?.data?.data).length === 0) {
            }
            else {

                let testedDataColumnKeys = (_.keys(response?.data?.data));
                let finalData = Array.isArray(response?.data?.data) ? response?.data?.data : [response?.data?.data];
                createSheetAndAddData(finalData)


            }
        }
    }



    return (
        <div className="gsheetContainer">
            <div className="header-flex">
                <div className="flex">
                    <ArrowBackIcon onClick={handleBack}>Back</ArrowBackIcon>
                    <div className="source">
                        <img
                            src={`../../../assets/${selectedCollection?.connection?.connectionImageIcon}`}
                            alt="Xero"
                        />{' '}
                        <div className="parameter-heading">
                        {selectedCollection?.connection?.name}  <ArrowForwardIosIcon /> {selectedCollection?.name}
                        </div>
                       
                    </div>
                </div>

                <div>
                    <ASButton
                    >
                        +
                    </ASButton>
                </div>
            </div>
            <div>
                <div className="flex mt-5">
                    <ASDataCollections className="group-image" flag={false} />
                    Group
                </div>
                <div className="ml-10 parameter-heading">
                    {selectedCollection?.groupName}  <ArrowForwardIosIcon /> {selectedCollection?.name}
                </div>
            </div>

            <div className="collection-params-container">

                <div style={{ marginInline: '5px' }}>

                    {

                        dataCollection?.collectionParameters?.map((collection, index) => {
                            const parameterTypes = Object.values(ParameterTypeValue());

                            if (parameterTypes.includes(collection.parameterTypeCD)) {
                                return (
                                    <div>
                                        {index === 0 &&
                                            <div className="parameter-heading mt-5 ">
                                                <ArrowForwardIosIcon /> Search Parameters
                                            </div>
                                        }

                                        <SearchParameterController collection={collection} index={index} dataCollection={dataCollection} setDatacollection={setDatacollection} />
                                    </div>

                                )
                            }
                            else {
                                return (
                                    <CollectionFilter data={collection} index={index} />
                                )
                            }

                        })
                    }
                </div>
            </div>


            <div className="run-btn">
                <ASButton
                    primary={true}
                    variant="contained"
                    sx={{ width: "100%" }}
                    onClick={() => testDataCollection()}
                > {<BoltIcon sx={{ color: "yellow" }} />}RUN
                </ASButton>
            </div>

            {
                isOpen &&
                <SheetsOverlay
                    connectionIcon={`${selectedCollection?.connection?.connectionImageIcon}`}
                    sheetsIcon={`/assets/google-sheets-icon.png`}
                    property={'Deals'} 
                />
            }
        </div >

    )
}

export default PluginParameter

// export const CollectionFilter = ({ data, index }) => {
//     const filterGroups = JSON.parse(data?.defaultValue)
//     const isSort = data?.parameterName === "Sort" ? true : false

//     const getFilterType = (value) => {
//         return filterType.find((i) => i.type === "String").value.find((i) => i.value === value)

//     }
//     const openPopup = (sheetIndex) => {
//         serverFunctions.openDialog().then().catch(alert);
//       };

//     return (
//         <div key={index} style={{ marginTop: "5px" }}>
//             <div className="filter-header">
//                 <div className="parameter-heading " >

//                     <ArrowForwardIosIcon />{isSort ? "Sort by" : "Filter"}
//                 </div>

//                 <button onClick={openPopup}>edit</button>
//             </div>
//             {
//                 isSort ?
//                     <div className="filter-box">
//                         {filterGroups?.map((item, index) => {
//                             return (
//                                 <div className="filter-value">
//                                     {item.displayName} {item.value}
//                                 </div>
//                             )
//                         })}
//                     </div>
//                     : ""
//             }
//             <div className="filter-box">
//                 {(filterGroups?.filter(group => group?.filters?.length > 0))?.map((group, groupIndex) => (
//                     <div key={groupIndex}>

//                         {group?.filters?.map((item, conditionIndex) => {
//                             return (
//                                 <div className="filter-value">
//                                     <div className="" key={conditionIndex}>
//                                         {item?.displayName} {getFilterType(item?.condition)?.name} 
//                                          <span className="bold"> {item?.dateLabel ? item?.dateLabel : item?.valueLabel ? item?.valueLabel : item?.value}</span> 
//                                         <span className="italic">{group?.filters.length !== conditionIndex + 1 ? ` ${item.operator}` : ""}</span> 

//                                     </div>

//                                 </div>

//                             )
//                         })}
//                         <div className="italic" style={{marginLeft:"15px"}}>{(filterGroups?.filter(group => group?.filters?.length > 0)).length !== groupIndex + 1 && group.operator}</div>
//                     </div>
//                     // </div>
//                 ))
//                 }
//             </div>
//         </div>
//     )
// }

// export const Parameters = ({ collection, index, dataCollection }) => {

//     const complexTypeValue = JSON.parse(collection?.complexTypeValue);
//     const [dynamicList, setDynamicList] = useState();
//     const dateString = collection?.defaultValue;
//     const [day, month, year] = dateString.split("-");
//     const dateObject = new Date(`${year}-${month}-${day}`);


//     useEffect(() => {
//         if (collection?.sysDataTypeId === 8)
//             getDynamicList()
//     }, [collection])

//     const getDynamicList = async () => {

//         let testDataCollecctionModal = {
//             connectionUId: dataCollection.connectionUId,
//             sysCollectionTypeId: dataCollection.sysCollectionTypeId,
//             sourceName: dataCollection.sourceName,
//             restRequestMethod: dataCollection.restRequestMethod,
//             restRequestIsAsync: true,
//             restBody: dataCollection.restBody,
//             restSendAsCD: dataCollection.restSendAsCD,
//             collectionParameters: dataCollection.collectionParameters,
//             collectionColumns: dataCollection.collectionColumns,
//             uId: dataCollection.uId,
//             returnFlatternData: dataCollection.sysCollectionTypeId === 7 ? true : false,
//             sourceNamePart4: dataCollection.sourceNamePart4
//         }
//         try {
//             const response = await collectionService.testDataCollection(testDataCollecctionModal);
//             if (response?.hasError) {
//                 toast.error(response?.errorMessage);
//             }
//             else {
//                 if (response?.data?.statusCode === "Unauthorized" || response?.data?.httpStatus === 401) {
//                     toast.error("Your connection has been expired. Please establish connection again");
//                     return;
//                 }
//                 filterData(response?.data?.data);
//             }
//         } catch (error) {
//             toast.error(error);
//         }
//     }

//     const filterData = (data) => {

//         const dynamicList = data.map(item => ({
//             [complexTypeValue?.DisplayColumn]: item[complexTypeValue?.DisplayColumn],
//             [complexTypeValue?.ValueColumn]: item[complexTypeValue?.ValueColumn]
//         }));
//         setDynamicList(dynamicList)

//     };

//     return (
//         <div className="filter-box ">
//             {collection.parameterTypeCD === AppEnum.ParameterTypeValue.UserInput || collection.parameterTypeCD === AppEnum.ParameterTypeValue.Fixed ?
//                 < ASTextField
//                     key={index}
//                     className="text-field1 label-text-field "
//                     type={collection?.sysDataTypeId === 3 ? "date" : collection?.sysDataTypeId === 1 ? "number" : "text"}
//                     fullWidth
//                     label={collection?.parameterName}
//                     variant="outlined"
//                     disabled={collection?.parameterTypeCD === "Fixed" ? true : false
//                     }

//                     defaultValue={collection?.sysDataTypeId === 3 ? moment(dateObject).format("YYYY-MM-DD") : collection?.defaultValue == "" ? "test" : collection.defaultValue}
//                 /> : null
//             }

//             {
//                 collection.parameterTypeCD === AppEnum.ParameterTypeValue.UserDefinedVariable ?
//                     collection?.sysDataTypeId === 5 ?
//                         < ASTextField
//                             size="small"
//                             className="text-field1 label-text-field "
//                             type={"text"}
//                             sx={{ fontSize: "12px" }}
//                             fullWidth
//                             defaultValue={complexTypeValue?.find((i) => i.Default)?.Label ?? ""}
//                             label={collection?.parameterName}
//                             variant="outlined"
//                             select={true}
//                             SelectProps={{
//                                 MenuProps: {
//                                     className: "menu-role-div",
//                                 },
//                             }}
//                         >
//                             {complexTypeValue?.map((list, index) => {
//                                 return (
//                                     <MenuItem sx={{ fontSize: "12px" }} value={list?.Label} key={`dynamic ${index}`} >
//                                         {list.Label}
//                                     </MenuItem>
//                                 );
//                             })}
//                         </ASTextField>
//                         :
//                         <ASTextField
//                             className="text-field1 label-text-field"
//                             size="small"
//                             type={"text"}
//                             sx={{ fontSize: "12px" }}
//                             fullWidth
//                             label={collection?.parameterName}
//                             variant="outlined"
//                             defaultValue={complexTypeValue?.DefaultValue}
//                             select={true}
//                             SelectProps={{
//                                 MenuProps: {
//                                     className: "menu-role-div",
//                                 },
//                             }}
//                         >
//                             {dynamicList?.map((list, index) => {
//                                 return (
//                                     <MenuItem value={list[complexTypeValue?.ValueColumn]} key={`dynamic ${index}`} >
//                                         {list[complexTypeValue.DisplayColumn]}
//                                     </MenuItem>
//                                 );
//                             })}
//                         </ASTextField> : null
//             }
//         </div >
//     )
// }