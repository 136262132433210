import { useEffect, useState } from "react";
import { getDataSourceTypeByID } from "../../../services/data-source-type.service";
import { MsSqlServer } from "../../../controllers/connections/connection-details/ms-sql-server";
import { RestApi } from "../../../controllers/connections/connection-details/rest-api/rest-api";
import { getTemplateDataSourceByID } from "../../../services/data-source.service";
import { PostgreSqlServer } from "../../../controllers/connections/connection-details/postgre-sql-server";
import { MySqlServer } from "../../../controllers/connections/connection-details/my-sql-server";
import { useLocation } from "react-router-dom";
import { RestServiceConstants } from "../../../constants/rest-service-constant";

export function ConnectionDetails({
  selectedStep,
  isFormInvalid,
  setSaveFormData,
  formData,
  setFormData,
  isTemplateConnection,
  isUpdatingTemplateConnection,
  errorMessage,
  testConnection,
  disconnectConnection,
  isCLoudConnectionDisconnected,
  setIsClientDataUpdated,
  existingClientData,
  isTally,
  restOrCloudApplicationsId
}) {
  const [dataSource, setDataSource] = useState();
  const [templateData, setTemplateData] = useState();

  const { pathname } = useLocation();
  const params = pathname.split('/'); // Split the path by '/'
  const uID = params[3];

  const [renderConnectionType,setRenderConnectionType] = useState();

  useEffect(() => {
    if (selectedStep) {
      if(restOrCloudApplicationsId.has(selectedStep?.id)){
        setRenderConnectionType(99);
      }else{
        setRenderConnectionType(selectedStep?.id);
      }
      if (isTemplateConnection) {
        getTemplateDataSourceById(selectedStep?.templateConnectionId);
      }
      else {
        getDataSourceById(selectedStep.id);
      }
    }
  }, [selectedStep]);


  const getDataSourceById = (id) => {
    getDataSourceTypeByID(id).then((res) => {
      if (res?.data) {
        setDataSource(res.data);
      }
    });
  }

  const getTemplateDataSourceById = (id) => {
    if(uID || (formData && formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN]?.value)){
      getDataSourceById(selectedStep.id);
    }else{
      getTemplateDataSourceByID(id).then((res) => {
        if (res?.data) {
          //This code is creating bugs as it changes selctedStep.id ex: when we move from rest to any template connections it keeps the selected.id as always 2
          //and when you come back to any template it sets template connection id as 2.
          //Another way of handling this code a set is created above which would keep id of sysConnections and rest,
          //So if any sysconnection is added whose base type is rest its id will be there in restOrCloudApplicationsId

          // let tempStepId = selectedStep.id;
          // selectedStep.id = res?.data.connectionTypeId;
          // res.data.connectionTypeId = tempStepId;

          const connectiontypeId = res?.data.connectionTypeId;
          res.data.connectionTypeId = selectedStep.id;

          if (!formData || !formData["name"].value){
            setFormData(res?.data);
            setTemplateData(res?.data);
          }
          else{
            setFormData(res?.data,null,true);
          }
          getDataSourceById(connectiontypeId);
        }
      });
    }
  }

  if (!!dataSource) {
    switch (renderConnectionType) {
      case 1:
        return (
          <MsSqlServer
            saveFormData={setSaveFormData}
            formData={formData}
            isFormInvalid={isFormInvalid}
            datasourceTypeParameters={dataSource.parameters || []}
          />
        );
      case 99:
        return (
          <RestApi
            saveFormData={setSaveFormData}
            formData={formData}
            isFormInvalid={isFormInvalid}
            datasourceTypeParameters={dataSource.parameters || []}
            isTemplateConnection={isTemplateConnection}
            isUpdatingTemplateConnection={isUpdatingTemplateConnection}
            templateData={templateData}
            errorMessage={errorMessage}
            selectedStep={selectedStep}
            testConnection={testConnection}
            disconnectConnection={disconnectConnection}
            isCLoudConnectionDisconnected={isCLoudConnectionDisconnected}
            setIsClientDataUpdated={setIsClientDataUpdated}
            existingClientData={existingClientData}
            isTally={isTally}
          />
        );
      case 6:
        return (
          <PostgreSqlServer
            saveFormData={setSaveFormData}
            formData={formData}
            isFormInvalid={isFormInvalid}
            datasourceTypeParameters={dataSource.parameters || []}
          />
        );
      case 4:
        return (
          <MySqlServer
            saveFormData={setSaveFormData}
            formData={formData}
            isFormInvalid={isFormInvalid}
            datasourceTypeParameters={dataSource.parameters || []}
          />
        );
      default:
        return <h3>Some Error...</h3>;
    }
  }

  return <h3>Loading...</h3>;
}
