import { Box } from "@mui/material";
import { AsheetLogoExcelSvg } from '../../../constants/common-svgs';
import "./connection-screen-excel.scss";
import { ASButton } from '../../../shared/as-button/as-button';
import EastIcon from '@mui/icons-material/East';
import ConnectingStageComponent from '../../connections/connecting-stage-excel/connecting-stage-excel'; // Import the component you want to navigate to

const ConnectionScreenExcel = (props) => {
  const { selectedConnection, onCancel, testConnection, isConnected, isConnecting,disconnectConnection} = props;  

  return (
    <div className="connection-screen-excel">
      {!isConnecting ? ( 
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="95vh">
          <div className="excel-connection-name">
            Connect to {selectedConnection.description}
          </div>
          <Box display="flex" alignItems="center" justifyContent="center" >
            <span className="connection-screen-asheet-logo">{AsheetLogoExcelSvg}</span>
            <div className='arrow-icon'><EastIcon style={{ marginLeft: '13px' }} /></div>
            <img src={`/assets/${selectedConnection.image}`} alt={selectedConnection.description} style={{ width: 46, height: 46, marginTop: '1rem', marginLeft: '0.5rem' }} />
          </Box>
          <ASButton className="connection-screen-connect-button" sx={{ padding: '10px 4rem', borderRadius: "10px" }} primary={true} onClick={testConnection}>
            <span style={{ textTransform: "none", fontWeight: '600' }}>Connect</span>
          </ASButton>          
        </Box>
      ) : (
        <ConnectingStageComponent
          disconnectConnection={disconnectConnection}
          isConnected={isConnected}
          onCancel={onCancel}
          selectedConnection={selectedConnection}
       /> 
      )}
    </div>
  );
};

export default ConnectionScreenExcel;
