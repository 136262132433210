
// stores item in Session Storage 
export const setSessionStorageItem = (storageName, storageValue) => {
    sessionStorage.setItem(storageName, storageValue);
}

// fetches item from Session Storage
export const getSessionStorageItem = (storageName) => {
    let item = sessionStorage.getItem(storageName);
    return item;
}

//removes item from Session Storage
export const removeSessionStorageItem = (storageName) => {
    sessionStorage.removeItem(storageName);
}