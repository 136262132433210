import { Paper } from "@mui/material";
import { ASTextField } from "../../../../shared/as-text-fields";
import { useEffect } from "react";


export const SaveConnection = (props) => {
    const { formData, setSaveFormData, setCheckInheritedConnectionValid} = props;
  
    useEffect(()=>{
        if(formData?.uID?.value.length === 0){
            //formData.name.value = "";
            setSaveFormData({ ...formData })
        }
    },[])
  
    const handleChange = (event) => {
      if(event.target.value == ""){
        setCheckInheritedConnectionValid(true);
      }
      else{
        setCheckInheritedConnectionValid(false);
      }
      formData.name.value = event.target.value;
      setSaveFormData({ ...formData })
    }
  
    return (
      <Paper
        sx={{
          minHeight: "100px",
          width: "750px",
          padding: "20px",
        }}
      >
        <>
          <ASTextField
            className="text-field"
            label="Connection Name"
            variant="outlined"
            onChange={handleChange}
            value={!!formData && formData?.name?.value}
          />
        </>
      </Paper>
    );
  }