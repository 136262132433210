import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { OAuth2ParametersController } from "../../../../controllers/connections/connection-details/rest-api/o-auth-2.0-controller";
import { ASInput } from "../../../../shared/as-inputs/as-input";
import { ASTab, ASTabs } from "../../../../shared/as-tabs/as-tabs";
import { muiCheckboxStyle } from "../../../user-permission/user-permission";
import {
    Parameters,
    ParametersForm
} from "../../../../controllers/connections/connection-details/rest-api/bearer-token";
import "./rest-api.scss";

export const OAuth2View = (props) => {

    const { formData,headerPrefixParameterType,grantTypeParameterType,accessTokenUrlParameterType,refreshTokenUrlParameterType,clientIdParameterType,clientSecretParameterType,
            scopeParameterType,stateParameterType,sendClientAuthAsParameterType,callBackUrlParameterType,authUrlParameterType,authUsingBrowserParameterType,userNameParameterType,
            passWordParameterType,parameters,authRequestParameterType,tokenRequestParameterType,refreshRequestParameterType,isFormInvalid,checkRefreshTokenUrl,refreshInputDisable,
            codeChallengeMethodParameterType,codeVerifierParameterType,refreshSupport,onCheckSupportRefreshToken,authHeaderNameParameterType,sendOAuthHeaderInRefresh,sendScopeInRefresh,
            onCheckSendAuthHeaderInRefreshTokenRequest,onCheckSendScopeInRefreshTokenRequest} = props;

    const [activeTab, setActiveTab] = useState(0);

    // changes the tab
    const onChangeTabHandler = () => {
        setActiveTab(+!activeTab);
    };

    return (
        <>
            <ASTabs className="mb-2" value={activeTab} onChange={onChangeTabHandler}>
                <ASTab label="BASIC DETAIL" />
                <ASTab label="PARAMETERS" />
            </ASTabs>

            {!activeTab && (
                <OAuth2
                    formData={formData}
                    headerPrefixParameterType={headerPrefixParameterType}
                    grantTypeParameterType={grantTypeParameterType}
                    accessTokenUrlParameterType={accessTokenUrlParameterType}
                    refreshTokenUrlParameterType={refreshTokenUrlParameterType}
                    clientIdParameterType={clientIdParameterType}
                    clientSecretParameterType={clientSecretParameterType}
                    scopeParameterType={scopeParameterType}
                    stateParameterType={stateParameterType}
                    sendClientAuthAsParameterType={sendClientAuthAsParameterType}
                    callBackUrlParameterType={callBackUrlParameterType}
                    authUrlParameterType={authUrlParameterType}
                    authUsingBrowserParameterType={authUsingBrowserParameterType}
                    userNameParameterType={userNameParameterType}
                    passWordParameterType={passWordParameterType}
                    checkRefreshTokenUrl={checkRefreshTokenUrl}
                    refreshInputDisable={refreshInputDisable}
                    codeChallengeMethodParameterType={codeChallengeMethodParameterType}
                    codeVerifierParameterType={codeVerifierParameterType}
                    refreshSupport={refreshSupport}
                    onCheckSupportRefreshToken={onCheckSupportRefreshToken}
                    authHeaderNameParameterType={authHeaderNameParameterType}
                    sendOAuthHeaderInRefresh={sendOAuthHeaderInRefresh}
                    sendScopeInRefresh={sendScopeInRefresh}
                    onCheckSendAuthHeaderInRefreshTokenRequest={onCheckSendAuthHeaderInRefreshTokenRequest}
                    onCheckSendScopeInRefreshTokenRequest={onCheckSendScopeInRefreshTokenRequest}
                    />
            )}
            {!!activeTab && (
                <OAuth2ParametersController
                    formData={formData}
                    parameters={parameters}
                    isFormInvalid={isFormInvalid}
                    authRequestParameterType={authRequestParameterType}
                    tokenRequestParameterType={tokenRequestParameterType}
                    refreshRequestParameterType={refreshRequestParameterType}
                />
            )}
        </>
    );
};

const OAuth2 = (props) => {

    const { formData,headerPrefixParameterType,grantTypeParameterType,accessTokenUrlParameterType,refreshTokenUrlParameterType,clientIdParameterType,clientSecretParameterType,
        scopeParameterType,stateParameterType,sendClientAuthAsParameterType,callBackUrlParameterType,authUrlParameterType,authUsingBrowserParameterType,userNameParameterType,
        passWordParameterType,checkRefreshTokenUrl,refreshInputDisable,codeChallengeMethodParameterType,codeVerifierParameterType,refreshSupport,onCheckSupportRefreshToken,authHeaderNameParameterType,
        sendOAuthHeaderInRefresh,sendScopeInRefresh,onCheckSendAuthHeaderInRefreshTokenRequest,onCheckSendScopeInRefreshTokenRequest} = props;

    return (
        <React.Fragment>
            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[grantTypeParameterType.parameterType] &&
                        formData[grantTypeParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[grantTypeParameterType.parameterType] &&
                        formData[grantTypeParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...grantTypeParameterType,
                    displayName: grantTypeParameterType.displayName || "Grant Type",
                }}
                selectInput={true}
            >
                <MenuItem value="authorization_code">Authorization Code</MenuItem>
                <MenuItem value="authorization_code_with_pkce">Authorization Code With PKCE</MenuItem>
                <MenuItem value="client_credentials">Client Credential</MenuItem>
                <MenuItem value="password">Password</MenuItem>
            </ASInput>

            { ((!!formData &&
                        !!formData[grantTypeParameterType.parameterType] &&
                        formData[grantTypeParameterType.parameterType]).value == "authorization_code" || 
                        (!!formData &&
                            !!formData[grantTypeParameterType.parameterType] &&
                            formData[grantTypeParameterType.parameterType]).value == "authorization_code_with_pkce") &&
                <div>        
                <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[callBackUrlParameterType.parameterType] &&
                        formData[callBackUrlParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[callBackUrlParameterType.parameterType] &&
                        formData[callBackUrlParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...callBackUrlParameterType,
                    displayName: callBackUrlParameterType.displayName || "Callback URL",
                }}
                disabled={true}
            />

            <FormControlLabel control={
                <Checkbox
                    checked={true}
                    // onChange={(event) => props.onCheckCollectionAsConnection(event)}
                    sx={muiCheckboxStyle}
                />
            }
                className='collection-as-connection'
                label="Authorize using browser"
            />

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[authUrlParameterType.parameterType] &&
                        formData[authUrlParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[authUrlParameterType.parameterType] &&
                        formData[authUrlParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...authUrlParameterType,
                    displayName: authUrlParameterType.displayName || "Auth URL",
                }}
            /></div>}

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[accessTokenUrlParameterType.parameterType] &&
                        formData[accessTokenUrlParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[accessTokenUrlParameterType.parameterType] &&
                        formData[accessTokenUrlParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...accessTokenUrlParameterType,
                    displayName: accessTokenUrlParameterType.displayName || "Access Token URL",
                }}
            />

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[clientIdParameterType.parameterType] &&
                        formData[clientIdParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[clientIdParameterType.parameterType] &&
                        formData[clientIdParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...clientIdParameterType,
                    displayName: clientIdParameterType.displayName || "Client ID",
                }}
            />

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[clientSecretParameterType.parameterType] &&
                        formData[clientSecretParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[clientSecretParameterType.parameterType] &&
                        formData[clientSecretParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...clientSecretParameterType,
                    displayName: clientSecretParameterType.displayName || "Secret",
                }}
            />

            {((!!formData &&
                            !!formData[grantTypeParameterType.parameterType] &&
                            formData[grantTypeParameterType.parameterType]).value == "authorization_code_with_pkce") &&
            <>

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[codeChallengeMethodParameterType.parameterType] &&
                        formData[codeChallengeMethodParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[codeChallengeMethodParameterType.parameterType] &&
                        formData[codeChallengeMethodParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...codeChallengeMethodParameterType,
                    displayName: codeChallengeMethodParameterType.displayName || "code Challenge Method",
                }}
                selectInput={true}
            >
                <MenuItem value="S256">SHA-256</MenuItem>
                <MenuItem value="plain">Plain</MenuItem>
            </ASInput>

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[codeVerifierParameterType.parameterType] &&
                        formData[codeVerifierParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[codeVerifierParameterType.parameterType] &&
                        formData[codeVerifierParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...codeVerifierParameterType,
                    displayName: codeVerifierParameterType.displayName || "code Verifier",
                }}
            /></>}

            {
                (!!formData &&
                    !!formData[grantTypeParameterType.parameterType] &&
                    formData[grantTypeParameterType.parameterType]).value == "password" &&   
                <div>
                    <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[userNameParameterType.parameterType] &&
                        formData[userNameParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[userNameParameterType.parameterType] &&
                        formData[userNameParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...userNameParameterType,
                    displayName: userNameParameterType.displayName || "Username",
                }}
            />

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[passWordParameterType.parameterType] &&
                        formData[passWordParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[passWordParameterType.parameterType] &&
                        formData[passWordParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...passWordParameterType,
                    displayName: passWordParameterType.displayName || "Password",
                }}
            />
                </div>
            }

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[scopeParameterType.parameterType] &&
                        formData[scopeParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[scopeParameterType.parameterType] &&
                        formData[scopeParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...scopeParameterType,
                    displayName: scopeParameterType.displayName || "Scope",
                }}
            />

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[authHeaderNameParameterType.parameterType] &&
                        formData[authHeaderNameParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[authHeaderNameParameterType.parameterType] &&
                        formData[authHeaderNameParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...authHeaderNameParameterType,
                    displayName: authHeaderNameParameterType.displayName || "Authorization Header Name",
                }}
            />

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[headerPrefixParameterType.parameterType] &&
                        formData[headerPrefixParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[headerPrefixParameterType.parameterType] &&
                        formData[headerPrefixParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...headerPrefixParameterType,
                    displayName: headerPrefixParameterType.displayName || "Header Prefix",
                }}
            />

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[sendClientAuthAsParameterType.parameterType] &&
                        formData[sendClientAuthAsParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[sendClientAuthAsParameterType.parameterType] &&
                        formData[sendClientAuthAsParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...sendClientAuthAsParameterType,
                    displayName: sendClientAuthAsParameterType.displayName || "Client Authentication",
                }}
                selectInput={true}
            >
                <MenuItem value={0}>none</MenuItem>
            </ASInput>

            {((!!formData &&
                        !!formData[grantTypeParameterType.parameterType] &&
                        formData[grantTypeParameterType.parameterType]).value == "authorization_code" || 
                        (!!formData &&
                            !!formData[grantTypeParameterType.parameterType] &&
                            formData[grantTypeParameterType.parameterType]).value == "authorization_code_with_pkce") &&
                <div>
                    <FormControlLabel control={
                        <Checkbox
                            checked={refreshSupport}
                            onChange={(event) => onCheckSupportRefreshToken(event)}
                            sx={muiCheckboxStyle}
                        />
                    }
                        className='collection-as-connection'
                        label="Support Offline_Access / Refresh Token"
                    />

                    { refreshSupport &&
                    <div>
                        <ASInput
                            value={
                                (
                                    !!formData &&
                                    !!formData[refreshTokenUrlParameterType.parameterType] &&
                                    formData[refreshTokenUrlParameterType.parameterType]
                                ).value
                            }
                            errorMsg={
                                (
                                    !!formData &&
                                    !!formData[refreshTokenUrlParameterType.parameterType] &&
                                    formData[refreshTokenUrlParameterType.parameterType]
                                ).errorMsg
                            }
                            fieldParameter={{
                                ...refreshTokenUrlParameterType,
                                displayName: refreshTokenUrlParameterType.displayName || "Refresh Token URL",
                            }}
                            onFocus={() => checkRefreshTokenUrl()}
                            isRefreshUrl={refreshInputDisable}
                            overwriteValue={(
                                !!formData &&
                                !!formData[accessTokenUrlParameterType.parameterType] &&
                                formData[accessTokenUrlParameterType.parameterType]
                            ).value}
                        />

                        <FormControlLabel control={
                                <Checkbox
                                    checked={sendOAuthHeaderInRefresh}
                                    onChange={(event) => onCheckSendAuthHeaderInRefreshTokenRequest(event)}
                                    sx={muiCheckboxStyle}
                                />
                                }
                                className='collection-as-connection'
                                label="Do not send default Authorization Header in refresh token request"
                        />

                        <FormControlLabel control={
                                <Checkbox
                                    checked={sendScopeInRefresh}
                                    onChange={(event) => onCheckSendScopeInRefreshTokenRequest(event)}
                                    sx={muiCheckboxStyle}
                                />
                                }
                                className='collection-as-connection'
                                label="Do not send Scope in refresh token request"
                        />
                    </div>}
            </div>}
        </React.Fragment>
    )
}

export const OAuth2Parameters = (props) => {

    const {formData, parameters, isFormInvalid, authRequestParameterType, tokenRequestParameterType, refreshRequestParameterType} = props;

    return (
        <>
            <div className="auth-request-div">
                <Parameters
                    formData={formData}
                    parameters={authRequestParameterType}
                    isFormInvalid={isFormInvalid}
                    title={"Auth Request Parameters"}
                    isAuthRequest={true}
                />
            </div>

            <div className="token-request-div">
                <Parameters
                    formData={formData}
                    parameters={tokenRequestParameterType}
                    isFormInvalid={isFormInvalid}
                    title={"Token Request Parameters"}
                />
            </div>

            <div className="refresh-request-div">
                <Parameters
                    formData={formData}
                    parameters={refreshRequestParameterType}
                    isFormInvalid={isFormInvalid}
                    title={"Refresh Request Parameters"}
                />
            </div>
        </>
    )
}