import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import "./asheet-plugin-header.scss";
import { ASheetCompleteLogoSvg } from "../../../constants/common-svgs";
import { HiMenuAlt1 } from "react-icons/hi";
import { PageContent } from "../../../controllers/layout/page-content/page-content";

const AsheetPluginHeader = (props) => {
    const { handleBackClick, isBackIconVisible } = props;

    return (
        <>
            <Box sx={{ backgroundColor: "#ffffff", height: "100vh", width: "300px" }} >
                <Box sx={{ display: "flex", height: "5vh" }}>
                    <AppBar position="static" sx={{ backgroundColor: "#ffffff", borderBottom: "0.1px solid #ccc", boxShadow: "none" }}>
                        <Toolbar sx={{ minHeight: "35px" }}>
                            {isBackIconVisible ? (
                                <img src={`/assets/back-button.svg`} alt="Back"
                                    className="plugin-back-button" onClick={handleBackClick} />
                            ) : (                                
                                <HiMenuAlt1 className="hi-menu-icon" />
                            )}
                            <Typography sx={{ flexGrow: 1, textAlign: "right"}}>
                                <svg className="plugin-asheet-logo">
                                    {ASheetCompleteLogoSvg}
                                </svg></Typography>
                        </Toolbar>
                    </AppBar>
                </Box>
                <PageContent />
            </Box>
        </>
    )
}
export default AsheetPluginHeader;