import colors from "./../../styles/_exports.module.scss";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

export const ASSwitch = styled(Switch)(() => ({
    // width: 62,
    // height: 50,
    // padding: 0,
    "& .MuiSwitch-switchBase": {
        // margin: 1,
        // padding: 0,
        // transform: "translateX(6px)",
        "&.Mui-checked": {
            // color: 'red',
            // transform: "translateX(22px)",
            "& .MuiSwitch-thumb": {
                backgroundColor: colors.switchChecked
            },
            "& + .MuiSwitch-track": {
                opacity: 0.38,
                backgroundColor: "rgb(0 0 0 / 95%)", // checked
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.2,
            },
        },
    },
    "& .MuiSwitch-thumb": {
        backgroundColor: "rgb(124 128 133 / 90%)",
        // width: 20,
        // height: 20,
        // left: "6px",
        // top: "11px",
        // "&:before": {
        //     content: "''",
        //     position: "absolute",
        //     width: "100%",
        //     height: "100%",
        //     left: 0,
        //     top: 0,
        // },
        // "&:hover": {
        //     backgroundColor: "white",
        // },
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:"#989fa7", // unchecked
        borderRadius: 20 / 2,
    },
    "& .Mui-disabled+.MuiSwitch-track": {
        opacity: "0.5",
        backgroundColor: "yellow", // unchecked disabled
    },
    ".Mui-focusVisible .MuiSwitch-thumb": {
        backgroundColor: "white",
    },
}));
