import { AppEnum } from "../../../constants/app-enum";

const operatorsList = [
    { label: "is one of", value: "IN" },
    { label: "is not one of", value: "NOT_IN" },
    { label: "is not empty", value: AppEnum.FilterConditions.HasProperty },
    { label: "is empty", value: AppEnum.FilterConditions.NotHasProperty },
    { label: "is Exactly", value: "EQ" },
    { label: "is not Exactly", value: "NEQ" },
    // Add more hardcoded menu items as needed
  ];
  
  export default operatorsList;
  