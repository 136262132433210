import React, { useEffect, useState } from "react";
import OAuth2, { OAuth2Parameters, OAuth2View } from "../../../../components/connections/connection-details/rest-api/o-auth-2.0";
import { RestServiceConstants } from "../../../../constants/rest-service-constant";
import { isNullOrEmpty } from "../../../../services/data-source-validation";
import { Environment } from "../../../../core/environment";

const OAuth2Controller = (props) => {

    const { oauth2Parameters, formData, bearerParameters, isFormInvalid, saveFormData, setIsClientDataUpdated, existingClientData } = props;

    const [refreshInputDisable,setRefreshInputDisable] = useState(false);
    const [supportRefresh,setSupportRefresh] = useState(false);
    const [sendOAuthHeaderInRefresh,setSendOAuthHeaderInRefresh] = useState(false);
    const [sendScopeInRefresh,setSendScopeInRefresh] = useState(false);

    useEffect(() => {
        getCallBackUrl();
        getHeaderPrifix();
        checkRefreshTokenUrl();
        checkSupportRefreshToken();
        getAuthrizationHeaderName();
    },[])

    useEffect(() => {
        checkFormIsValid();
        checkIsChangedScope();
    },[formData])


    const getCallBackUrl = () => {
        const parsedUrl = new URL(window.location.href);
        formData[callBackUrlParameterType.parameterType] ={
            // value: parsedUrl.origin + "/auth-callback",
            value: Environment.getUrl() + "/auth-callback",
            datasourceParameterTypeId: formData[callBackUrlParameterType.parameterType].datasourceParameterTypeId,
            errorMsg: formData[callBackUrlParameterType.parameterType].errorMsg,
            isValid: formData[callBackUrlParameterType.parameterType].isValid
        };
    };

    const getHeaderPrifix = () => {
        formData[headerPrefixParameterType.parameterType] ={
            value: formData[headerPrefixParameterType.parameterType].value == null || formData[headerPrefixParameterType.parameterType].value == "" ? "Bearer" : formData[headerPrefixParameterType.parameterType].value,
            datasourceParameterTypeId: formData[headerPrefixParameterType.parameterType].datasourceParameterTypeId,
            errorMsg: formData[headerPrefixParameterType.parameterType].errorMsg,
            isValid: formData[headerPrefixParameterType.parameterType].isValid
        };
    };

    const getAuthrizationHeaderName = () => {
        if(formData[authHeaderNameParameterType.parameterType]){
            formData[authHeaderNameParameterType.parameterType] ={
                value: formData[authHeaderNameParameterType.parameterType].value == null || formData[authHeaderNameParameterType.parameterType].value == "" ? "Authorization" : formData[authHeaderNameParameterType.parameterType].value,
                datasourceParameterTypeId: formData[authHeaderNameParameterType.parameterType].datasourceParameterTypeId,
                errorMsg: formData[authHeaderNameParameterType.parameterType].errorMsg,
                isValid: formData[authHeaderNameParameterType.parameterType].isValid
            };
        }
        
    };

    const headerPrefixParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.HEADER_PREFIX)
    );

    const grantTypeParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE)
    );

    const accessTokenUrlParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_URL)
    );

    const refreshTokenUrlParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.REFRESH_TOKEN_URL)
    );

    const clientIdParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID)
    );

    const clientSecretParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.CLIENT_SECRET)
    );

    const scopeParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.SCOPE)
    );

    const authHeaderNameParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.AUTHORIZATION_HEADER_NAME)
    );

    const stateParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.STATE)
    );

    const sendClientAuthAsParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.SEND_CLIENT_AUTH_AS)
    );

    const authUrlParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL)
    );

    const callBackUrlParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.CALLBACK_URL)
    );

    const authUsingBrowserParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_USING_BROWSER)
    );

    const userNameParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.PASS_CRED_INPUT.USERNAME)
    );

    const passWordParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.PASS_CRED_INPUT.PASSWORD)
    );

    const authRequestParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.AUTH_REQUEST)
    );
    
    const tokenRequestParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.TOKEN_REQUEST)
    );

    const refreshRequestParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.REFRESH_REQUEST)
    );

    const codeChallengeParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.CODE_CHALLENGE)
    );

    const codeChallengeMethodParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.CODE_CHALLENGE_METHOD)
    );

    const codeVerifierParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.CODE_VERIFIER)
    );

    const supportRefreshTokenParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN)
    );

    const sendAuthHEaderInRefreshTokenParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.SEND_AUTHORIZATION_HEADER_IN_REFRESHTOKENREQUEST)
    );

    const sendScopeInRefreshTokenParameterType = oauth2Parameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.OAUTH2_INPUTS.SEND_SCOPE_IN_REFRESHTOKENREQUEST)
    );

    const checkRefreshTokenUrl = () => {
        var grantType = (
            !!formData &&
            !!formData[grantTypeParameterType.parameterType] &&
            formData[grantTypeParameterType.parameterType]
        ).value

        if(grantType == "authorization_code" || grantType == "authorization_code_with_pkce"){

            const refreshTokenUrl = formData[refreshTokenUrlParameterType.parameterType]?.value;
            if(refreshTokenUrl == "" || refreshTokenUrl == null){
                formData[refreshTokenUrlParameterType.parameterType] ={
                    value: formData[accessTokenUrlParameterType.parameterType].value == null || formData[accessTokenUrlParameterType.parameterType].value == "" ? "" : formData[accessTokenUrlParameterType.parameterType].value,
                    datasourceParameterTypeId: formData[refreshTokenUrlParameterType.parameterType].datasourceParameterTypeId,
                    errorMsg: formData[refreshTokenUrlParameterType.parameterType].errorMsg,
                    isValid: formData[refreshTokenUrlParameterType.parameterType].isValid
                };
                //saveFormData(formData);
                setRefreshInputDisable(true);
            }
            else{
                setRefreshInputDisable(false);
            }
        }
    };

    const onCheckSupportRefreshToken = (event) => {
        setSupportRefresh(event.target.checked);

        formData[supportRefreshTokenParameterType.parameterType] ={
            value: `${event.target.checked}`,
            datasourceParameterTypeId: formData[supportRefreshTokenParameterType.parameterType].datasourceParameterTypeId,
            errorMsg: formData[supportRefreshTokenParameterType.parameterType].errorMsg,
            isValid: formData[supportRefreshTokenParameterType.parameterType].isValid
        };

        if(!event.target.checked){
            formData[refreshTokenUrlParameterType.parameterType] ={
                value: "",
                datasourceParameterTypeId: formData[refreshTokenUrlParameterType.parameterType].datasourceParameterTypeId,
                errorMsg: formData[refreshTokenUrlParameterType.parameterType].errorMsg,
                isValid: formData[refreshTokenUrlParameterType.parameterType].isValid
            };

            formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN].value = ""
        }
    }

    const onCheckSendAuthHeaderInRefreshTokenRequest = (event) => {

        setSendOAuthHeaderInRefresh(event.target.checked);

        if(formData[sendAuthHEaderInRefreshTokenParameterType.parameterType]){
            formData[sendAuthHEaderInRefreshTokenParameterType.parameterType] ={
                value: `${event.target.checked}`,
                datasourceParameterTypeId: formData[sendAuthHEaderInRefreshTokenParameterType.parameterType].datasourceParameterTypeId,
                errorMsg: formData[sendAuthHEaderInRefreshTokenParameterType.parameterType].errorMsg,
                isValid: formData[sendAuthHEaderInRefreshTokenParameterType.parameterType].isValid
            };
        }
    }

    const onCheckSendScopeInRefreshTokenRequest = (event) => {

        setSendScopeInRefresh(event.target.checked);

        if(formData[sendScopeInRefreshTokenParameterType.parameterType]){
            formData[sendScopeInRefreshTokenParameterType.parameterType] ={
                value: `${event.target.checked}`,
                datasourceParameterTypeId: formData[sendScopeInRefreshTokenParameterType.parameterType].datasourceParameterTypeId,
                errorMsg: formData[sendScopeInRefreshTokenParameterType.parameterType].errorMsg,
                isValid: formData[sendScopeInRefreshTokenParameterType.parameterType].isValid
            };
        }
    }

    const checkSupportRefreshToken = () => {

        var grantType = (
            !!formData &&
            !!formData[grantTypeParameterType.parameterType] &&
            formData[grantTypeParameterType.parameterType]
        ).value

        if(grantType == "authorization_code" || grantType == "authorization_code_with_pkce"){
            const refreshSupport = (
                !!formData &&
                !!formData[supportRefreshTokenParameterType.parameterType] &&
                formData[supportRefreshTokenParameterType.parameterType]
            ).value  == "true" ? true : false;

            formData[supportRefreshTokenParameterType.parameterType] ={
                value: `${refreshSupport}`,
                datasourceParameterTypeId: formData[supportRefreshTokenParameterType.parameterType].datasourceParameterTypeId,
                errorMsg: formData[supportRefreshTokenParameterType.parameterType].errorMsg,
                isValid: formData[supportRefreshTokenParameterType.parameterType].isValid
            };
            
            setSupportRefresh(refreshSupport);
            checkScopeAndAuthHeader(refreshSupport);
        }
        
    }

    const checkScopeAndAuthHeader = (refreshSupport) => {
        if(refreshSupport){

            if(formData[sendAuthHEaderInRefreshTokenParameterType.parameterType]){
                const doNotSendAuthHeader = (
                    !!formData &&
                    !!formData[sendAuthHEaderInRefreshTokenParameterType.parameterType] &&
                    formData[sendAuthHEaderInRefreshTokenParameterType.parameterType]
                ).value  == "true" ? true : false;

                formData[sendAuthHEaderInRefreshTokenParameterType.parameterType] ={
                    value: `${doNotSendAuthHeader}`,
                    datasourceParameterTypeId: formData[sendAuthHEaderInRefreshTokenParameterType.parameterType].datasourceParameterTypeId,
                    errorMsg: formData[sendAuthHEaderInRefreshTokenParameterType.parameterType].errorMsg,
                    isValid: formData[sendAuthHEaderInRefreshTokenParameterType.parameterType].isValid
                };

                setSendOAuthHeaderInRefresh(doNotSendAuthHeader);
            }

            if(formData[sendScopeInRefreshTokenParameterType.parameterType]){
                const doNotSendScope = (
                    !!formData &&
                    !!formData[sendScopeInRefreshTokenParameterType.parameterType] &&
                    formData[sendScopeInRefreshTokenParameterType.parameterType]
                ).value  == "true" ? true : false;

                formData[sendScopeInRefreshTokenParameterType.parameterType] ={
                    value: `${doNotSendScope}`,
                    datasourceParameterTypeId: formData[sendScopeInRefreshTokenParameterType.parameterType].datasourceParameterTypeId,
                    errorMsg: formData[sendScopeInRefreshTokenParameterType.parameterType].errorMsg,
                    isValid: formData[sendScopeInRefreshTokenParameterType.parameterType].isValid
                };

                setSendScopeInRefresh(doNotSendScope);
            }

        }
    };


    const checkFormIsValid = () => {
        const grantType = (!!formData && !!formData[grantTypeParameterType.parameterType] && formData[grantTypeParameterType.parameterType]).value
        const accessTokenUrl = (!!formData && !!formData[accessTokenUrlParameterType.parameterType] && formData[accessTokenUrlParameterType.parameterType]).value
        const client_id = (!!formData && !!formData[clientIdParameterType.parameterType] && formData[clientIdParameterType.parameterType]).value
        const authHeaderName = (!!formData && !!formData[authHeaderNameParameterType.parameterType] && formData[authHeaderNameParameterType.parameterType]).value
        const headerPrefix = (!!formData && !!formData[headerPrefixParameterType.parameterType] && formData[headerPrefixParameterType.parameterType]).value
        const authUrl = (!!formData && !!formData[authUrlParameterType.parameterType] && formData[authUrlParameterType.parameterType]).value
        const codeChallengeMethod = (!!formData && !!formData[codeChallengeMethodParameterType.parameterType] && formData[codeChallengeMethodParameterType.parameterType]).value
        const password = (!!formData && !!formData[passWordParameterType.parameterType] && formData[passWordParameterType.parameterType]).value
        const username = (!!formData && !!formData[userNameParameterType.parameterType] && formData[userNameParameterType.parameterType]).value
        const secret = (!!formData && !!formData[clientSecretParameterType.parameterType] && formData[clientSecretParameterType.parameterType]).value

        if(isNullOrEmpty(grantType) || isNullOrEmpty(accessTokenUrl) || isNullOrEmpty(client_id) || isNullOrEmpty(authHeaderName) || isNullOrEmpty(headerPrefix)){
            isFormInvalid(true);
        }
        else if(grantType == "authorization_code" || grantType == "authorization_code_with_pkce"){
            if(isNullOrEmpty(authUrl)){
                isFormInvalid(true);
            }
            else if(grantType == "authorization_code_with_pkce"){
                if(isNullOrEmpty(codeChallengeMethod)){
                    isFormInvalid(true);
                }
            }
        }
        else if(grantType == "password"){
            if(isNullOrEmpty(username) || isNullOrEmpty(password)){
                isFormInvalid(true);
            }
        }
        else if(grantType == "client_credentials"){
            if(isNullOrEmpty(secret)){
                isFormInvalid(true);
            }
        }
        else{
            isFormInvalid(false)
        }

    };

    const checkIsChangedScope = () => {
        var scope = (!!formData && !!formData[scopeParameterType.parameterType] && formData[scopeParameterType.parameterType]).value;
        var clientId = (!!formData && !!formData[clientIdParameterType.parameterType] && formData[clientIdParameterType.parameterType]).value;
        var secret = (!!formData && !!formData[clientSecretParameterType.parameterType] && formData[clientSecretParameterType.parameterType]).value;

        if((existingClientData.scope != scope && !isNullOrEmpty(existingClientData.scope)) || (existingClientData.clientId != clientId && !isNullOrEmpty(existingClientData.clientId)) || (existingClientData.clientSecret != secret && !isNullOrEmpty(existingClientData.clientSecret))){
            setIsClientDataUpdated(true);

            formData[supportRefreshTokenParameterType.parameterType] ={
                value: "false",
                datasourceParameterTypeId: formData[supportRefreshTokenParameterType.parameterType].datasourceParameterTypeId,
                errorMsg: formData[supportRefreshTokenParameterType.parameterType].errorMsg,
                isValid: formData[supportRefreshTokenParameterType.parameterType].isValid
            };
            
            setSupportRefresh(false);

        }
        else if(existingClientData.scope == scope && existingClientData.clientId == clientId && existingClientData.clientSecret == secret){
            setIsClientDataUpdated(false)

            const refreshSupport = (
                !!formData &&
                !!formData[supportRefreshTokenParameterType.parameterType] &&
                formData[supportRefreshTokenParameterType.parameterType]
            ).value  == "true" ? true : false;

            formData[supportRefreshTokenParameterType.parameterType] ={
                value: `${refreshSupport}`,
                datasourceParameterTypeId: formData[supportRefreshTokenParameterType.parameterType].datasourceParameterTypeId,
                errorMsg: formData[supportRefreshTokenParameterType.parameterType].errorMsg,
                isValid: formData[supportRefreshTokenParameterType.parameterType].isValid
            };
            
            setSupportRefresh(refreshSupport);
        }
    };

    return (
        <React.Fragment>
            <OAuth2View
                headerPrefixParameterType={headerPrefixParameterType}
                grantTypeParameterType={grantTypeParameterType}
                accessTokenUrlParameterType={accessTokenUrlParameterType}
                refreshTokenUrlParameterType={refreshTokenUrlParameterType}
                clientIdParameterType={clientIdParameterType}
                clientSecretParameterType={clientSecretParameterType}
                scopeParameterType={scopeParameterType}
                stateParameterType={stateParameterType}
                sendClientAuthAsParameterType={sendClientAuthAsParameterType}
                callBackUrlParameterType={callBackUrlParameterType}
                authUrlParameterType={authUrlParameterType}
                authUsingBrowserParameterType={authUsingBrowserParameterType}
                userNameParameterType={userNameParameterType}
                passWordParameterType={passWordParameterType}
                formData={formData}
                checkRefreshTokenUrl={checkRefreshTokenUrl}
                refreshInputDisable={refreshInputDisable}
                parameters={oauth2Parameters}
                authRequestParameterType={authRequestParameterType}
                tokenRequestParameterType={tokenRequestParameterType}
                refreshRequestParameterType={refreshRequestParameterType}
                isFormInvalid={isFormInvalid}
                codeChallengeMethodParameterType={codeChallengeMethodParameterType}
                codeVerifierParameterType={codeVerifierParameterType}
                refreshSupport={supportRefresh}
                onCheckSupportRefreshToken={onCheckSupportRefreshToken}
                authHeaderNameParameterType={authHeaderNameParameterType}
                sendOAuthHeaderInRefresh={sendOAuthHeaderInRefresh}
                sendScopeInRefresh={sendScopeInRefresh}
                onCheckSendAuthHeaderInRefreshTokenRequest={onCheckSendAuthHeaderInRefreshTokenRequest}
                onCheckSendScopeInRefreshTokenRequest={onCheckSendScopeInRefreshTokenRequest}
            />
        </React.Fragment>
    )
}

export default OAuth2Controller;

export const OAuth2ParametersController = (props) => {

    const { formData, parameters, isFormInvalid,authRequestParameterType,tokenRequestParameterType,refreshRequestParameterType} = props;

    // useEffect(() => {
    //     getOAuth2ParameterValue();
    // })

    // const getOAuth2ParameterValue = () => {
    //     formData[authRequestParameterType.parameterType] = {
    //         value: formData[authRequestParameterType.parameterType].value == null || formData[authRequestParameterType.parameterType].value == "" ? "[]" : formData[authRequestParameterType.parameterType].value,
    //         datasourceParameterTypeId: formData[authRequestParameterType.parameterType].datasourceParameterTypeId,
    //         errorMsg: formData[authRequestParameterType.parameterType].errorMsg,
    //         isValid: formData[authRequestParameterType.parameterType].isValid
    //     };
    // };

    formData[authRequestParameterType.parameterType] = {
        value: formData[authRequestParameterType.parameterType].value == null || formData[authRequestParameterType.parameterType].value == "" ? "[]" : formData[authRequestParameterType.parameterType].value,
        datasourceParameterTypeId: formData[authRequestParameterType.parameterType].datasourceParameterTypeId,
        errorMsg: formData[authRequestParameterType.parameterType].errorMsg,
        isValid: formData[authRequestParameterType.parameterType].isValid
    };

    formData[tokenRequestParameterType.parameterType] = {
        value: formData[tokenRequestParameterType.parameterType].value == null || formData[tokenRequestParameterType.parameterType].value == "" ? "[]" : formData[tokenRequestParameterType.parameterType].value,
        datasourceParameterTypeId: formData[tokenRequestParameterType.parameterType].datasourceParameterTypeId,
        errorMsg: formData[tokenRequestParameterType.parameterType].errorMsg,
        isValid: formData[tokenRequestParameterType.parameterType].isValid
    };

    formData[refreshRequestParameterType.parameterType] = {
        value: formData[refreshRequestParameterType.parameterType].value == null || formData[refreshRequestParameterType.parameterType].value == "" ? "[]" : formData[refreshRequestParameterType.parameterType].value,
        datasourceParameterTypeId: formData[refreshRequestParameterType.parameterType].datasourceParameterTypeId,
        errorMsg: formData[refreshRequestParameterType.parameterType].errorMsg,
        isValid: formData[refreshRequestParameterType.parameterType].isValid
    };

    return (
        <React.Fragment>
            <OAuth2Parameters
                formData={formData}
                parameters={authRequestParameterType}
                isFormInvalid={isFormInvalid}
                authRequestParameterType={authRequestParameterType}
                tokenRequestParameterType={tokenRequestParameterType}
                refreshRequestParameterType={refreshRequestParameterType}
            />
        </React.Fragment>
    )
}