import React from 'react';
import './sheets-overlay.scss';
import { AsheetLogoExcelSvg } from '../../../constants/common-svgs';


const SheetsOverlay = ({connectionIcon, sheetsIcon, property}) => {
    return (
        <div className="fetching-data-container">
            <div className="fetching-data-content">
                {`Fetching ${property}, please wait ...`}
                <div className="fetching-data-icons">
                    <img src={`/assets/${connectionIcon}`} alt="Connection Icon" />
                    <img src= {`/assets/back-arrow-animation.svg`} alt="Back Arrow Animation" />
                    <span>{AsheetLogoExcelSvg}</span>
                    <img src= {`/assets/back-arrow-animation.svg`} alt="Back Arrow Animation" />
                    <img src={`${sheetsIcon}`} alt="Sheets Icon" />
                </div>
            </div>
        </div>
    );
};

export default SheetsOverlay;
