import React from "react";
import "./Order-complete.scss";
import { CloseOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { ASheetCompleteLogo, ASheetCompleteLogoSvg } from "../../constants/common-svgs";

function Ordercomplete(props) {
    const { orderStatus } = props;
    const navigate = useNavigate();

    return (
        <>
        <div className='asheet-icon'>
            <span>{ASheetCompleteLogoSvg}</span>
        </div>        
        <div className="container">
            {                
                orderStatus ? <div className="card">                    
                    <div className="order-header">
                        <div className="order-header_logo">
                            <img src={"./assets/green-icon.gif"} alt="gif" />
                        </div>
                        <div className="order-header_info">
                            <div>
                                <span>Thanks for choosing </span> 
                                <span>{ASheetCompleteLogo}</span>
                            </div>                                                        
                            <div>We have received the payment. Enjoy rendering data to Excel Sheets</div>
                        </div>
                    </div>
                    <div className="button-div">
                    <Button variant="contained" className="continue-button" onClick={() => navigate("/")}>
                        {"Proceed to dashboard"}
                    </Button>
                    </div>
                </div> : (orderStatus === false ? (
                    <div className="card">
                    <div className="payment-status-div">
                        <CloseOutlined style={{ fontSize: "189px", color: "red" }} />
                    </div>
                    <h4 className="closeIcon">Your payment is Failed</h4>

                    {/* <div>
                        Your order no. is
                    </div> */}
                    <button
                        className="shop_button"
                        type="button"
                        onClick={() => navigate(-1)}
                    >
                        Try Again
                    </button>
                </div> ) : <div></div> )
                }
        </div>
        </>        
    );
}

export default Ordercomplete;
