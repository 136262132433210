import React from 'react'
import "./CheckoutView.scss";
import CalculateCheckoutView from '../../common/calculateCheckout/CalculateCheckoutView';
import { ASheetCompleteLogoSvg } from '../../../constants/common-svgs';

function CheckoutView({ planList, onHandlePlanList, featureData, developerPrice, userList, hoursList, user,
  setUser,isUpdatePayment,unusedAmount,
  hour,updateSubscription,
  setHour, totalPrice, isDeveloperSetUp, setDeveloperSetUp, pFlag ,onBuyPlan,boughtPlanDetails}) {
  return (
    <div className="mainDiv">
      <div className="rightDiv">
        <div>
           {
            !pFlag && <><div className='asheet-logo'><span>{ASheetCompleteLogoSvg}</span>
          </div></>
        }
        </div>

        <div><CalculateCheckoutView planList={planList} onHandlePlanList={onHandlePlanList}
          featureData={featureData} developerPrice={developerPrice}
          userList={userList}
          hoursList={hoursList}
          user={user}
          setUser={setUser}
          hour={hour}
          setHour={setHour}
          totalPrice={totalPrice}
          isDeveloperSetUp={isDeveloperSetUp}
          setDeveloperSetUp={setDeveloperSetUp}
          pFlag={pFlag}
          onBuyPlan={onBuyPlan}
          boughtPlanDetails={boughtPlanDetails}
          isUpdatePayment={isUpdatePayment}
          updateSubscription={updateSubscription}
          unusedAmount={unusedAmount}
        />
        </div>
      </div>
      <div>
      {
        !pFlag && <div className="leftDIv">
          <div className="add-padding">
            <div className='display-contact'>
              <div className='display-contactus-text'>{"Contact Us"}</div>
              <label>{"+7210063532"}</label>
            </div>
            <div className='description-section'>
              <label>Its small but a big impact</label>

              <div> On an Average you save your <span>$18 per</span> day per user if you
                spend <span>15-30 mins</span> everyday and average<span>$180 per</span> per month
                on just downloading and re-importing your excel sheets and with
                chances to error.</div>
            </div>
            <div className='logo'>
              <img src="./assets/contactus.png" alt="logo" />
            </div>


          </div>
        </div>
      }
    </div>
    </div>
  )
}

export default CheckoutView