export const ASConnection = ({ flag, className, size }) => {
    return (
        <>
            <svg
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                width={size ? size : "23.296"}
                height={size ? size : "25"}
                viewBox="0 0 23.296 25"
            >
                <g id="Group_1" data-name="Group 1" transform="translate(-28 -139)">
                    <path
                        id="database"
                        d="M12.076,3C7.614,3,4,5.486,4,8.556s3.614,5.556,8.076,5.556,8.076-2.486,8.076-5.556S16.537,3,12.076,3M4,11.333V15.5c0,3.069,3.614,5.556,8.076,5.556s8.076-2.486,8.076-5.556V11.333c0,3.069-3.614,5.556-8.076,5.556S4,14.4,4,11.333m0,6.944v4.167C4,25.514,7.614,28,12.076,28s8.076-2.486,8.076-5.556V18.278c0,3.069-3.614,5.556-8.076,5.556S4,21.347,4,18.278Z"
                        transform="translate(24 136)"
                        fill={flag ? "#FFF" : "#16365f"}
                    />
                    <path
                        id="database-2"
                        data-name="database"
                        d="M10.145,3c-.856,0,.4-.446,0,0-.512.27.459,9.166,0,9.178-.1.081,6.145-2.481,6.145-4.864S13.54,3,10.145,3m0,10.785c0,1.633.028,1.948,0,3.235,3.395,0,6.145-1.93,6.145-4.314V9.471c0,2.383-2.75,4.314-6.145,4.314m0,5.392c.075.429-.016,2.106,0,3.235-.042.086-.44,0,0,0,3.395,0,6.145-1.931,6.145-4.314V14.863c0,2.383-6.145,4.314-6.145,4.314Z"
                        transform="translate(35.006 139.874)"
                        fill={flag ? "#FFF" : "#16365f"}
                    />
                </g>
            </svg>
        </>
    );
};
