import React from "react";
import "./SelectSubscriptionPlan.scss"

export default function SelectSubscriptionPlan(props){
    
    const { planList , onHandlePlanList, pFlag } = props;

    return(
        <div className={pFlag ? "plan-type-container-update" : "plan-type-container"}>
            <text>Select a Plan Type</text>
            <div className="root">
              {planList?.map((plan, index) => (
                <>
                  <div
                    className="plane-type"
                    onClick={() => onHandlePlanList(plan)}
                  >
                    <div
                      className={
                        plan.isSelected
                          ? "plane-type-item_selected"
                          : "plane-type-item"
                      }
                    >
                      <span>{plan.planName}</span>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
    )
};