import { FormatListBulleted, Usb, WorkspacesOutlined } from "@mui/icons-material";
import { AppEnum } from "../../constants/app-enum";
import { ASDataCollections } from "../../shared/menu-icons/as-data-collections";
import { ASConnection } from "../../shared/menu-icons/as-connection";
import { ASGroups } from "../../shared/menu-icons/as-groups";

export class DataCollectionsState {

    dataCollections = [];
    filteredDataCollections = [];
    uniqueFilteredDataCollections = [];
    searchedCollectionList = [];
    dataCollectionsHeader = [
        { headerTitle: "Data Collection Name" },
        { headerTitle: "Connection" },
        // { headerTitle: "Category" },
        { headerTitle: "Last Accessed" },
        { headerTitle: "Authorized users" },
        { headerTitle: " " },
    ];
    filterHeader = [
        {
            filterName: "Connection",
            filterId: AppEnum.CollectionFilterId.connection,
            isSelected: true,
            icon: <ASConnection size={'1em'} className={'data-coll-icon'} />
        },
        {
            filterName: "Group Name",
            filterId: AppEnum.CollectionFilterId.groupName,
            isSelected: false,
            icon: <ASGroups size={'1em'} className={'data-coll-icon'} />
        }
        // {
        //     filterName: "Collection as source",
        //     filterId: AppEnum.CollectionFilterId.collectionAsSource,
        //     isSelected: false,
        //     icon:  <ASConnection size={'1em'} className={'data-coll-icon'} />
        // },
        // {
        //     filterName: "Category",
        //     filterId: AppEnum.CollectionFilterId.category,
        //     isSelected: false,
        //     icon: <FormatListBulleted className="filter-icons" />
        // },
    ];
    collectionAsSourceOptions = [
        {
            title: "All",
            isSelected: true,
        },
        {
            title: "Yes",
            isSelected: false,
        },
        {
            title: "No",
            isSelected: false,
        },
    ];
    selectedFilterId = AppEnum.CollectionFilterId.connection;
    isShrink = false;
    filterMenuName = '';
    collectionEnchorEl = null;
    selectedCollectionIndex = null;
    isCollectionMenuOpen = false;
    isShowPopUp = false;
    collectionUId = "";
    errorMessage = null;
    collectionAsDataSource = 'All'
    filteredDataCollectionsAsSource = [];
}