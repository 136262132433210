import React, { Fragment, useCallback, useEffect, useState } from "react";
import Groups from "../../components/group-subgroups/groups";
import * as GroupService from '../../services/groups-service';
import _ from 'lodash';
import { getDataSources } from "../../services/data-source.service";
import { AppEnum } from "../../constants/app-enum";
import { getRemainingBalance } from "../../services/license-service";
import { useDispatch } from "react-redux";
import { setRemainingFeatureBalance } from "../../store/slices/as-Slice";


class UpdateNameModel {
  groupName = '';
  newGroupName = '';
  childGroupName = '';
  newChildGroupName = '';
}

const GroupsController = () => {

  const [groups, setGroups] = useState([]);
  const [tempGroups, setTempGroups] = useState([]);
  const [updateName, setupdateName] = useState(new UpdateNameModel());
  const [openGroups, setOpenGroups] = useState([]);
  const [editableGroupIndex, setEditableGroupIndex] = useState(null);
  const [editableSubgroupIndex, setEditableSubgroupIndex] = useState(null);
  const [dataSources, setDataSources] = useState([]);
  const dispatch = useDispatch();


  useEffect(() => {
    getGroups();
  }, [])

  //Fetching all the groups and subgroups
  const getGroups = () => {
    GroupService.getAllGroups().then((response) => {

      if (response?.data) {
        setGroups(response?.data);
        let data = _.cloneDeep(response?.data);
        setTempGroups([...data])
      }
    })
  }

  useEffect(() => {
    dispatch(setRemainingFeatureBalance(null));

    getDataSources(null).then((res) => {
      if (res?.data) {

        setDataSources(
          res?.data.map((_e, index) => ({
            ..._e,
            key: Date.now() + index,
          }))
        )

      }
    })

    getRemainingBalance(AppEnum.FeatureType.CONNECTION_LIMIT).then((res) =>{
      if(res?.data?.featureCD != null){
        dispatch(setRemainingFeatureBalance(res.data.remainingFeatureUnit));
      }
    })
    
  }, []);

  //handles Group and particular subgroup collapse
  const handleGroupToggle = (index) => {
    const updatedOpenGroups = [...openGroups];
    const groupIndex = updatedOpenGroups.indexOf(index);

    if (groupIndex === -1) {
      updatedOpenGroups.push(index);
    } else {
      updatedOpenGroups.splice(groupIndex, 1);
    }

    setOpenGroups(updatedOpenGroups);
  };

  const handleEditGroup = (groupIndex) => {
    setupdateName(new UpdateNameModel());
    setEditableGroupIndex(groupIndex);
    setEditableSubgroupIndex(null); // Clear subgroup editing state
  };

  const handleEditSubgroup = (groupIndex, subgroupIndex) => {
    setEditableGroupIndex(groupIndex);
    setEditableSubgroupIndex(subgroupIndex);
  };

  const handleGroupChange = (groupIndex, value) => {
    const prevData = [...tempGroups];
    setGroups((prevGroups) => {
      const updatedGroups = [...prevGroups];
      updatedGroups[groupIndex] = {
        ...updatedGroups[groupIndex],
        groupName: value,
      };

      handleUpdateGroup(prevData[groupIndex].groupName, value)
      return updatedGroups;
    });
  };

  const handleUpdateGroup = (prevValue, value) => {
    setupdateName((prevState) => {
      return {
        ...prevState, groupName: prevValue,
        newGroupName: value,
      }
    });
  };

  const handleSubGroupChange = (groupIndex, subGroupIndex, value) => {
    const prevData = [...tempGroups];

    let groupData = _.cloneDeep([...groups]);
    const updatedGroups = groupData;
    updatedGroups[groupIndex].subGroups[subGroupIndex].name = value;
    setGroups(updatedGroups);

    handleUpdateSubGroup(prevData[groupIndex].groupName, prevData[groupIndex].subGroups[subGroupIndex].name, value)
  };

  const handleUpdateSubGroup = (groupName, prevValue, value) => {
    setupdateName((prevState) => {
      return {
        ...prevState,
        groupName: groupName,
        childGroupName: prevValue,
        newChildGroupName: value,
      }
    });
  };

  const handleSave = () => {
    GroupService.updateName(updateName).then((response) => {
      if (response?.data) {
        getGroups();
      }
    }).catch((error) => {
      const prevData = [...tempGroups];
      setGroups(prevData);
    })

    setupdateName(new UpdateNameModel());
    setEditableGroupIndex(null);
    setEditableSubgroupIndex(null);
  };

  const handleCancelEdit = () => {
    setGroups(tempGroups);
    setupdateName(new UpdateNameModel());

    // Clear editable states
    setEditableGroupIndex(null);
    setEditableSubgroupIndex(null);
  };


  return (
    <Fragment>
      <Groups
        groups={groups}
        handleGroupToggle={handleGroupToggle}
        openGroups={openGroups}
        editableGroupIndex={editableGroupIndex}
        handleEditGroup={handleEditGroup}
        handleSave={handleSave}
        handleCancelEdit={handleCancelEdit}
        handleEditSubgroup={handleEditSubgroup}
        editableSubgroupIndex={editableSubgroupIndex}
        handleUpdateGroup={handleUpdateGroup}
        handleGroupChange={handleGroupChange}
        handleSubGroupChange={handleSubGroupChange}
      />
    </Fragment>
  )

}

export default GroupsController;