const formatGridDate = (dateTime) => {
    if (isNaN(Date.parse(dateTime))) {
        return dateTime;
    }

    const date = new Date(dateTime);
    let year, month, day, hours, minutes, seconds;

    if (dateTime.endsWith('Z')) {
        // UTC timezone
        year = date.getUTCFullYear();
        month = String(date.getUTCMonth() + 1).padStart(2, '0');
        day = String(date.getUTCDate()).padStart(2, '0');
        hours = String(date.getUTCHours()).padStart(2, '0');
        minutes = String(date.getUTCMinutes()).padStart(2, '0');
        seconds = String(date.getUTCSeconds()).padStart(2, '0');
    } else {
        // Local timezone
        year = date.getFullYear();
        month = String(date.getMonth() + 1).padStart(2, '0');
        day = String(date.getDate()).padStart(2, '0');
        hours = String(date.getHours()).padStart(2, '0');
        minutes = String(date.getMinutes()).padStart(2, '0');
        seconds = String(date.getSeconds()).padStart(2, '0');
    }

    return `${year}-${month}-${day}, ${hours}:${minutes}:${seconds}`;
};

export const getReadableDateFormate = (gridColumnKeyData, rows, isDataTypePresent) => {
    let updatedRows = null;

    if (isDataTypePresent) {
        const dateTimeColumns = gridColumnKeyData
            .filter(column => column?.description && column?.description?.dataTypeName && column?.description?.dataTypeName == "datetime")
            .map(column => column?.description?.columnName);

        if (dateTimeColumns) {
            updatedRows = rows?.map(row => {
                const newRow = { ...row };
                dateTimeColumns?.forEach(column => {
                    if (newRow[column]) {
                        newRow[column] = formatGridDate(newRow[column]);
                    }
                });
                return newRow;
            });
        }
    }
    else {
        updatedRows = rows?.map(row => {
            const newRow = { ...row };
            Object.keys(newRow).forEach(key => {
                let value = newRow[key]

                const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[\+\-]\d{2}:\d{2})?$/;
                let isValidDate = isoDatePattern.test(value);

                if (isValidDate && !isNaN(Date.parse(value))) {
                    const parsedDate = new Date(value);
                    if (!isNaN(parsedDate.getTime())) {
                        if (value?.includes("T")) {
                            newRow[key] = formatGridDate(newRow[key]);
                        }
                    }
                }
            });
            return newRow;
        });
    }
    return updatedRows;
}


export const updateDisplayOrder = (newColumnDefs, columns) => {
    const updatedColumns = columns?.map(column => {
        const index = newColumnDefs?.findIndex(col => col.field === column.columnName);
        if (index !== -1) {
            return {
                ...column,
                displayOrder: index
            };
        }
        return column;
    });
    return updatedColumns;
};

export function debounce(func, wait) {
    let timeout;
    return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}