import { Button } from "@mui/material";
import { ASButton } from "../../../shared/as-button/as-button";
import "./confirmation-model.scss";

const ConfirmationModel = (props) => {
    const { open, selectedConnection, stateConnectionParameter, onConfirmationClose } = props;
    return (
        <>
        {open ? 
            <div className="confirmation-dialog-overlay">
                <div className="confirmation-dialog">
                    <div className="confirmation-text">
                        Are you sure you want to <span> {stateConnectionParameter} </span>
                        the Connection to {selectedConnection?.description}
                    </div>

                    <div className="confirmation-button-div">
                        <Button className="confirmation-no-button" onClick={()=>onConfirmationClose()}> No </Button>
                        <ASButton primary={true} onClick={()=>onConfirmationClose(stateConnectionParameter)}> Yes </ASButton>
                    </div>
                </div>
            </div>
        : null }
        </>
    );
}
export default ConfirmationModel;