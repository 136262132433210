import { axios } from "../core/axios.interceptor";

const baseURL = `collections/groups`;

export async function getAllGroups() {
    const response = await axios.get(`${baseURL}`);
    return response;
}

export async function updateName(model) {
    const response = await axios.put(`${baseURL}`, model);
    return response;
}