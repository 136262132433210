export class UserPermissionState {

    userInfo = {};
    userCollectionPermission = [];
    userConnectionPermission = [];
    userRoles = [];
    currentUserRole = '';
    selectedCollections = [];
    selectedConnections = [];
    searchedConnectionList = [];
    searchedCollectionList = [];
}