import { ASheetCompleteLogo } from "../../../constants/common-svgs";
import { ASButton } from "../../../shared/as-button/as-button";
import "./starting-page.scss";

const StartingPage = (props) => {
    const { handleConnectClick } = props;
    return (
        <div className="main-div">
            <div className="welcome-text">
                Welcome to <svg className="asheet-svg">{ASheetCompleteLogo}</svg>
            </div>

            <div>There is no connection made yet.</div>

            <div className="info-text">
                Please <span onClick={handleConnectClick}> connect </span>
                to your favorite data source
            </div>
            <div className="connect-data-source">
                <ASButton primary={true} onClick={handleConnectClick}>
                    <label className="connect-button-text"> Connect to Your Data Source</label>
                </ASButton>
            </div>
        </div>
    );
}
export default StartingPage;