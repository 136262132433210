export const filterType = [
    {
        type : "enumeration",
        value: [
            {
                name : "is not empty",
                value : "is not empty"
            },
            {
                name : "is empty",
                value : "is empty"
            },
            {
                name : "is exactly",
                value : "is exactly"
            }
        ]
    },
    {
        type : "datetime",
        value: []
    },
    {
        type : "String",
        value: [
            {
                name : "Equal",
                value : "EQ"
            },
            {
                name : "not Equal",
                value : "NEQ"
            },
            {
                name : "Contains",
                value : "CONTAINS_TOKEN"
            },
            {
                name : "Not Contains",
                value : "NOT_CONTAINS_TOKEN"
            }
        ]
    },
    {
        type : "number",
        value: [
            {
                name : "is equal to",
                value : "is equal to"
            },
            {
                name : "greater than",
                value : "greater than"
            },
            {
                name : "greater or equal to",
                value : "greater or equal to"
            },
            {
                name : "less than",
                value : "less than"
            },
            {
                name : "less than or equal to",
                value : "less than or equal to"
            },
            {
                name : "is not equal to",
                value : "is not equal to"
            },
            {
                name : "is not empty",
                value : "is not empty"
            },
            {
                name : "is empty",
                value : "is empty"
            }
        ]
    }
]