import { tableBorderRight } from "../../../controllers/data-collections/add-new-data-collection/add-new-data-collection";
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import './data-after-transformation.scss';
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import { useParams } from "react-router-dom";
import DataGridTable from "../../common/data-grid/data-grid";
import ExpandableView from "../../common/expandableView/expandableView";
import { useSelector } from "react-redux";


const DataAfterTransformation = (props) => {

    const { testedDataTransformedList, testedDataTransformedColumneKeys, columnSchemaList, collectionColumnSchema, testedDataList } = props.state;
    const isAllColunmSelected = props.isShowHeader ? collectionColumnSchema.every(columnName =>
        columnSchemaList.some(columnDetail => columnDetail.columnName === columnName.columnName)
    )
    : testedDataTransformedColumneKeys.every(columnName =>
        columnSchemaList.some(columnDetail => columnDetail.columnName === columnName)
    );

    const showSelectAll = (props.isShowHeader && testedDataList.length == 0 && collectionColumnSchema.length > 0) || testedDataList.length > 0;
    const isExpandedView = useSelector((state) => state.asState.isExpandedView);
    return (
        <Paper
            sx={{
                minHeight: "100px",
                width: "1250px",
                padding: "20px",
                paddingBottom:"0px"
            }}
            ref={props.prevStepRef}
        >
            <>
            <ExpandableView>
                <div className="test-step-container" style={isExpandedView ? { maxHeight:"calc(100vh - 95px) " } : undefined}>
                    {showSelectAll && <div>
                        <Checkbox
                            color="primary"
                            checked={(columnSchemaList?.every(column => column.isSelected) && isAllColunmSelected)}
                            onChange={(event) => props.onCheckAllColumnHandler(event)}
                            indeterminate={
                                columnSchemaList?.some(item => item.isSelected) &&
                                !(columnSchemaList?.every(item => item.isSelected) 
                                && isAllColunmSelected)
                            }
                            sx={{
                                ...muiCheckboxStyle,
                                paddingLeft: '16px',
                                '&.MuiCheckbox-indeterminate': {
                                    color: '#018786', // Change the color of the indeterminate state to green
                                },
                            }}
                        />
                        <span className="checkbox-title">Select All</span>
                    </div>}
                    <TableContainer sx={{ overflowX: 'unset' }}>
                        <Table
                            //sx={{ minWidth: 750 }}
                            size='small'
                        >
                            <TableHead>
                                <TableRow>
                                    {
                                        (testedDataList.length > 0)  ? 
                                        testedDataTransformedColumneKeys?.map((column, index) => {
                                            const columnSelection = columnSchemaList?.find(item => (item?.columnName?.toLowerCase().trim() || item?.ColumnName?.toLowerCase().trim()) === column?.toLowerCase().trim());
                                            return (
                                                <TableCell


                                                    key={`${index}+${column}`}
                                                    align='left' scope="row" component={'th'}
                                                    sx={((testedDataTransformedColumneKeys?.length - 1) === index) ?
                                                        { borderRight: 'none' } : tableBorderRight}
                                                    id={`keys-${index}`}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        checked={columnSelection?.isSelected ? true : false}
                                                        onChange={(event) => props.onCheckColumnHandler(!columnSelection?.isSelected, column)}
                                                        sx={{
                                                            ...muiCheckboxStyle,
                                                            paddingLeft: '0px',
                                                        }}
                                                    //sx={muiCheckboxStyle}
                                                    //disabled={collectionUId ? true : false}
                                                    />
                                                    {column}
                                                </TableCell>)
                                        }) : 
                                        collectionColumnSchema?.length > 0 ?
                                        collectionColumnSchema?.map((column, index) => {
                                            const columnSelection = columnSchemaList?.find(item => (item?.columnName?.toLowerCase().trim() || item?.ColumnName?.toLowerCase().trim()) === column?.columnName?.toLowerCase().trim());
                                            return (
                                                <TableCell


                                                    key={`${index}+${column}`}
                                                    align='left' scope="row" component={'th'}
                                                    sx={((testedDataTransformedColumneKeys?.length - 1) === index) ?
                                                        { borderRight: 'none' } : tableBorderRight}
                                                    id={`keys-${index}`}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        checked={columnSelection?.isSelected ? true : false}
                                                        onChange={(event) => props.onCheckColumnHandler(!columnSelection?.isSelected, column)}
                                                        sx={{
                                                            ...muiCheckboxStyle,
                                                            paddingLeft: '0px',
                                                        }}
                                                    //sx={muiCheckboxStyle}
                                                    //disabled={collectionUId ? true : false}
                                                    />
                                                    {column?.columnName}
                                                </TableCell>)
                                        }) : <div>
                                            <label>No Column to select</label>
                                        </div>
                                    }
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    testedDataTransformedList?.map((column, index) => {

                                        return (
                                            <TableRow
                                                tabIndex={-1}
                                                key={`test-column${index}`}
                                            >
                                                {
                                                    testedDataTransformedColumneKeys?.map((col, colIndex) => {
                                                        return (
                                                            <TableCell
                                                                key={`${colIndex}+keys`}
                                                                align='left' scope="row" component={'td'}
                                                                id={`keys-${colIndex}`}
                                                                sx={((testedDataTransformedColumneKeys?.length - 1) === colIndex) ?
                                                                    { borderRight: 'none' } : tableBorderRight}
                                                            >
                                                                {/* // for accecssing dynamic props from object */}
                                                                {column[col] === null ? "" : String(column[col])}
                                                            </TableCell>)
                                                    })
                                                }
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                </ExpandableView>
            </>
        </Paper>
    );
}

export default DataAfterTransformation;
