import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import UserPermission from "../../components/user-permission/user-permission";
import { UserPermissionState } from "./user-permission-state";
import * as userService from '../../services/user-service';
import _ from "lodash";
import jwtDecode from "jwt-decode";

// connection header column lables
const connectionTableHeaderRowCells = [
    { id: 'connection', numeric: false, disablePadding: true, label: 'Connection', },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type', },
];

// collection permission head cells label
const collectionTableHeaderRowCells = [
    { id: 'collectionName', numeric: false, disablePadding: true, label: 'Collection Name', },
    { id: 'collectionGroup', numeric: false, disablePadding: false, label: 'Collection Group', },
    { id: 'connection', numeric: false, disablePadding: false, label: 'Connection', },
    // { id: 'category', numeric: false, disablePadding: false, label: 'Category', },
];

const UserPermissionController = () => {

    const location = useLocation();
    const searchCollectionRef = useRef(null);
    const searchConnectionRef = useRef(null);

    // user permission state
    const [state, setState] = useState(new UserPermissionState());
    const [userRole, setUserRole] = useState({ role: null, isRoleChanged: false });
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {

        if (location?.state) {
            setState((prevState) => {
                return { ...prevState, userInfo: location.state, currentUserRole: location.state.userRole }
            });
            setUserRole((prevUserRole) => ({
                ...prevUserRole, role: location.state.userRole,
            }));
        }

        getUserCollectionData(location?.state.userId);
        getUserConnectionData(location?.state.userId);
        getUserRoles();
        disableUserPermission();

    }, [])


    // it fetches user's collection permission data
    const getUserCollectionData = (userId) => {

        userService.getUserCollection(userId).then((response) => {
            if (response?.data) {

                selectAllCollectionHandler(null, response.data);
                setState((prevState) => { return { ...prevState, userCollectionPermission: response.data, } });
            }
        })
    }

    // it fetches user's all role list
    const getUserRoles = () => {

        userService.getUserRoleList().then((response) => {
            if (response?.data)
                setState((prevState) => { return { ...prevState, userRoles: response.data, } });
        })
    }

    const handleUserRoleChange = (event) => {
        if (userRole.role !== event.target.value) {
            setUserRole((prevUserRole) => ({
                ...prevUserRole,
                isRoleChanged: true,
            }));
        }
        else {
            setUserRole((prevUserRole) => ({
                ...prevUserRole,
                isRoleChanged: false,
            }));
        }

        setState((prevState) => {
            return { ...prevState, currentUserRole: event.target.value }
        });
    };

    const handleSaveUpdatedUserRole = (userRole) => {

        const userId = location?.state.userId;
        userService.updateUserRole(userId, userRole).then((response) => {
            if (response?.data) {
                setUserRole((prevUserRole) => ({
                    ...prevUserRole,
                    role:userRole,
                    isRoleChanged: false,
                }));
            }
        })
        .catch((error) => {

        })
    }

    // it saves user's collection permission
    const saveUserCollectionPermission = (hasPermission, collection) => {

        let userCollectionPermission = state.userCollectionPermission;
        let collectionPermissionData = checkUncheckCheckbox(userCollectionPermission, hasPermission, collection, true);

        setState((prevState) => { return { ...prevState, userCollectionPermission: collectionPermissionData, } });

        let collectionPermission = {
            collectionUId: collection.collectionUId,
            userId: state.userInfo.userId,
            hasPermission: hasPermission
        }

        userService.saveCollectionPermission(collectionPermission).then((response) => {

            if (response?.data)
                getUserCollectionData(state.userInfo.userId);
        })
            .catch((error) => {
                getUserCollectionData(location?.state.userId);
            })
    }

    // it selects or deSelects all the available options
    const selectAllCollectionHandler = (event, response) => {

        if (event?.target?.checked) {
            setAllSelectedCollections(state.userCollectionPermission);
            return;
        }
        else if (event === null) {
            setAllSelectedCollections(response);
            return;
        }
        setState((prevState) => { return { ...prevState, selectedCollections: [], } });
    };

    // if all permission is granted then all the collections will have permission otherwise all permission will be removed
    // from collection
    const onCheckAllCollectionHandler = (event) => {

        let finalCollectionPermission = state.userCollectionPermission;
        let currentPermissionCount = 0;

        if (event?.target?.checked) {
            let totalPermissionCount = finalCollectionPermission.filter((permission) => permission.hasPermission === false)?.length;

            finalCollectionPermission?.forEach((collection) => {
                if (!collection.hasPermission) {
                    currentPermissionCount++;
                    saveAllCollectionPermission(true, collection, totalPermissionCount, currentPermissionCount);
                }
            })
        }
        else {
            let totalPermissionCount = finalCollectionPermission.filter((permission) => permission.hasPermission === true)?.length;

            finalCollectionPermission?.forEach((collection) => {
                if (collection.hasPermission) {
                    currentPermissionCount++;
                    saveAllCollectionPermission(false, collection, totalPermissionCount, currentPermissionCount);
                }
            })
        }

        let collectionPermission = checkUncheckAllCheckboxes(finalCollectionPermission, event?.target?.checked)
        setState((prevState) => { return { ...prevState, userCollectionPermission: collectionPermission, } });

        setAllSelectedCollections(collectionPermission);
    };

    // it saves all collection permission
    const saveAllCollectionPermission = (hasPermission, collection, totalPermissionCount, currentPermissionCount) => {

        let collectionPermission = {
            collectionUId: collection.collectionUId,
            userId: state.userInfo.userId,
            hasPermission: hasPermission
        }

        userService.saveCollectionPermission(collectionPermission)
            .then((response) => {
                if (response?.data) {
                    if (totalPermissionCount === currentPermissionCount)
                        getUserCollectionData(state.userInfo.userId);
                }
            })
            .catch((error) => {
                getUserCollectionData(location?.state.userId);
            })
    }


    // it sets the all selected collections array
    const setAllSelectedCollections = (userCollectionPermission) => {

        const hasPermissions = userCollectionPermission.filter((permission) => permission.hasPermission === true);
        setState((prevState) => { return { ...prevState, selectedCollections: hasPermissions, } });
        return;
    }

    //-------- for connection-----------

    // It checks or unchecks the checkboxes instantly
    const checkUncheckCheckbox = (data, hasPermission, chosenRow, isCollection) => {

        _.forEach(data, function (item) {
            let uId = (isCollection ? (item.collectionUId === chosenRow.collectionUId) : (item.connectionUId === chosenRow.connectionUId));

            if (uId && hasPermission)
                _.set(item, 'hasPermission', true);
            if (uId && !hasPermission)
                _.set(item, 'hasPermission', false);
        })
        return data;
    }

    // It checks or unchecks the checkboxes instantly
    const checkUncheckAllCheckboxes = (data, isChecked) => {

        _.forEach(data, function (item) {

            if (isChecked)
                _.set(item, 'hasPermission', true);
            if (!isChecked)
                _.set(item, 'hasPermission', false);
        })
        return data;
    }

    // it fetches user's connection permission data
    const getUserConnectionData = (userId) => {

        userService.getUserConnection(userId).then((response) => {
            if (response?.data) {

                selectAllConnectionHandler(null, response.data);
                setState((prevState) => { return { ...prevState, userConnectionPermission: response.data, } });
            }
        })
    }

    // it saves user's connection permission
    const saveUserConnectionPermission = (hasPermission, connection) => {

        let userConnectionPermission = state.userConnectionPermission;
        let connectionPermissionData = checkUncheckCheckbox(userConnectionPermission, hasPermission, connection, false);
        setState((prevState) => { return { ...prevState, userConnectionPermission: connectionPermissionData, } });

        let connectionPermission = {
            connectionUId: connection.connectionUId,
            userId: state.userInfo.userId,
            hasPermission: hasPermission
        }

        userService.saveConnectionPermission(connectionPermission).then((response) => {
            if (response?.data) {

                getUserConnectionData(state.userInfo.userId);
            }
        })
            .catch((error) => {
                getUserConnectionData(location?.state.userId);
            })
    }

    // it selects or deSelects all the available options
    const selectAllConnectionHandler = (event, response) => {

        if (event?.target?.checked) {
            setAllSelectedConnections(state.userConnectionPermission);
            return;
        }
        else if (event === null) {
            setAllSelectedConnections(response);
            return;
        }

        setState((prevState) => { return { ...prevState, selectedConnections: [], } });
    };

    // it sets the all selected collections array
    const setAllSelectedConnections = (userConnectionPermission) => {

        const hasPermissions = userConnectionPermission.filter((permission) => permission.hasPermission === true);
        setState((prevState) => { return { ...prevState, selectedConnections: hasPermissions, } });
        return;
    }

    // if all permission is granted then all the connections will have permission otherwise all permission will be removed
    // from connection
    const onCheckAllConnectionHandler = (event) => {

        let finalConnectionPermission = state.userConnectionPermission;
        let currentPermissionCount = 0;

        if (event?.target?.checked) {
            let totalPermissionCount = finalConnectionPermission.filter((permission) => permission.hasPermission === false)?.length;

            finalConnectionPermission?.forEach((connection) => {
                if (!connection.hasPermission) {
                    currentPermissionCount++;
                    saveAllConnectionPermission(true, connection, totalPermissionCount, currentPermissionCount);
                }
            })
        }
        else {
            let totalPermissionCount = finalConnectionPermission.filter((permission) => permission.hasPermission === true)?.length;

            finalConnectionPermission?.forEach((connection) => {
                if (connection.hasPermission) {
                    currentPermissionCount++;
                    saveAllConnectionPermission(false, connection, totalPermissionCount, currentPermissionCount);
                }
            })
        }

        let connectionPermission = checkUncheckAllCheckboxes(finalConnectionPermission, event?.target?.checked);
        setState((prevState) => { return { ...prevState, userConnectionPermission: connectionPermission, } });

        setAllSelectedConnections(connectionPermission);
    };

    // it saves all connection permission
    const saveAllConnectionPermission = (hasPermission, connection, totalPermissionCount, currentPermissionCount) => {

        let connectionPermission = {
            connectionUId: connection.connectionUId,
            userId: state.userInfo.userId,
            hasPermission: hasPermission
        }

        userService.saveConnectionPermission(connectionPermission)
            .then((response) => {
                if (response?.data) {
                    if (totalPermissionCount === currentPermissionCount)
                        getUserConnectionData(state.userInfo.userId);
                }
            })
            .catch((error) => {
                getUserConnectionData(location?.state.userId);
            })
    }

    // searches the collection by the entered key
    const onChangeSearchCollectionHandler = (searchedKey) => {

        let collectionList = [];
        let searchedCollectionList = [];

        if (searchedKey) {
            collectionList = state.userCollectionPermission;
            searchedCollectionList = [];

            _.forEach(collectionList, function (collection) {
                if (_.includes(collection?.collectionName?.toLowerCase().trim(), searchedKey?.toLowerCase().trim()))
                    searchedCollectionList.push(collection);
            })
        }
        else
            searchCollectionRef.current.value = '';

        setState((prevState) => { return { ...prevState, searchedCollectionList: searchedKey ? searchedCollectionList : [] } });
    }

    // searches the connection by the entered key
    const onChangeSearchConnectionHandler = (searchedKey) => {

        let connectionList = [];
        let searchedConnectionList = [];

        if (searchedKey) {
            connectionList = state.userConnectionPermission;
            searchedConnectionList = [];

            _.forEach(connectionList, function (connection) {
                if (_.includes(connection?.name?.toLowerCase().trim(), searchedKey?.toLowerCase().trim()))
                    searchedConnectionList.push(connection);
            })
        }
        else
            searchConnectionRef.current.value = '';

        setState((prevState) => { return { ...prevState, searchedConnectionList: searchedKey ? searchedConnectionList : [] } });
    }

    const disableUserPermission = () => {
        const aSTenantId = sessionStorage.getItem('ASTenantId') || localStorage.getItem('ASTenantId');
        // let aSTenantId = localStorage.getItem('ASTenantId');
        let accessToken = localStorage.getItem('token');
        const decodedToken = jwtDecode(accessToken);

        if (aSTenantId !== decodedToken?.ASTenantUId){
            setIsDisabled(true);
        }
    }

    return (
        <Fragment>
            <UserPermission
                state={state}
                collectionTableHeaderRowCells={collectionTableHeaderRowCells}
                connectionTableHeaderRowCells={connectionTableHeaderRowCells}
                saveUserConnectionPermission={saveUserConnectionPermission}
                onCheckAllConnectionHandler={onCheckAllConnectionHandler}
                saveUserCollectionPermission={saveUserCollectionPermission}
                onCheckAllCollectionHandler={onCheckAllCollectionHandler}
                onChangeSearchConnectionHandler={onChangeSearchConnectionHandler}
                onChangeSearchCollectionHandler={onChangeSearchCollectionHandler}
                searchCollectionRef={searchCollectionRef}
                searchConnectionRef={searchConnectionRef}
                handleUserRoleChange={handleUserRoleChange}
                userRole={userRole}
                handleSaveUpdatedUserRole={handleSaveUpdatedUserRole}
                isDisabled={isDisabled}
            />
        </Fragment>
    )

}

export default UserPermissionController;