import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from "@mui/material";
import React from "react";
import styles from "../../../styles/_exports.module.scss";
import { MenuItem, Paper } from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";
import { selectedMenuItemBackgroundColor } from "../collection-parameters/collection-parameters";
import { useParams } from "react-router-dom";
import MessageToaster from "../../../shared/message-toaster/message-toaster";
import './select-connection.scss'

const SelectConnection = (props) => {

    const { collectionUId: collectionUId } = useParams();

    const { connectionList, connectionUId, basicInfoError } = props.state;
    return (
        
        // <div ref={props.prevStepRef}>
        //     <Paper
        //         sx={{
        //             minHeight: "100px",
        //             width: "100%",
        //             padding: "20px",
        //         }}
        //     >
        //         {
        //             basicInfoError &&
        //             <div className="collection-error-div">
        //                 <MessageToaster errorMessage={basicInfoError} />
        //             </div>
        //         }

        //         <div className="basic-info-container">

        //             <ASTextField
        //                 className="role-text-field"
        //                 label="Select a Data Connection"
        //                 key="Select a Data Connection"
        //                 variant="outlined"
        //                 SelectProps={{
        //                     MenuProps: {
        //                         className: 'basic-info-select', sx: selectedMenuItemBackgroundColor
        //                     }
        //                 }}
        //                 select={true}
        //                 onChange={(event) =>
        //                     props.onSelectConnectionHandler(event.target.value)
        //                 }
        //                 disabled={collectionUId ? true : false}
        //                 value={connectionUId}
        //             >
        //                 {connectionList?.map((connection) => (
        //                     <MenuItem
        //                         key={connection.uId}
        //                         value={connection.uId}
        //                         name={connection?.name}
        //                     >
        //                         {connection?.name}
        //                     </MenuItem>
        //                 ))}
        //             </ASTextField>
        //         </div>
        //     </Paper>
        // </div >
        <>
        <div className="conn-card-container-type">
            {(collectionUId ? connectionList.filter(i => i.uId ==  connectionUId) : connectionList).map((card) => (
                <Card
                    key={card.key}
                    sx={{
                        width: "261px",
                        border: card.uId === connectionUId
                            ? `2px solid ${styles.primaryColor}` : "",
                        boxShadow: card.uId === connectionUId
                            ? "none !important" : "",
                        backgroundColor:collectionUId ? "#F2F2F2" : "",
                    }}
                    className='connection-card'
                >
                    <CardActionArea
                        onClick={() =>  props.onSelectConnectionHandler(card)}
                        className='card-action-area'
                        disabled={collectionUId ? true : false}
                    >
                        {
                            card.iconImage &&
                            <CardMedia
                                component="img"
                                sx={{ width: "auto !important", height: '44px' }}
                                // image={`/assets/${card.datasourceTypeIconImage}`}
                                // alt={card.datasourceTypeIconImage}
                                image={`/assets/${card.iconImage}`}
                                alt={card.iconImage}
                            />
                        }

                        <CardContent className="connection-name">
                            <Typography gutterBottom variant="body2" component="div" sx={{ marginBottom: '0px' }}>
                                {card.name}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            ))}
        </div>
    </>
    );
};

export default SelectConnection;