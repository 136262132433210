import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { ASTextField } from "../../../shared/as-text-fields";
import { useEffect } from "react";
import "./add-new-org.scss";
import MessageToaster from "../../../shared/message-toaster/message-toaster";
import { saveOrganization } from "../../../services/login-signup.service";
import { setToken } from "../../../shared/local-storage-handler/local-storage-handler";
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

class OrganizationModel {
    businessName = "";
    refreshToken = "";
}
const AddNewOrganization = (props) => {
    const { open, onClose, setSavedOrganization, refresh_token } = props;
    const [businessName, setBusinessName] = useState("");
    const [isButtonDisabled, setButtonDisable] = useState(true);
    const [saveOrganizationError, setSaveOrganizationError] = useState("");
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setSaveOrganizationError("");
    }, []);


    const onChangeDataBusinessNameHandler = (event) => {
        setSaveOrganizationError("");
        setBusinessName(event.target.value);
        if (event?.target?.value?.length > 0) {
            setButtonDisable(false);
        }
        else{
            setButtonDisable(true);
        }
    }

    const handleCancel = () => {
        setSaveOrganizationError("");
        setBusinessName("");
        setButtonDisable(true);
        onClose();
    };

    const handleOk = () => {
        setLoader(false);
        setButtonDisable(true);
        let organizationModel = { ...OrganizationModel };
        organizationModel.businessName = businessName;
        organizationModel.refreshToken = refresh_token;

        saveOrganization(organizationModel).then((response) => {

            setLoader(true);

            if (response?.hasError) {
                setSaveOrganizationError(response?.errorMessage);
                toast.error(response?.errorMessage);             
            }

            else if (response?.data?.hasError) {
                setSaveOrganizationError(response?.data?.error);
                toast.error(response?.data?.error);               
            }
            else {
                setToken("token", response?.data?.accessToken);
                setToken("refresh_token", response?.data?.refreshToken);
                setSavedOrganization(Math.random());
                onClose();
                toast.success("Organization Added Successfully.");
            }
        });
        setSaveOrganizationError("");
        setBusinessName('');
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: "10px", width: "500px", overflow: "hidden" } }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DialogTitle className="title">Add New Organization</DialogTitle>
            {
                saveOrganizationError &&
                <div className="save-organization-error">
                    <MessageToaster errorMessage={saveOrganizationError} />
                </div>
            }
            <DialogContent sx={{  paddingRight: '35px', paddingLeft: '35px', height: "150px" }}>

                <ASTextField
                    className="text-field"
                    type={"text"}
                    fullWidth
                    label="Business Name"
                    variant="outlined"
                    value={businessName}
                    disabled={false}
                    onChange={onChangeDataBusinessNameHandler}
                />

                <div className="buttons">
                    <Button onClick={handleCancel} className="cancel-button" >
                        Cancel
                    </Button>
                    <Button onClick={handleOk} className="accept-button" disabled={isButtonDisabled}>
                        {loader ? ("Create")
                            : (<CircularProgress
                                sx={{ display: loader ? "none" : "block" }}
                                size={24} />)}
                    </Button>
                </div>
            </DialogContent>

        </Dialog>
    );
};
export default AddNewOrganization;