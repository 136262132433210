import React, { useEffect, useState } from "react";
import ChangePasswordComponent from "../../../components/header-username-menu/changePassword/ChangePassword";
import * as UserService from "../../../services/changePassword.service";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MuiDialog from "../../../shared/mui-dialog/mui-dialog";

export default function ChangePassword(props) {
  const { open, handleCloseDialog, setMuiDialog, muiDialog ,passwordExists } = props;
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [error, setError] = useState("");
  const handlePasswordChange = (field) => (e) => {
    setError(null);
    const newValue = e.target.value.replace(/\s/g, '');
    setPasswords({
      ...passwords,
      [field]: newValue,
    });
  };

  const handleTogglePasswordVisibility = (field) => () => {
    setIsPasswordVisible((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const clearPasswordState = (isOnClosingDialog) => {
    setPasswords({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setIsPasswordVisible({
      oldPassword: false,
      newPassword: false,
      confirmPassword: false,
    });
    if (isOnClosingDialog) {
      setError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Destructure the passwords from the state
    const { newPassword, confirmPassword } = passwords;

    // Check if new password matches confirm password

    if (newPassword !== confirmPassword) {
      setError("The new password does not match the confirm password");
    }else if(passwordExists && passwords?.oldPassword === ""){
      setError("Please enter old password");
    }
     else {
      let form = new FormData();
      form.append("newPassword", passwords.newPassword);
      form.append("oldPassword", passwords.oldPassword);
      const response = await UserService.changePassword(form);
      if (response?.data) {        
        handleCloseDialog();
        setMuiDialog(true);
      } else if (response?.hasError) {

        setError(response?.errorMessage);
        toast.error(response?.errorMessage);
      }
      // Reset the form
      clearPasswordState(false);
    }
  };

  return (
    <>
      <ChangePasswordComponent
        open={open}
        handleCloseDialog={() => {
          handleCloseDialog();
          clearPasswordState(true);
        }}
        isPasswordVisible={isPasswordVisible}
        handleSubmit={handleSubmit}
        handleTogglePasswordVisibility={handleTogglePasswordVisibility}
        handlePasswordChange={handlePasswordChange}
        error={error}
        passwords={passwords}
        passwordExists={passwordExists}
      />
      <MuiDialog
          isShowPopUp={muiDialog}
          secondaryButtonAction={props?.closeConfirmationModal}
          primaryButtonAction={props?.handleClick}
          closeDialog={props?.closeConfirmationModal}
          secondaryButtonTitle="No"
          primaryButtonTitle="Yes"
          titleDescription="Do you want to logout from all the devices including this one?"
        />
      </>
  );
}
