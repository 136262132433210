import { Fragment, useEffect, useState } from "react";
import CollectionFilterColumns,  {CollectionFilterColumnFormView } from "../../../../components/data-collections/collection-filter-column/collection-filter-column";
import * as variableService from '../../../../services/variable-service';
import { CollectionFilterColumn } from "../new-data-collection-state";
import _ from 'lodash';
import MuiDialog from "../../../../shared/mui-dialog/mui-dialog";
import { toast } from "react-toastify";

const CollectionFilterColumnController = (props) => {

    const { state, setState, prevStepRef, setCollectionFilterColumnState, collectionFilterColumnState } = props;

    const [isAddNewCollectionColumnFillter, setIsAddNewCollectionColumnFilter] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    
    const [variables, setVariables] = useState();
    const [selectedOperators, setSelectedOperators] = useState(""); 

    useEffect(() => {
        setState((prevState) => { return { ...prevState} });
        fetchAllVariables();
    }, [])

    // it opens the form to add a new collection_column_filters
    const addNewCollectionFilterColumn = () => {
        setIsAddNewCollectionColumnFilter(true);
    }

    const onChangeColumnNameHandler = (event) => {
        setCollectionFilterColumnState((prevState) => {return {...prevState, columnName: event.target.value}});
    }

    const handleToggleHasMultiSelection = (event) => {
        setCollectionFilterColumnState((prevState) => {return {
            ...prevState,
            hasMultiSelection: event?.target?.checked
        }});
    };

    // it sets the collection filter column array state
    const setCollectionFilterColumn = (collectionFilterColumns) => {
        setState((prevState) => { return { ...prevState, collectionFilterColumns: collectionFilterColumns } });
    }

    // deletes the collection_column_filter from state
    const deleteCollectionColumnFilter = (index) => {
        setDeleteIndex(index);
        setState((prevState) => {
            return {
                ...prevState, isShowDeletePopUp: true
            }
        });

        if (state.isShowDeletePopUp) {
            let collectionFilterColumnObject = state.collectionFilterColumns;
            collectionFilterColumnObject.splice(deleteIndex, 1);
            
            setCollectionFilterColumn(collectionFilterColumnObject);
            setCollectionFilterColumnState(collectionFilterColumnObject);
            closeDialog();
        }
    };

    // opens form to edit the collection_column_filter
    const editCollectionFilterColumn = async (index) => {
        
        let collectionFilterColumns = state.collectionFilterColumns;
        let selectedcollectionFilterColumnState = collectionFilterColumns[index];

        setCollectionFilterColumnState(() => { return { ...selectedcollectionFilterColumnState, } });
        
        collectionFilterColumns[index].edit = true;

        if(collectionFilterColumns[index]?.operators.length > 0){
            setSelectedOperators(collectionFilterColumns[index]?.operators);
        }

        setCollectionFilterColumn(collectionFilterColumns);

        setIsEditMode(true);
    };

        // it closes the popup 
        const closeDialog = () => {
            setState((prevState) => {
                return {
                    ...prevState, isShowDeletePopUp: false
                }
            });
        }

        const fetchAllVariables = async () => {
            try {
                const response = await variableService.getSysAndUserDefinedVariablesList(); // Fetch all variables
                if (response?.data) {
                    const filteredData = response.data
                        .filter(variable => variable.sysDataTypeId === 5 || variable.sysDataTypeId === 8)
                        .map(variable => ({ name: variable.name, UId: variable.uId})); // Extract only name and UId
                    setVariables(filteredData); // Save the filtered variables in state
                }
            } catch (error) {
            }
        }

        const onHandleSelectVariableHandler = (event) => {
            setCollectionFilterColumnState((prevState) => ({
              ...prevState,
              variableUId: event.target.value.UId,
              variableName: event.target.value.name
            }));
        };

        const handleMenuItemClick = (event, option) => {
            event.preventDefault();
            const value = option.value;
            const newSelectedOperators = selectedOperators?.replace(/ /g, "").split(',').includes(value)
              ? selectedOperators?.replace(/ /g, "").split(',').filter(item => item !== value).join(' , ')
              : selectedOperators ? `${selectedOperators} , ${value}` : value;

            setSelectedOperators(newSelectedOperators);
            setCollectionFilterColumnState(prevState => ({
              ...prevState,
              operators: newSelectedOperators,
            }));
          };
        
        
    return (
        <Fragment>
            <MuiDialog
                isShowPopUp={state.isShowDeletePopUp}
                secondaryButtonAction={closeDialog}
                primaryButtonAction={deleteCollectionColumnFilter}
                closeDialog={closeDialog}
                secondaryButtonTitle="No"
                primaryButtonTitle="Yes"
                titleDescription="Are you sure you want to delete the collection filters?"
            />
            <CollectionFilterColumns
                prevStepRef={prevStepRef}
                onChangeColumnNameHandler={onChangeColumnNameHandler}
                handleToggleHasMultiSelection={handleToggleHasMultiSelection}
                addNewCollectionFilterColumn={addNewCollectionFilterColumn}
                editCollectionFilterColumn={editCollectionFilterColumn}
                deleteCollectionColumnFilter={deleteCollectionColumnFilter}
                isAddNewCollectionColumnFillter={isAddNewCollectionColumnFillter}
                setIsAddNewCollectionColumnFilter={setIsAddNewCollectionColumnFilter}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                state={state}
                setState={setState}
                collectionFilterColumnState={collectionFilterColumnState}
                setCollectionFilterColumnState={setCollectionFilterColumnState}
                onHandleSelectVariableHandler={onHandleSelectVariableHandler}
                variables={variables}
                setCollectionFilterColumn={setCollectionFilterColumn}
                handleMenuItemClick={handleMenuItemClick}
                closeDialog={closeDialog}
            />
        </Fragment>
    );
}

export default CollectionFilterColumnController;

export const CollectionFilterColumnForm = (props) => {

    const { state, setIsEditMode, index, setIsAddNewCollectionColumnFilter, setCollectionFilterColumn, 
        collectionFilterColumnState, setCollectionFilterColumnState, variables, setState} = props;

    // it cancels the process of adding the collection_column_filter
    const cancelAddingCollectionFilterColumn = () => {

        if (index !== null) {
            let collectionFilterColumnObject = state.collectionFilterColumns;
            collectionFilterColumnObject = _.map(collectionFilterColumnObject, parameter => _.omit(parameter, ['edit']));

            setCollectionFilterColumn(collectionFilterColumnObject);
        }
        setIsEditMode(false);
        setIsAddNewCollectionColumnFilter(false);
        resetCollectionFilterColumnState();
    }

    // it adds a new collection_column_filter
    const addCollectionFilterColumn = () => {
        let rowIndex = index;

        if (!collectionFilterColumnState.operators || collectionFilterColumnState.operators.trim() === '') {
            toast.error('No operators selected'); // Replace this with your preferred error handling
            return;
        }

        if (index !== null)
            updateCollectionFilterColumn(index);
        else {
            let collectionFilterColumnObject = { ...collectionFilterColumnState };
            let collectionFilterColumns = state.collectionFilterColumns;
            collectionFilterColumns?.push(collectionFilterColumnObject);

            setCollectionFilterColumn(collectionFilterColumns);
            resetCollectionFilterColumnState();
            setIsAddNewCollectionColumnFilter(false);
        }
    }

    // updates the selected collection_column_filter details
    const updateCollectionFilterColumn = (index) => {

        let collectionFilterColumnObject = state.collectionFilterColumns;
        collectionFilterColumnObject = _.map(collectionFilterColumnObject, parameter => _.omit(parameter, ['edit']));

        collectionFilterColumnObject[index] = { ...collectionFilterColumnState};

        setIsEditMode(false);
        setIsAddNewCollectionColumnFilter(false);
        setCollectionFilterColumn(collectionFilterColumnObject);
        resetCollectionFilterColumnState();
    };

    // it resets the collection_column_filter state to its initial state
    const resetCollectionFilterColumnState = () => {
        let collectionFilterColumnState = new CollectionFilterColumn();
        setCollectionFilterColumnState(() => {return{...collectionFilterColumnState}})
    }

    // if required fields are filled then is enables the add button
    let isSaveEnable = true;
    

    return (
        <CollectionFilterColumnFormView
            addCollectionFilterColumn={addCollectionFilterColumn}
            cancelAddingCollectionFilterColumn={cancelAddingCollectionFilterColumn}
            state={state}
            parameterIndex={index}
            isSaveEnable={isSaveEnable}
            collectionFilterColumnState={collectionFilterColumnState}
            setCollectionFilterColumnState={setCollectionFilterColumnState}
            variables={variables}
            onHandleSelectVariableHandler={props.onHandleSelectVariableHandler}
            onSelectHandleOperators={props.onSelectHandleOperators}
            handleToggleHasMultiSelection={props.handleToggleHasMultiSelection}
            onChangeColumnNameHandler={props.onChangeColumnNameHandler}
            handleMenuItemClick={props.handleMenuItemClick}
        />
    );
};

