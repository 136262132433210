import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "./CustomDropDown";
import { withStyles } from '@material-ui/core/styles';
import { OutlinedInput } from '@mui/material';

const styles = (theme) => ({
    outlinedInput: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none', },
        '& .MuiOutlinedInput-notchedOutline': { border: 'none', },
    },
});

function CustomDropDown({ classes, dropDownList, selectedValue, setValue }) {
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 80 }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
                <Select
                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedValue}
                    onChange={handleChange}
                    label="Age"
                    input={<OutlinedInput classes={{ notchedOutline: classes.outlinedInput }} />}
                >
                    {dropDownList?.map((item) => (
                        <MenuItem value={item.id} className='menuItemLabel'><span >{item.id} &nbsp;</span> {item.name}</MenuItem>
                    ))}

                </Select>
            </FormControl>
        </Box>
    );
}
export default withStyles(styles)(CustomDropDown);
