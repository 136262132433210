import { axios } from "../core/axios.interceptor";
// import { BASE_API } from "./api-url.service";
// import { http } from "./http-request.service";

// const baseURL = `connection/type`;

// export function getDataSourceType() {
//   return http.get(BASE_API, baseURL);
// }

// export function getDataSourceTypeByID(id) {
//   return http.get(BASE_API, `${baseURL}/${id}`);
// }

const baseURL = `connection-types`;

export async function getDataSourceType() {
  const response = await axios.get(baseURL);
  return response;
}

export async function getDataSourceTypeByID(id) {
  const response = await axios.get(`${baseURL}/${id}`);
  return response;
}
