// import { http } from "./http-request.service";
// import { BASE_API } from "./api-url.service";
import { axios } from "../core/axios.interceptor";

// const baseURL = "connection";

// export function getDataSources() {
//   return http.get(BASE_API, baseURL);
// }

// export function getDataSourceByUID(uId) {
//   return http.get(BASE_API, `${baseURL}/${uId}`);
// }

// export function addDataSourceType(dataSource) {
//   // return http.post(BASE_API, `${baseURL}/Add`, dataSource, false);
//   return http.post(BASE_API, `${baseURL}`, dataSource, false);
// }

// export function updateDataSourceType(dataSource) {
//   return http.put(BASE_API, `${baseURL}`, dataSource);
// }

// export function testDataSourceType(dataSource) {
//   return http.post(BASE_API, `${baseURL}/Test`, dataSource, false);
// }

const baseURL = "connections";

export async function getDataSources(isActive) {
  const response = await axios.get(isActive ? `${baseURL}?isActive=${isActive}` : baseURL);
  return response;
}

export async function getDataSourceByUID(uId) {
  const response = await axios.get(`${baseURL}/${uId}`);
  return response;
}

export async function addDataSourceType(dataSource) {
  const response = await axios.post(`${baseURL}`, dataSource);
  return response;
}

export async function disconnetCloudConnection(uId) {
  const response = await axios.put(`${baseURL}/disconnect-cloud-connections/${uId}`);
  return response;
}

export async function updateDataSourceType(dataSource) {
  const response = await axios.put(`${baseURL}/${dataSource?.uId}`, dataSource);
  return response;
}

export async function testDataSourceType(dataSource) {
  const response = await axios.post(`${baseURL}/test-result`, dataSource);
  return response;
}
export async function deleteDataSource(connectionUId) {
  const response = await axios.delete(`${baseURL}?connectionUId=${connectionUId}`);
  return response;
}

export async function getTemplateDataSourceByID(id) {
  const response = await axios.get(`${baseURL}/template/${id}`);
  return response;
}

export async function getConnectionByName(connectionName) {
  const response = await axios.get(`${baseURL}/existance?connectionName=${connectionName}`);
  return response;
}