import { axios } from "../core/axios.interceptor";

const invitedUserbaseURL = "invited-users";

export async function completeInvitation(invite) {
  const response = await axios.patch(`/${invitedUserbaseURL}`,invite);
  return response;
}
export async function getInviteUsertDetail(invite) {
  const response = await axios.get(
    `/${invitedUserbaseURL}/${invite}`
  );
  return response;
}
