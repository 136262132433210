export const AsheetPlugInConstant = {
    menuItems : [
        { label: 'Disconnect', action: 'disconnect' },
        { label: 'Re-Authorize', action: 're-Authorize' },
        { label: 'Delete', action: 'delete' },
        { label: 'Permissions', action: 'permissions' },
    ],   
};

export class AddConnectionComponents {
    static ADD_CONNECTION = "showAddConnection";
    static CONNECT_VIEW = "showConnectView";
    static CONNECTING_POPUP = "showConnectingPopup";
    static SUCCESSFULL_CONNECTED = "showSuccessfullConnectedPage";
}

export class ComponentRendering {
    showAddConnection = false;
    showConnectView = false;
    showConnectingPopup = false;
    showSuccessfullConnectedPage = false;
}