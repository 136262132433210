import { axios } from "../core/axios.interceptor";

const baseURL = "accounts";

export async function forgetPassword(model) {
    const response = await axios.post(`${baseURL}/password/reset-token`, model);
    return response;
}

export async function resetPassword(model) {
    const response = await axios.post(`${baseURL}/password`, model);
    return response;
}