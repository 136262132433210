import React, { useEffect,useState,Fragment,useRef,useCallback } from 'react';
import * as proxySecretService from '../../../../services/proxy-secret-service';
import ProxySecretComponent from '../../../../components/connections/connection-details/rest-api/proxy-secret';
import { toast } from "react-toastify";
import MuiDialog from '../../../../shared/mui-dialog/mui-dialog';
import { useASContext } from '../../../context-api/as-context';

export default function ProxySecretController(props){

    const { isDialogOpen, setIsDialogOpen} = props; 

    const[secretExists,setSecretExist] = useState(false);
    const[regenerateSecret,setRegenerateSecret] = useState(false);
    const[secret,setSecret] = useState("");
    const[secretCreatedDuration,setSecretCreatedDuration] = useState("");
    const [isShowPopUp, setIsShowPopUp] = useState(false);
    const passRef = useRef(null);
    const[apiRequestCompleted,setIsApiRequestCompleted]=useState(false);

    useEffect(()=>{
        if(isDialogOpen){
           getProxySecret();
        }
    },[isDialogOpen]);


    const getProxySecret = async () => {
        try {
            const response = await proxySecretService.getProxySecret();
            if (response?.data) {
                setSecretExist(true);
                setSecretCreatedDuration(`Last Generated on ${convertDatetime(response.data.createdDateTime)}`);
            }
            else{               
                setSecretExist(false);
                setRegenerateSecret(false);
            }
            setIsApiRequestCompleted(true);
        } catch (error) {
            toast.error(error);
        }
    }
    
    const convertDatetime = (isoString) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        // Parse the ISO date string into a Date object
        const date = new Date(isoString);
        
        // Extract parts of the date
        const day = date.getDate().toString().padStart(2, '0');
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        
        // Extract and format time
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const isPM = hours >= 12;
        
        if (hours > 12) {
          hours -= 12;
        } else if (hours === 0) {
          hours = 12; // Midnight case
        }
        
        // Format AM/PM
        const amPm = isPM ? 'PM' : 'AM';
        
        // Construct the formatted date-time string
        return `${day}/${month}/${year} ${hours}:${minutes} ${amPm}`;
      }
      
    const handleCancel = () => {
        setIsDialogOpen(false);
        setIsApiRequestCompleted(false);
        setSecret("");
        setSecretCreatedDuration("");
        setSecretExist(false);
        setRegenerateSecret(false);
    }

    const handleOk = () => {
        if(secretExists){
            setIsShowPopUp(true);
        }else{
            generateProxySecret();
        }
    }

    const regenerateProxySecret = async () => {
        try {
            closeDialog();
            const response = await proxySecretService.regenerateProxySecret();
            if (response?.data) {
                setSecret(response.data.secret);
                setRegenerateSecret(true);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const generateProxySecret = async () => {
        try {
            const response = await proxySecretService.generateProxySecret();
            if (response?.data) {
                setSecret(response.data.secret);
                setSecretExist(true);
                setRegenerateSecret(true);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const closeDialog = () => {
        setIsShowPopUp(false);
    };

    const copySecret = useCallback(() => {
        passRef.current?.select();
        window.navigator.clipboard.writeText(secret);
      }, [secret]);

    return(
        <Fragment>
            <MuiDialog
                isShowPopUp={isShowPopUp}
                secondaryButtonAction={closeDialog}
                primaryButtonAction={regenerateProxySecret}
                closeDialog={closeDialog}
                secondaryButtonTitle="No"
                primaryButtonTitle="Yes"
                titleDescription="If you regenrate secret your existing Proxy Connector instance would be stopped?"
            />

            {
                apiRequestCompleted && <ProxySecretComponent
                    secretExists={secretExists}
                    regenerateSecret={regenerateSecret}
                    secret={secret}
                    secretCreatedDuration={secretCreatedDuration}
                    open={isDialogOpen} 
                    handleCancel={handleCancel}
                    handleOk={handleOk}
                    copySecret={copySecret}
                    passRef={passRef}
                />
            }
        </Fragment>
        
    )
}