import { useNavigate } from "react-router-dom";
import StartingPage from "../../../components/asheetPlugin/starting-page/starting-page";

const StartingPageContoller = () => {
    const navigate = useNavigate();

    const handleConnectClick = () => {
        navigate("/plugin/add-connection");
    }

    return (
        <>
            <StartingPage handleConnectClick={handleConnectClick} />
        </>
    );
}
export default StartingPageContoller;