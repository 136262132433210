import React from "react";
import { ASButton } from "../../../shared/as-button/as-button";
import { Link } from "react-router-dom";
import "./UpgradeButtonView.scss";

const UpgradeButtonView = (props) => { 
    const { balanceFeature } = props;
    return (
        <div className="upgrade-btn-data">

            <ASButton sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                onClick={() => { }}
                rounded={true}
                primary={true}
            >
                <Link to="/update-checkout">
                    UPGRADE TO ADD MORE {balanceFeature}
                </Link>
            </ASButton>

        </div>
    );
}

export default UpgradeButtonView;