import { TextField } from "@mui/material";
import styles from "./../styles/_exports.module.scss";
import { styled } from "@mui/material/styles";

export const ASTextField = styled(TextField)(
    ({ disabled }) => ({
      "& label.Mui-focused": {
        color: styles.primaryColor,
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: styles.primaryColor,
        },
      },
    }),
    // Additional styles for the disabled state
    ({ disabled }) =>
      disabled && {
        "& label.Mui-focused": {
          color: 'grey', // Change to the color you want when disabled
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: 'grey', // Change to the color you want when disabled
          },
        },
      }
  )
