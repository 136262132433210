import { useEffect } from "react";
import { MsSqlServerView } from "../../../components/connections/connection-details/ms-sql-server/ms-sql-server-view";
import { formValidationCheck, handleChangeInputValidation } from "../../../services/data-source-validation";

export function MsSqlServer({
    datasourceTypeParameters,
    isFormInvalid,
    saveFormData,
    formData,
}) {

    useEffect(() => {
        const formObjectC = {};
        formObjectC.name = {
            value: (formData && formData?.name?.value) || "",
            isValid: (formData && !!formData?.name?.value) || true,
        };

        if (formData?.uID) {
            formObjectC.uID = { value: formData.uID.value || "", isValid: true };
        }

        datasourceTypeParameters?.forEach((input) => {
            formObjectC[input.parameterType] = {
                datasourceParameterTypeId: input.id,
                value: (formData && formData[input.parameterType])?.value || "",
                isValid:
                    !!(formData && formData[input.parameterType])?.value ||
                    !input.isRequired,
                errorMsg: (formData && formData[input.parameterType])?.errorMsg,
            };
        });

        saveFormData(formObjectC);
        formValidationCheck(formObjectC,isFormInvalid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeInput = (dataSource, event) => handleChangeInputValidation(dataSource,
        event, formData, saveFormData, datasourceTypeParameters, isFormInvalid);
    return (
        <MsSqlServerView
            handleChangeInput={handleChangeInput}
            formData={formData}
            datasourceTypeParameters={datasourceTypeParameters}
        />
    );
}
