
  // sets the local storage
  export const setToken = (storageName, storageValue) => {
    localStorage.setItem(storageName, storageValue);
  }
  
  // removes token
  export const removeToken = () => {
    localStorage.removeItem("token");
  }

  // fetches token
  export const getToken = () => {
    let token = localStorage.getItem("token");
    return token;
  }

  // fetches item
  export const getItem = (storageName) => {
    let token = localStorage.getItem(storageName);
    return token;
  }

  // removes item 
  export const removeItem= (storageName) => {
    localStorage.removeItem(storageName);
  }

  //fetches refresh_token
  export const getRefreshToken = () =>{
    let refresh_token = localStorage.getItem("refresh_token");
    return refresh_token;
  }