import { RestServiceConstants } from "../constants/rest-service-constant";
export const RestApiNavData = [
    {
        label: "No Auth",
        for: "No_Auth",
        tabId: 0,
        key: null
    },
    {
        label: "API Key",
        for: "API_Key",
        tabId: 1,
        key: RestServiceConstants.API_KEY
    },
    {
        label: "Bearer Token",
        for: "Bearer_Token",
        tabId: 2,
        key: RestServiceConstants.BEARER
    },
    {
        label: "OAuth 2.0",
        for: "OAuth_2.0",
        tabId: 3,
        key: RestServiceConstants.OAUTH2
    },
    {
        label: "Basic Auth",
        for: "Basic_Auth",
        tabId: 4,
        key: RestServiceConstants.BASIC_AUTH
    },
    // {
    //     label: "Cookie Auth",
    //     for: "Cookie_Auth",
    //     tabId: 5,
    //     key: RestServiceConstants.AWS_SIGN
    // },
    // {
    //     label: "AWS Signature",
    //     for: "AWS_Signature",
    //     tabId: 6,
    //     key: null
    // },
];

export const refineFormData = (formData, selectedStep, restOrCloudApplicationsId, isTally = false, isTestingData = false) => {
    const formDataC = { ...formData };

    delete formDataC.name;
    delete formDataC.uID;

    const formResponse = {
        name: formData?.name?.value,
        uId: formData?.uID && formData?.uID?.value,
        sysConnectionTypeId: isTestingData ? 2 : selectedStep?.id,
        isSaasAuthSource: false,
        description: formDataC?.description || "",
        baseConnectionTypeId : formDataC?.baseConnectionTypeId ?? null,
        // datasourceParameters: []
        parameters: []
    };

    let connectionTypeId = 0;
    if(restOrCloudApplicationsId.has(selectedStep.id)){
        connectionTypeId = 99;
    }else{
        connectionTypeId = selectedStep.id;
    }

    switch (connectionTypeId) {
        case 1:
            formResponse.parameters = msSql(formDataC);
            break;
        case 99:
            formResponse.parameters = restService(formDataC,isTally);
            break;
        case 6:
            formResponse.parameters = postgreSql(formDataC);
            break;
        case 4:
            formResponse.parameters = mySql(formDataC);
            break;
        default:
            formResponse.parameters = [];
    }

    return formResponse;
};

const _setDataSource = (formDataC, key) => ({
    // datasourceParameterTypeId: formDataC[key].datasourceParameterTypeId,
    // datasourceParameterValue: formDataC[key].value,
    // datasourceParameterName: key,
    configConnectionParameterTypeId: formDataC[key]?.datasourceParameterTypeId,
    parameterValue: formDataC[key]?.value,
    parameterName: key,
});

const msSql = (formDataC) => {

    const res = [];

    for (let key in formDataC) {
        res.push(_setDataSource(formDataC, key));
    }

    return res;

}

const restService = (formDataC,isTally) => {
    const res = [];
    const _formDataC = { ...formDataC };

    const authTYpe = RestApiNavData.find(ele => ele.label === _formDataC[RestServiceConstants.AUTH_TYPE].value)
    
    if (_formDataC[RestServiceConstants.BEARER_TOKEN.SEND_AS]?.value !== RestServiceConstants.BEARER_TOKEN.SEND_AS_OPTION[0]?.value){
        delete _formDataC[RestServiceConstants.BEARER_TOKEN.JSON_BODY];
    }
    if(authTYpe?.label == "No Auth"){
        res.push(_setDataSource(_formDataC, RestServiceConstants.AUTH_TYPE));
        res.push(_setDataSource(_formDataC, RestServiceConstants.API_ENDPOINT));
        return res;
    }

    if(authTYpe?.label == "Basic Auth"){
        res.push(_setDataSource(_formDataC, RestServiceConstants.API_ENDPOINT));
        res.push(_setDataSource(_formDataC, RestServiceConstants.REST_TALLY_CONNECTOR_URL));
    }

    if(isTally && authTYpe == null){
        res.push(_setDataSource(_formDataC, RestServiceConstants.REST_TALLY_CONNECTOR_URL));
    }

    res.push(_setDataSource(_formDataC, RestServiceConstants.AUTH_TYPE));
    res.push(_setDataSource(_formDataC, RestServiceConstants.JWT_JSON_PATH));
    res.push(_setDataSource(_formDataC, RestServiceConstants.REQUIRE_PROXY_CONNECTOR));

   
    for (let key in _formDataC) {
        if (authTYpe != null && key.startsWith(authTYpe.key))
            res.push(_setDataSource(_formDataC, key));
    }

    return res;
}

const postgreSql = (formDataC) =>{
    const res = [];

    for(let key in formDataC){
        res.push(_setDataSource(formDataC,key));
    }

    return res;
}

const mySql = (formDataC) =>{
    const res = [];

    for(let key in formDataC){
        res.push(_setDataSource(formDataC,key));
    }

    return res;
}