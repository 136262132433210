import { axios } from "../core/axios.interceptor";
const baseURL = "sys";
// api for getting user list
export async function getAllSystemVariables() {
  const response = await axios.get(`${baseURL}/variables`);
  return response;
}

// api for getting userdefined list
export async function getAllUserdefinedVariables() {
  const response = await axios.get(`variables?isUserDefineVariableOnly=true`);
  return response;
}

export async function getTemplateCompany() {
  const response = await axios.get(`${baseURL}/template-company`);
  return response;
}

//Save User Define Variable
export async function saveUserVariable(userModel) {
  const response = await axios.post(`variables`, userModel);
  return response;
}
// api for getting collection type list
export async function getCollectionTypeList(isVariable = false ,isCollection = false) {
  const response = await axios.get(`${baseURL}/datatypes?isVariable=${isVariable}&isCollection=${isCollection}`);
  return response;
}
export async function deleteVariables(UId) {
  const response = await axios.delete(`/variables/${UId}`);
  return response;
}
//api for updating user defined variable
export async function updateUserVariable(userModel,UId){
  const response = await axios.put(`/variables/${UId}`,userModel);
  return response;
}
//api to get user defined variable by uid
export async function getUserDefinedVariableByUId(UId, isASTenantUIdApplicable = true){
  const response = await axios.get(`/variables/${UId}?isASTenantUIdApplicable=${isASTenantUIdApplicable}`);
  return response;
}

export async function getSysAndUserDefinedVariablesList(){
  const response = await axios.get(`/variables/all`);
  return response;
}