import { Autocomplete, InputLabel, MenuItem } from "@mui/material";
import { ASTextField } from "../../../../../shared/as-text-fields";
import { ASButton } from "../../../../../shared/as-button/as-button";

export default function AddSortForm(props) {

    const { filterObject, filterFields, handleOnChnageFilterInput, isSort, conditionOrArr, handleAddCondtionCancel, addCondition, isDisabled} = props;

    return (
        <div>
            <div className="fields-group">
                <div className="sort-fiels-group-part">
                    <InputLabel>Field</InputLabel>
                    <Autocomplete
                        id="value"
                        freeSolo
                        options={filterFields?.map((option) => ({
                            columnName: option.columnName,
                            displayName: option.displayName,
                        }))}
                        getOptionLabel={(option) => option.displayName || ''}
                        isOptionEqualToValue={(option, value) => option.columnName === value}
                        sx={{ margin: "0px !important", height: "60px" }}
                        renderOption={(props, item) => (
                            <customMenuItem
                                {...props}
                                key={item.columnName}
                                value={item.columnName}
                                name={item.displayName}
                            >
                                {item.displayName}
                            </customMenuItem>
                        )}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                handleOnChnageFilterInput({ target: { value: newValue.columnName } }, "field", filterFields);
                            } else {
                                handleOnChnageFilterInput({ target: { value: '' } }, "field", filterFields);
                            }
                        }}
                        value={filterFields.find(option => option.columnName === filterObject?.field) || null}
                        renderInput={(params) => (
                            <ASTextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: filterObject?.field && params.InputProps.endAdornment,
                                    className: "fields-inputs"
                                }}
                                sx={{ width: "100%" }}
                            />
                        )}
                    />
                </div>
                <div className="sort-condition-group-part">
                    <InputLabel>Order By</InputLabel>
                    {/* <ASTextField autoComplete="off" value={filterObject?.condition} onChange={(e) => handleOnChnageFilterInput(e,"condition")}  /> */}
                    <ASTextField
                        className="fields-selection"
                        select={true}
                        onChange={(e) =>
                            handleOnChnageFilterInput(
                                e,
                                isSort ? "value" : "condition"
                            )
                        }
                        value={
                            isSort ? filterObject?.value : filterObject?.condition
                        }
                        InputProps={{
                            className: "fields-inputs"
                        }}
                    >
                        {conditionOrArr?.map((item) => (
                            <MenuItem
                                key={item.name}
                                value={item.value}
                                name={item.name}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </ASTextField>
                </div>
            </div>

            {/* <div className="add-condition-div">Click on Add to add condition</div> */}
            <div className="add-condition-button-div">
                <div className="cancel-button-div">
                    <button
                        className="cancel-link-button"
                        onClick={() => handleAddCondtionCancel()}
                    >
                        Cancel
                    </button>
                </div>

                <ASButton
                    onClick={() => addCondition()}
                    sx={{
                        color: "white !important",
                        backgroundColor: "#6B859E !important",
                        borderRadius: "6px !important",
                        boxShadow: "0px 2px 4px #6B859E !important",
                        padding: "5px 25px !important",
                    }}
                    disabled={isDisabled}
                >
                    Ok
                </ASButton>
            </div>
        </div>
    )
}