import { Button, Menu, MenuItem } from "@mui/material"
import { AppEnum } from "../../../constants/app-enum";
import _ from "lodash";
import './plugin-collections.scss'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { menuIconSvg, successIconSvg } from "../../../constants/common-svgs";
import { ASButton } from "../../../shared/as-button/as-button";
import { menuItems } from "../../common/plugin-menu/plugin-menu";
import { truncateString } from "../../../shared/code-challanges/code-challanges";

const PluginConnections = ({ dataCollections, back, selectedCollection, activeTab }) => {

    const [filteredDataCollections, setFilteredDataCollection] = useState();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();
    const filterDataCollectionByMenu = () => {
        let filteredDataCollections;
        if (activeTab === AppEnum.CollectionFilterId.groupName)
            filteredDataCollections = _.filter(dataCollections, function (collection) {
                return collection?.groupName === selectedCollection?.groupName;
            });


        if (activeTab === AppEnum.CollectionFilterId.connection)
            filteredDataCollections = _.filter(dataCollections, function (collection) {
                return collection?.connection?.id === selectedCollection?.connection?.id;
            });
        setFilteredDataCollection(filteredDataCollections)
    }

    useEffect(() => {
        filterDataCollectionByMenu()
    }, [selectedCollection])

    const navigateToCollectionParameter = (collection) => {
        navigate(`/plugin/data-collections/${collection.uId}`, {
            state: collection
        })
    }

    const formatDate = (dateTime) => {
        const date = new Date(dateTime);
        const now = new Date();

        const isToday = date.getDate() === now.getDate() &&
            date.getMonth() === now.getMonth() &&
            date.getFullYear() === now.getFullYear();

        const isYesterday = date.getDate() === now.getDate() - 1 &&
            date.getMonth() === now.getMonth() &&
            date.getFullYear() === now.getFullYear();

        if (isToday) {
            const options = { hour: 'numeric', minute: 'numeric', hour12: true };
            return `${date.toLocaleString('en-US', options)} Today`;
        }
        else if (isYesterday) {
            const options = { hour: 'numeric', minute: 'numeric', hour12: true };
            return `${date.toLocaleString('en-US', options)} Yesterday`;
        }
        else {
            const options = { hour: 'numeric', minute: 'numeric', hour12: true };
            const formattedTime = date.toLocaleString('en-US', options);
            const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
            return `${formattedTime} - ${formattedDate}`;
        }
    }
    

    return (
        <div>
            <div className="flex border-bottom">
                <ArrowBackIcon onClick={back}>Back</ArrowBackIcon>
                <div className="source">
                    <img
                        src={`../../assets/${selectedCollection.connection.connectionImageIcon}`}
                        alt="Xero"
                    />{' '}
                    {activeTab === 'connection' ? truncateString(selectedCollection?.connection?.name, 28) : (<div>{selectedCollection?.groupName}<span className="f-9">{truncateString(selectedCollection.connection.name, 18)}</span></div>)}
                    <img
                        className="plugin-collections-info-icon"
                        src={`/assets/infoIcon.svg`}
                    />
                </div>
            </div>
            
            <div className="content">

                {(
                    <div id="groupName" className="active">
                        {
                            filteredDataCollections?.map((item, index) => {
                                return <div onClick={() => { navigateToCollectionParameter(item) }} className="connction-list">
                                    {index === filteredDataCollections.length - 1 ?
                                        <img className="" src={`/assets/failure-icon.svg`} />
                                        : successIconSvg}
                                    <div className="plugin-collections-content">
                                        {item?.name}
                                        <div className="f-9">
                                            {index === filteredDataCollections.length - 1 ?
                                                `Last run was failed at ${formatDate(item.lastAccessedDateTime)}`
                                                : `Last ran at ${formatDate(item.lastAccessedDateTime)} successfully`}
                                        </div>
                                        <div className="plugin-menu-icon" onClick={(event) => handleClick(event)}
                                        >
                                            {menuIconSvg} 
                                        </div> 
                                        
                                    </div>
                                </div>
                            })
                        }
                    </div>
                )}

            </div>
            
            <div className="add-new-connection-btn">
                <ASButton
                    primary={true}
                >
                    Add New
                </ASButton>
            </div>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="plugin-menu-container"
                >
                {menuItems.map((item, index) => (
                    <MenuItem
                    key={index}
                    onClick={handleClose}
                    className="plugin-menu-container-data"
                    >
                    {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default PluginConnections