import React from "react";
import "./CalculateCheckoutView.scss";
import SelectSubscriptionPlan from "../../checkout-process/select-subscription-plan/SelectSubscriptionPlan";
import { UpdateSubscriptionHeader } from "../../checkout-process/update-subscription/UpdateSubscriptionHeader";
import CheckoutPaymentArea from "../../checkout-process/chekout-payment-area/CheckoutPaymentArea";
import { ASheetCompleteLogo } from "../../../constants/common-svgs";

function CalculateCheckoutView({
  planList,
  onHandlePlanList,
  featureData,
  developerPrice,
  userList,
  hoursList,
  user,
  setUser,
  hour,
  setHour,
  totalPrice,
  isDeveloperSetUp,
  setDeveloperSetUp,
  pFlag,
  onBuyPlan,
  boughtPlanDetails,
  isUpdatePayment,
  updateSubscription,
  unusedAmount
}) {
  return (
    <>
      {
        pFlag && 
        <UpdateSubscriptionHeader boughtPlanDetails={boughtPlanDetails}/>
      }

      <div className={pFlag ? "" : "checkoutDiv"}>
        {
          !pFlag && <><div className="heading">Thanks for choosing <span>{ASheetCompleteLogo} .</span>
          
          </div>
            <div className="sub-heading">
              You are just one step away from making your Excel Sheets dynamic data
              driven
            </div></>
        }


        <div className="subscription-plan">
          <SelectSubscriptionPlan planList={planList} onHandlePlanList={onHandlePlanList} pFlag={pFlag}/>
          <CheckoutPaymentArea featureData={featureData}
                                developerPrice={developerPrice}
                                userList={userList}
                                hoursList={hoursList}
                                user={user}
                                setUser={setUser}
                                hour={hour}
                                setHour={setHour}
                                totalPrice={totalPrice}
                                isDeveloperSetUp={isDeveloperSetUp}
                                setDeveloperSetUp={setDeveloperSetUp}
                                onBuyPlan={onBuyPlan}
                                pFlag={pFlag}
                                isUpdatePayment={isUpdatePayment}
                                updateSubscription={updateSubscription}
                                unusedAmount={unusedAmount}/>
        </div>
      </div>
    </>
  );
}

export default CalculateCheckoutView;
