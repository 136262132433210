import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { ASTextField } from "../../../shared/as-text-fields";
import { useEffect } from "react";
import * as collectionService from "../../../services/data-collection.service";
import "./copy-collection.scss";
import MessageToaster from "../../../shared/message-toaster/message-toaster";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const CopyCollection = (props) => {
    const { open, onClose, collectionUId,setDataCollectionListRefresh } = props;
    const [collectionName, setCollectionName] = useState('');
    const [error,setError] = useState("");
    const [collectionNameError,setCollectionNameError] = useState("");


     useEffect(() => {
         if (collectionName !== collectionNameError) {
            setError("");
         }
     }, [collectionName]);


    const onChangeDataCollectionNameHandler = (event) => {
        setCollectionName(event.target.value);
    }

    const handleCancel = () => {
        setCollectionName('');
        setError("");
        onClose();
    };

    const handleOk = async () => {
        // Perform the copy collection action here
        if(collectionName.length === 0){
            setError("Collection name cannot be empty");
            return
        }

        const res = await collectionService.getCollectionByName(collectionName);

        if(res.data){
            setCollectionNameError(collectionName);
            setError("Colection with this name already exists");
            return
        }else{
            setCollectionNameError("");
            setError("");
        }

        collectionService.saveCopyCollection(collectionUId,collectionName)
                .then((response) => {
                        if(response?.data?.hasError){
                            setError(response?.data?.errorMessage);
                            toast.error(response?.data?.errorMessage);
                        }
                        else{
                            setDataCollectionListRefresh(Math.random());
                            toast.success("Your collection is copied successfully");
                        }
        });
        setCollectionName('');
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: "10px",width :'500px' } }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DialogTitle className="title">Copy Collection</DialogTitle>
            <DialogContent sx={{ paddingRight: '35px', minHeight: "150px", paddingLeft: '35px' }}>

                {
                    error &&
                    <div className="collection-error-div">
                        <MessageToaster errorMessage={error} />
                    </div>
                }

                <ASTextField
                    className="text-field"
                    type={"text"}
                    fullWidth
                    label="Data Collection Name"
                    variant="outlined"
                    value={collectionName}
                    disabled={false}
                    onChange={onChangeDataCollectionNameHandler}
                />

                <div className="buttons">
                    <Button onClick={handleCancel} className="cancel-button">
                        Cancel
                    </Button>
                    <Button onClick={handleOk} className="accept-button">
                        Ok
                    </Button>
                </div>
            </DialogContent>
            
        </Dialog>
    );
};
export default CopyCollection;