export class SignupModel {

    fullName = "";
    email = "";
    password = "";
    confirmPassword = "";
    isIndividual = true;
    businessName = "";
    userRoleId = "";
    isPasswordValidated = true;
    isEmailValid = true;
    isFullnameValid = true;
    isBusinessNameValid = true;
    errorMessage = null;
    isConfirmPasswordVisible = false;
    isPasswordVisible = false;
}