import React, { useEffect, useState } from "react";
import UserNameMenu from "../../components/header-username-menu/username-menu";
import { useNavigate } from "react-router-dom";
import { AppEnum } from "../../constants/app-enum";
import { getToken, removeItem, removeToken } from "../../shared/local-storage-handler/local-storage-handler";
import { useASContext } from "../context-api/as-context";
import * as loginService from "../../services/login-signup.service";
import jwt from 'jwt-decode'
import ChangePassword from "./changePassword/ChangePassword";
import { removeSessionStorageItem } from "../../shared/session-storage-handler/session-storage-handler";

import { checkPasswordExistsApi } from "../../services/changePassword.service";
import { toast } from "react-toastify";

const menuOptions = [
    { label: "Change Password", link: "/changepassword", icon: null, id: AppEnum.MenuOptionId.ChangePassword, isVisible: true },
    { label: "Logout ", link: "/login", icon: null, id: AppEnum.MenuOptionId.Logout, isVisible: true },
]

const UserNameMenuContainer = (props) => {

    const navigate = useNavigate();

    // menu option state
    const [menus, setMenus] = useState();
    const [isDialogOpen , setIsDialogOpen] = useState(false);

    const asContext = useASContext();

    useEffect(() => {
        setMenus(menuOptions);
    }, [])

    const [passwordExists, setPasswordExists] = useState(false);

    const checkPasswordExists = async () =>{
        try {
            let token = getToken();
            let decodedToken = jwt(token);
            let userName = decodedToken.username;
            const response = await checkPasswordExistsApi(userName);
            if(response?.hasError){
                toast.error(response.errorMessage);
            }
            else{
                //setPasswordExists(false);
                setPasswordExists(response?.data);
                setIsDialogOpen(true);
            }
        } catch (error) {
           
        }
    }

    // it routes to the particular page if any menu is selected
    const onMenuClick = (menu) => {

        if (menu?.id === AppEnum.MenuOptionId.Logout) {
            onLogoutHandler(menu,false);
            return;
        }
        else {
            checkPasswordExists();
        }
    };
    const handleCloseDialog =() =>{
        setIsDialogOpen(false)
    }

    const onLogoutHandler = async (menu , logoutFromAllClients = false) => {
        let token = getToken();
        let decodedToken = jwt(token);
        let userId = decodedToken.sub;

        const clientIds = [AppEnum.LoginApiKey.ClientId];
        if(logoutFromAllClients){
            clientIds.push(AppEnum.LoginApiKey.ExcelClientId);
        }

        try {
            const response = await loginService.logout(userId , clientIds);
            if (response?.data) {
                asContext?.tenants?.setSelectedTenant('');
                removeToken();
                removeItem("refresh_token");
                removeItem("ASTenantId");
                removeSessionStorageItem("ASTenantId");
                navigate(menu.link);
            }
        } catch (error) {

        }
    };

    const [muiDialog, setMuiDialog] = useState(false);

    const handleClick = () =>{
        let menu = {link : "/login"};
        onLogoutHandler(menu,true);
    }

    const closeConfirmationModal =() =>{
        setMuiDialog(false);
        toast.success("Your password has been updated successfully");
    }

    return (
        <div>
            <UserNameMenu
                styles={props.styles}
                userName={props.userName}
                isUserLoggedIn={props.isUserLoggedIn}
                menus={menus}
                onMenuClick={onMenuClick}
            />
            <ChangePassword passwordExists={passwordExists} open ={isDialogOpen} handleCloseDialog = {handleCloseDialog}
            closeConfirmationModal={closeConfirmationModal} handleClick={handleClick} muiDialog={muiDialog} setMuiDialog={setMuiDialog}/>
        </div>
    )

}

export default UserNameMenuContainer;