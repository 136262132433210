import { useEffect } from "react";
import { SaveConnectionView } from "../../../components/connections/save-connection/saveConnectionView";

export function SaveConectionDetails({
    formData,
    setSaveFormData,
}) {

    useEffect(()=>{
        if(formData?.uID?.value.length === 0){
            formData.name.isValid = false;
        }
        setSaveFormData({ ...formData })
    },[])
  
    const handleChange = (event) => {
      formData.name.value = event.target.value;
      if(formData.name.value.length > 0){
        formData.name.isValid = true;
      }else{
        formData.name.isValid = false;
      }
      
      setSaveFormData({ ...formData })
    }
  
    return (
        <SaveConnectionView
            formData={formData}
            handleChange={handleChange}
        />
    );
}
