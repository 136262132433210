import { AsheetLogoExcelSvg } from "../../../constants/common-svgs";
import { ASButton } from "../../../shared/as-button/as-button";
import EastIcon from '@mui/icons-material/East';
import './connect-view.scss';

const ConnectPageView = (props) => {
    const { selectedConnection, testConnection } = props;

    return (
        <>
            <div className="connect-view-div">
                <label>
                    Connect to {selectedConnection?.description}
                </label>
                <div className="logo-div">
                    <span className="asheetlogo">{AsheetLogoExcelSvg}</span>
                    <img className="arrow-icon-div" src={`/assets/right-arrow.svg`} alt="" />
                    <img className="connection-logo" src={`/assets/${selectedConnection?.iconImage}`} alt={selectedConnection?.iconImage} />
                </div>
                <div className="connect-button-div">
                    <ASButton primary={true} onClick={testConnection}> Connect </ASButton>
                </div>
            </div>
        </>
    );
}
export default ConnectPageView;