import { ExcelConnection } from "../../../components/connections/excel-connection/excel-connection";
import { getDataSourceType, getDataSourceTypeByID } from "../../../services/data-source-type.service";
import { addDataSourceType, disconnetCloudConnection, getDataSources, getTemplateDataSourceByID } from "../../../services/data-source.service";
import { useState, useEffect, useRef } from 'react';
import { useASContext } from "../../context-api/as-context";
import { RestServiceConstants } from "../../../constants/rest-service-constant";
import { generateCode, generateRandomString } from "../../../shared/code-challanges/code-challanges";
import * as collectionService from '../../../services/data-collection.service';
import { toast } from "react-toastify";
import { refineFormData } from "../../../services/data-source.utility.service";
import { getTokenFromAuthCode } from "../../../services/authCallback-service";
import { Environment } from "../../../core/environment";

export function AddNewConnectionExcel() {

  const [formData, setSaveFormData] = useState();
  const [sourceType, setSourceType] = useState([]);
  const [dataSource, setDataSource] = useState();
  const asContext = useASContext();
  const [openCloud, setOpenCloud] = useState(true);
  const [openNatural, setOpenNatural] = useState(true);
  const [showWelcome, setShowWelcome] = useState(true);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [sysConnectionTypeId, setSysConnectionTypeId] = useState(null);
  const [code, setCode] = useState(null);
  const [checkAuthCode, setCheckAuthCode] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [usedCloudApplicationsId, setUsedCloudApplicationsId] = useState(new Set());
  const [dataSources, setDataSources] = useState([]);
  const [id,setId] = useState();

  const windowRef = useRef();

  const handleListItem = (label) => {
    if(label == "cloud"){
      setOpenCloud(!openCloud);
    }
    else {
      setOpenNatural(!openNatural)
    }
  };

  const disconnectConnection = () => {
    disconnetCloudConnection(id).then((res) => {
      if(!res?.data?.hasError){
        toast.success("Connection Disconnected Successfully.");
      }
    })
  }

  const handleConnectButtonClick = () => {
    setShowWelcome(false);
  };

  const handleConnectionClick = (item) => {
    getTemplateDataSourceById(item);
    setSysConnectionTypeId(item?.id);
  };

  const getConnectionList = async () => {
    try {
      const response = await getDataSources();
      if (response?.hasError) {
        toast.error(response?.errorMessage)
      }
      else {
        setDataSources(response?.data);
      }
    } catch (error) {
      toast.error(error)
    }
  }

  useEffect(() => {
    if (showWelcome) {
      getConnectionList();
    }
  }, [showWelcome])

  useEffect(() => {
    if (dataSources && dataSources.length > 0) {
      setUsedCloudApplicationsId(
        new Set(dataSources.map(ca => ca.templateConnectionId).filter(tcid => tcid != null)));
    }
  }, [dataSources]);

  useEffect(() => {
    if (asContext.plugin.connectionSelected === false) {
      setSelectedConnection(null);
      setShowWelcome(true);
      setIsConnected(false);
      setIsConnecting(false);
    }
  }, [asContext.plugin.connectionSelected]);

  const setFormData = (res) => {
    let newFormData = [];
    newFormData = {
      name: { value: res.connectionName },
    };
    res.parameters.forEach((ele) => {
      newFormData[ele?.parameterName] = {
        value: ele?.parameterValue,
      };
    });
    setSaveFormData(newFormData);
  };

  useEffect(() => {
    getDataSourceType().then((res) => {
      if (res.data) {
        const _sources = res?.data?.filter(datasource => datasource.isActive === true)
          .map((datasource, index) => ({
            ...datasource,
            key: Date.now() + index,
          }));
        setSourceType(_sources);
      }
    });
  }, []);

  const getDataSourceById = (id) => {
    getDataSourceTypeByID(id).then((res) => {
      if (res?.data) {
        setDataSource(res.data);
      }
    });
  }

  const getTemplateDataSourceById = (card) => {
    let id = card?.templateConnectionId;
    setSelectedConnection(card);
    getTemplateDataSourceByID(id).then((res) => {
      if (res?.data) {
        let tempStepId = card.id;
        card.id = res?.data.connectionTypeId;
        res.data.connectionTypeId = tempStepId;
        setFormData(res?.data);
        getDataSourceById(card?.id);
      }
    });
  }

  const bearerParameters = dataSource?.parameters?.filter((_ele) =>
    _ele.parameterType.includes(RestServiceConstants.BEARER)
  );

  useEffect(() => {
    const formObjectC = {};
    formObjectC.name = {
      value: (formData && formData?.name?.value) || "",
      isValid: (formData && !!formData?.name?.value) || true,
    };

    if (formData?.uID) {
      formObjectC.uID = { value: formData.uID.value || "", isValid: true };
    }

    const bearer = bearerParameters?.find(ele =>
      ele.parameterType === RestServiceConstants.BEARER_TOKEN.PARAMETERS) || {};

    dataSource?.parameters?.forEach((input) => {
      formObjectC[input.parameterType] = {
        datasourceParameterTypeId: input.id,
        value: (formData && formData[input.parameterType])?.value || (bearer.parameterType === input.parameterType ? JSON.stringify([]) : ""),
        isValid:
          !!(formData && formData[input.parameterType])?.value ||
          !input.isRequired,
        errorMsg: (formData && formData[input.parameterType])?.errorMsg,
      };
    });
    setSaveFormData(formObjectC);
    if (selectedConnection) {      
      asContext.plugin.setConnectionSelected(true)
    }
  }, [dataSource]);

  const testConnection = async () => {

    var grantType = (
      !!formData &&
      !!formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE] &&
      formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE]
    ).value;

    var refresh_token = (
      !!formData &&
      !!formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN] &&
      formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN]
    ).value;

    var uId = (
      !!formData &&
      !!formData["uID"] &&
      formData["uID"]
    ).value;

    var supportRefreshToken = (
      !!formData &&
      !!formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN] &&
      formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN]
    ).value

    var is_supportRefreshToken = supportRefreshToken == "" ? false : supportRefreshToken == "false" ? false : true;

    var isNullOrEmptyUId = (uId == null || uId == '') ? true : false;
    var isNullOrEmptyRefreshToken = (refresh_token == null || refresh_token == '') ? true : false;
    var isGrantTypeForOauth = (grantType == "authorization_code" || grantType == "authorization_code_with_pkce") ? true : false;
    var redirect_url = Environment.getUrl() + "/auth-callback";

    if ((isGrantTypeForOauth && code == null && isNullOrEmptyRefreshToken && is_supportRefreshToken == false) || (isGrantTypeForOauth && code == null && isNullOrEmptyRefreshToken) || (isNullOrEmptyUId && code == null && isGrantTypeForOauth) || (is_supportRefreshToken == false && code == null && isGrantTypeForOauth)) {
      var data = null;
      let randomString = generateRandomString(8);
      if (grantType == "authorization_code") {
        data = {
          client_id: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID] && formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID]).value,
          redirect_uri: redirect_url,
          auth_url: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL] && formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL]).value,
          scope: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.SCOPE] && formData[RestServiceConstants.OAUTH2_INPUTS.SCOPE]).value,
          state: randomString
        };
      }
      else if (grantType == "authorization_code_with_pkce") {
        var pkceData = await generateCode((
          !!formData &&
          !!formData[RestServiceConstants.OAUTH2_INPUTS.CODE_VERIFIER] &&
          formData[RestServiceConstants.OAUTH2_INPUTS.CODE_VERIFIER]
        ).value);

        formData[RestServiceConstants.OAUTH2_INPUTS.CODE_VERIFIER].value = pkceData.verifier;

        data = {
          responseType: "code",
          auth_url: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL] && formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL]).value,
          client_id: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID] && formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID]).value,
          scope: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.SCOPE] && formData[RestServiceConstants.OAUTH2_INPUTS.SCOPE]).value,
          redirect_uri: redirect_url,
          state: randomString
        };
        if (formData[RestServiceConstants.OAUTH2_INPUTS.CODE_CHALLENGE_METHOD].value != "S256") {
          data["code_challenge"] = formData[RestServiceConstants.OAUTH2_INPUTS.CODE_VERIFIER].value
        }
        else {
          data["code_challenge"] = pkceData.base64EncodedHash
          data["code_challenge_method"] = formData[RestServiceConstants.OAUTH2_INPUTS.CODE_CHALLENGE_METHOD].value;
        }
      }

      if (formData[RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.AUTH_REQUEST].value != null || formData[RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.AUTH_REQUEST].value != '[]' || formData[RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.AUTH_REQUEST].value != '') {
        var variableList = await collectionService.getVariableList();
        let varData = null;
        if (variableList.data && variableList.data.length > 0) {
          varData = variableList.data;
        }
        let authRequestData = [];

        if (formData[RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.AUTH_REQUEST].value) {
          authRequestData = JSON.parse(formData[RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.AUTH_REQUEST].value);
        }

        authRequestData.forEach(obj => {
          if (varData != null && varData.length > 0) {
            var selectedVar = variableList.data.filter(i => i.uId == obj.value);
            if (selectedVar.length > 0) {
              data[obj.key] = selectedVar[0].primitiveTypeValue;
            }
            else {
              data[obj.key] = obj.value;
            }
          }
          else {
            data[obj.key] = obj.value;
          }
        });
      }
      formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.CALLBACK_URL] = {
        isValid: true,
        datasourceParameterTypeId: 1009,
        value: redirect_url,
      };
      formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE] = {
        isValid: true,
        datasourceParameterTypeId: 2016,
        value: is_supportRefreshToken ? refresh_token ? "" : code : code,
      };
      formData[RestServiceConstants.OAUTH2_INPUTS.STATE] = {
        datasourceParameterTypeId: 1016,
        isValid: true,
        value: data.state
      };
      const queryString = Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
      setCheckAuthCode(true);
      setIsConnecting(true);
      const popupWindow = window.open(
        `/begin-oauth?${queryString}`,
        "_blank",
        "width=600,height=400"
      );
      windowRef.current = popupWindow;
    }
  };

  const onCancel = () => {
    setIsConnecting(false);
    setCheckAuthCode(false);
    windowRef.current.close();
  }

  const checkIfWindowOpen = () => {
    if (windowRef?.current && !windowRef?.current?.closed) {
      console.log('Another window is open.');
    } else {
      console.log('No other window is open.');
      setCheckAuthCode(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (checkAuthCode) {
        authCode();
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [checkAuthCode])

  const authCode = async () => {
    try {
      const response = await getTokenFromAuthCode(formData[RestServiceConstants.OAUTH2_INPUTS?.STATE].value, refineFormData(formData, selectedConnection));
      if (response?.data?.hasError) {
        checkIfWindowOpen();
      }
      else {
        if (response.data && !response?.data?.hasError && response?.data?.error == null) {
          if ((
            !!formData &&
            !!formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE] &&
            formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE]
          ).value == "authorization_code" || (
            !!formData &&
            !!formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE] &&
            formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE]
          ).value == "authorization_code_with_pkce") {
            formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN] = {
              isValid: true,
              datasourceParameterTypeId: 2017,
              value: response?.data?.data?.access_token,
            };
            if (response?.data?.data?.expires_in) {
              formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_EXPIRESAT] = {
                isValid: true,
                datasourceParameterTypeId: 2020,
                value: response?.data?.data?.expires_in,
              };
            }
            if (response?.data?.data?.refresh_token) {
              formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN] = {
                isValid: true,
                datasourceParameterTypeId: 2025,
                value: response?.data?.data?.refresh_token,
              };
            }
          }
        } 
        else {
          if(response?.hasError || response?.data?.error || response?.data?.data?.error){
            toast.error(
              response?.errorMessage
                ? response?.errorMessage
                : response?.data?.data?.error
                  ? response?.data?.data?.error
                  : response?.data?.error
                    ? response?.data?.error
                    : "Couldn't establish a secure connection!"
              );
          }
          else {
            toast.error(`Couldn't establish a secure connection!`);
          }
        }
        if (response?.data?.data?.access_token) {
          handleSave();
          setIsConnected(true);
          toast.success("The connection was established successfully.");
        }
        setCheckAuthCode(false);
        windowRef.current.close();
      }
    } catch (error) {

    }
  }

  const handleSave = () => {
    const data = refineFormData(formData, selectedConnection);
    if (data?.name == null || data?.name == "") {
      return
    }

    data.sysConnectionTypeId = sysConnectionTypeId;
    data.baseConnectionTypeId = selectedConnection.id;
    data.templateConnectionId = selectedConnection?.templateConnectionId; 
    addDataSourceType(data)
      .then((_res) => {
        if (_res.data) {
          setId(_res?.data?.entityUId);
          toast.success("Connection added successfully.")
        }
        else if (_res.hasError) {
          toast.error(_res.errorMessage);
        }
      })
      .catch((err) => {
        toast.error(err.validationErrors);
      });
  };

  return (
    <ExcelConnection
      testConnection={testConnection}
      onCancel={onCancel}
      isConnected={isConnected}
      isConnecting={isConnecting}
      sourceType={sourceType}
      handleListItem={handleListItem}
      handleConnectButtonClick={handleConnectButtonClick}
      handleConnectionClick={handleConnectionClick}
      showWelcome={showWelcome}
      selectedConnection={selectedConnection}
      openCloud={openCloud}
      openNatural={openNatural}
      usedCloudApplicationsId={usedCloudApplicationsId}
      disconnectConnection={disconnectConnection}
    />)
}