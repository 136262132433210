import { useASContext } from "../../controllers/context-api/as-context";
import { useLocation } from "react-router-dom";

//Add the routes and the particalur name to show on app title.
const getPageName = (pathName) => {

    if (pathName.startsWith("/connections")) {
        return "Connections";
    } else if (pathName.startsWith("/data-collections")) {
        return "Data Collections";
    } else if (pathName.startsWith("/users")) {
        return "Users";
    } else if (pathName.startsWith("/variables")) {
        return "Variables";
    } else {
        return "";
    }
}

const useAppTitle = () => {

    const location = useLocation();
    const asContext = useASContext();

    let pageName = getPageName(location.pathname);

    const getTitle = () => {
        if (!asContext?.tenants?.selectedTenant || pageName === "") { //condition for, if the user is not logged in.
            document.title = "αsheet"; //default title.
        } else {
            document.title = `αsheet - ${pageName} in ${asContext?.tenants?.selectedTenant?.Name}`;
        }
    }

    return [getTitle];
};

export default useAppTitle;
