import { Paper, Typography } from "@mui/material";
import { ConnectingAnimationElement } from "../../../constants/common-svgs";
import { ASButton } from "../../../shared/as-button/as-button";
import MessageToaster from "../../../shared/message-toaster/message-toaster";
import "./test-connection.scss";
import ProxySecretController from "../../../controllers/connections/connection-details/rest-api/proxy-secret-controller";
import { RestServiceConstants } from "../../../constants/rest-service-constant";

export function TestConnection({
    dataSourceName,
    testConnection, errorMessage,
    serverName, isTestingConnection,selectedStep,isTally,openSecretDialog, isDialogOpen, setIsDialogOpen, formData
}) {
    return (
        <Paper
            className={isTally ? 'test-connection-paper-tally': 'test-connection-paper'}
        >
            {!isTally ?
            <>
                <div style={{display:"flex"}} >
                    <Typography>{`Connect to ${dataSourceName} -`}{'\u00A0'}</Typography>
                    <Typography style={{ fontWeight: 'bold'}}>{`${serverName()}`}</Typography>
                </div>

                {
                    isTestingConnection &&
                    <div className="connecting-spinner-div">
                        {ConnectingAnimationElement}

                        <Typography className="connecting-dots">Connecting</Typography>
                    </div>
                }
                {
                    errorMessage &&
                    <MessageToaster errorMessage={errorMessage} />
                }

                <div className={isTestingConnection ? 'test-connection-div' : 'test-connection-btn'}>

                    <ASButton onClick={testConnection} buttonType="button-basic" disabled={isTestingConnection}>
                        Test Connection
                    </ASButton>

                </div>
            </>
            :
            <div>
                {
                    isTestingConnection &&
                    <div className="connecting-spinner-div">
                        {ConnectingAnimationElement}

                        <Typography className="connecting-dots">Connecting</Typography>
                    </div>
                }
                {
                    <div>
                        <div className="tally-connector-test-content">
                        <div className="tally-connector-test-message-part">
                            <div style={{display:"flex"}} >
                                <Typography>{`Connect to ${dataSourceName} -`}{'\u00A0'}</Typography>
                                <Typography style={{ fontWeight: 'bold'}}>{`${serverName()}`}</Typography>
                            </div>  
                            {
                                (errorMessage == "No connection found yet") ? 
                                    <div className="tally-test-result-div">
                                    <div>Not able to connect to your Proxy Connector</div>
                                    <div><span className="tally-link">Install Proxy Connector</span>  on the machine where Tally is running.</div>
                                    <div>You can <span className="tally-link">check this video</span> for configuration instructions</div>
                                </div> :
                                errorMessage && <MessageToaster errorMessage={errorMessage} />
                            }   
                            
                        </div>
                            
                        <div className="tally-test-connection-btn-div">
                            <ASButton onClick={testConnection} primary={true} sx={{ mr: 2, marginRight: "0px" }} variant="contained" disabled={isTestingConnection}>
                                Test Connection
                            </ASButton>

                                    <div className="test-tally-link-div">
                                        <Typography className="tally-link"
                                            sx={{cursor : "pointer"}}
                                            onClick={()=>{openSecretDialog()}}>
                                            {"Regenrate Proxy Secret"}
                                        </Typography> 

                                    <ProxySecretController isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen}/>

                                </div>
                        </div>
                        </div>
                    </div>
                }
            </div>
            }
        </Paper>
    );
}
