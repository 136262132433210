export const ASDataCollections = ({ flag, className, size }) => {
    return (
        <>
            <svg
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                width={size ? size : "28"}
                height={size ? size : "25.455"}
                viewBox="0 0 28 25.455"
            >
                <path
                    id="table-multiple"
                    d="M8.636,2H26.455A2.545,2.545,0,0,1,29,4.545V19.818a2.537,2.537,0,0,1-2.545,2.545H8.636a2.545,2.545,0,0,1-2.545-2.545V4.545A2.553,2.553,0,0,1,8.636,2m0,5.091v5.091h7.636V7.091H8.636m10.182,0v5.091h7.636V7.091H18.818M8.636,14.727v5.091h7.636V14.727H8.636m10.182,0v5.091h7.636V14.727H18.818M3.545,24.909V7.091H1V24.909a2.537,2.537,0,0,0,2.545,2.545H23.909V24.909Z"
                    transform="translate(-1 -2)"
                    fill={flag ? "#FFF" : "#16365f"}
                />
            </svg>
        </>
    );
};
