import React, { Fragment } from "react";
import { ASInput } from "../../../../shared/as-inputs/as-input";

const BasicAuth = (props) => {

    const { formData, usernameParameterType, passwordParameterType} = props;

    return (
        <Fragment>
            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[usernameParameterType.parameterType] &&
                        formData[usernameParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[usernameParameterType.parameterType] &&
                        formData[usernameParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...usernameParameterType,
                    displayName: usernameParameterType.displayName || "Username *",
                }}
            />

            <ASInput
                value={
                    (
                        !!formData &&
                        !!formData[passwordParameterType.parameterType] &&
                        formData[passwordParameterType.parameterType]
                    ).value
                }
                errorMsg={
                    (
                        !!formData &&
                        !!formData[passwordParameterType.parameterType] &&
                        formData[passwordParameterType.parameterType]
                    ).errorMsg
                }
                fieldParameter={{
                    ...passwordParameterType,
                    displayName: passwordParameterType.displayName || "Password *",
                }}
            />
        </Fragment>
    )
}

export default BasicAuth;
