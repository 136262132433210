import { useSearchParams , useNavigate } from 'react-router-dom';
import Ordercomplete from '../../components/order-sucess/Order-complete';
import { useEffect, useState } from 'react';
import { paymentStatus ,planType} from "../../controllers/checkout-process/checkout/Checkout.state";

const OrderCompleteController = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    let params = searchParams.get(paymentStatus);
    let plan = searchParams.get(planType);
    const [orderStatus, setOrderStatus] = useState(null);
    const [freePlan, setFreePlan] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (params == "True" || params == "true") {
            setOrderStatus(true);
        }
        else if(params == "False" || params == "false") {
            setOrderStatus(false);
        }
        if(plan == "free"){
            setFreePlan("free")
        }
    }, []);

    return (
        <>
            {freePlan != "free" ?
                <div>
                    <Ordercomplete
                    orderStatus = {orderStatus}
                />
                </div> :  navigate("/")
            }
        </>
    )
}

export default OrderCompleteController;