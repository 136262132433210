import { timeCondition } from "../../../../components/data-collections/column-selection-popup/filterPopup/date-filter/date-filter";

export function getDateRange(value, condition) {
    const today = new Date();
    let startDate, endDate, duration, daysCounts, direction;

    if (value.includes(" ")) {
        const splitString = value.split(" ");
        if (splitString.length === 3) {
            duration = splitString[0];
            daysCounts = splitString[1];
            direction = splitString[2];
        }
    }

    if (duration && daysCounts && direction) {
        let daysCount = Number(daysCounts);
        switch (duration) {
            case "DAYS":
                if (direction === "NEXT") {
                    startDate = new Date(today);
                    endDate = new Date(today);
                    endDate.setDate(today.getDate() + daysCount);
                } else if (direction === "LAST") {
                    endDate = new Date(today);
                    startDate = new Date(today);
                    startDate.setDate(today.getDate() - daysCount);
                }
                break;
            case "WEEKS":
                if (direction === "NEXT") {
                    startDate = new Date(today);
                    endDate = new Date(today);
                    endDate.setDate(today.getDate() + (daysCount * 7));
                } else if (direction === "LAST") {
                    endDate = new Date(today);
                    startDate = new Date(today);
                    startDate.setDate(today.getDate() - (daysCount * 7));
                }
                break;
            case "MONTHS":
                if (direction === "NEXT") {
                    startDate = new Date(today);
                    endDate = new Date(today);
                    endDate.setMonth(today.getMonth() + daysCount);
                } else if (direction === "LAST") {
                    endDate = new Date(today);
                    startDate = new Date(today);
                    startDate.setMonth(today.getMonth() - daysCount);
                }
                break;
            case "YEARS":
                if (direction === "NEXT") {
                    startDate = new Date(today);
                    endDate = new Date(today);
                    endDate.setFullYear(today.getFullYear() + daysCount);
                } else if (direction === "LAST") {
                    endDate = new Date(today);
                    startDate = new Date(today);
                    startDate.setFullYear(today.getFullYear() - daysCount);
                }
                break;
            default:
                startDate = null;
                endDate = null;
        }
    }
    else {
        switch (value) {
            case "TODAY":
                startDate = new Date(today);
                endDate = new Date(today);
                break;

            case "TOMORROW":
                startDate = new Date(today);
                startDate.setDate(today.getDate() + 1);
                endDate = new Date(startDate);
                break;

            case "YESTERDAY":
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 1);
                endDate = new Date(startDate);
                break;

            case "CURRENT_WEEK":
                startDate = new Date(today);
                const dayOfWeek = today.getDay(); 
                const diffToMonday = dayOfWeek === 0 ? 5 :  dayOfWeek - 2;
                startDate.setDate(today.getDate() - diffToMonday);
                startDate.setHours(0, 0, 0, 0);

                endDate = new Date(startDate);
                endDate.setDate(startDate.getDate() + 5);
                endDate.setHours(23, 59, 59, 999);
                break;

            case "CURRENT_MONTH":
                startDate = new Date(today.getFullYear(), today.getMonth(), 2);
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                endDate.setHours(23, 59, 59, 999);
                break;

            case "CURRENT_YEAR":
                startDate = new Date(today.getFullYear(), 0, 2);
                endDate = new Date(today.getFullYear(), 11, 31);
                endDate.setHours(23, 59, 59, 999);
                break;

            default:
                startDate = null;
                endDate = null;
        }
    }

    if (startDate && endDate) {
        let updatedDateTime = getFiltrableUTCTime(startDate, endDate, condition, true);
        if (updatedDateTime) {
            startDate = updatedDateTime?.date;
            endDate = updatedDateTime?.endDate

            // startDate.setUTCHours(23, 59, 59, 999);
            // endDate.setUTCHours(0, 0, 0, 0);
        }
        else {
            // startDate.setUTCHours(0, 0, 0, 0);
            // endDate.setUTCHours(23, 59, 59, 999);
        }
    }
    return {
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null
    };
}

export const getFiltrableUTCTime = (date, endDate, condition, isLabeledDate) => {
    let conditionForTime = condition ? condition : null;

    if (typeof date === 'string' || !(date instanceof Date)) {
        date = new Date(date);
    }
    if (typeof endDate === 'string' || !(endDate instanceof Date)) {
        endDate = new Date(endDate);
    }

    if (conditionForTime) {
        if (timeCondition?.startDay?.includes(conditionForTime)) {
            if (date && endDate && isLabeledDate) {
                const tempDate = new Date(date.getTime());
                const tempEndDate = new Date(endDate.getTime());

                tempDate.setUTCHours(0, 0, 0, 0);
                tempEndDate.setUTCHours(23, 59, 59, 999);

                date = tempDate;
                endDate = tempEndDate;

                return { date, endDate };
            }
            const tempDate = new Date(date.getTime());
            tempDate.setUTCHours(0, 0, 0, 0);
            date = tempDate;
            return { date };
        }
        else if (timeCondition?.endDay?.includes(conditionForTime)) {
            if (date && endDate && isLabeledDate) {
                const tempDate = new Date(date.getTime());
                const tempEndDate = new Date(endDate.getTime());

                tempDate.setUTCHours(0, 0, 0, 0);
                tempEndDate.setUTCHours(23, 59, 59, 999);
            
                date = tempEndDate;
                endDate = tempDate;

                return { date, endDate };
            }
            const tempDate = new Date(date.getTime());
            tempDate.setUTCHours(23, 59, 59, 999);
            date = tempDate;
            return { date };            
        }
    }
}
