import React from 'react'
import "./date-filter.scss"
import { FormControlLabel, InputLabel, MenuItem, TextField } from '@mui/material';
import { ASTextField } from '../../../../../shared/as-text-fields';

export default function DateFilter(props) {

    const { filterObject, handleOnChangeDateInput, getDateByDateFilters, dateFilterObject } = props;
    return (
        <div className='date-add-condition-value-div'>
            <div>
                <InputLabel>Value</InputLabel>
                <ASTextField
                    className="fields-selection"
                    select={true}
                    onChange={(e) => getDateByDateFilters(e, "subDateLabel")}
                    value={dateFilterObject?.subDateLabel}
                >
                    {(SubDateFields)?.filter(item => {
                        if (filterObject?.condition === "BETWEEN") {
                            return item.value !== "EXACT_DATE";
                        } else {
                            return item.value !== "EXACT_DATES";
                        }
                    })?.map((item) => (
                        <MenuItem
                            key={item.value}
                            value={item.value}
                            name={item.value}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </ASTextField>
            </div>

            {(dateFilterObject?.subDateLabel == "LAST" || dateFilterObject?.subDateLabel == "NEXT") &&
                <div className='date-filter-fields-group'>
                    <div className="date-condition-div">
                        <div className="date-add-next-condition-value-div">
                            <ASTextField type='number' autoComplete="off" min="0" value={dateFilterObject?.daysCount} 
                                        onChange={(e) => getDateByDateFilters(e, "daysCount")} 
                                        inputProps={{
                                            min: 0
                                        }}
                                        sx={{ width: "100%" }} />
                        </div>
                        <div className="date-add-next-condition-value-div">
                            <ASTextField
                                className="fields-selection"
                                select={true}
                                onChange={(e) => getDateByDateFilters(e, "dateDuration")}
                                value={dateFilterObject?.dateDuration}
                            >
                                {DurationFields?.map((item) => (
                                    <MenuItem
                                        key={item.name}
                                        value={item.value}
                                        name={item.name}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </ASTextField>
                        </div>
                    </div>
                </div>}

            {((dateFilterObject?.subDateLabel === "EXACT_DATE" && filterObject?.condition != "BETWEEN")|| (dateFilterObject?.subDateLabel === "EXACT_DATES" && filterObject?.condition == "BETWEEN")) &&
                <div className='date-filter-fields-group'>
                    <div>
                        <div className={filterObject?.condition === "BETWEEN" ? "exact-date-add-condition-value" : ""}>
                            {dateFilterObject?.subDateLabel == "EXACT_DATES" && <InputLabel>From</InputLabel>}
                            <ASTextField
                                type="date"
                                value={dateFilterObject?.exactDate}
                                // sx={{ ...(dateFilterObject?.subDateLabel == "EXACT_DATES" && { width: "72%" }) }}
                                onChange={(e) => handleOnChangeDateInput(e, "value")}
                            />
                        </div>
                        {(filterObject?.condition == "BETWEEN" && dateFilterObject?.subDateLabel == "EXACT_DATES") &&
                            <>
                                <div className="exact-date-add-condition-value">
                                    <InputLabel sx={{ paddingRight: "18px", paddingTop: "8px" }}>To</InputLabel>
                                    <ASTextField
                                        type="date"
                                        sx={{paddingTop: "8px" }}
                                        value={dateFilterObject?.exactFromDate}
                                        onChange={(e) => handleOnChangeDateInput(e, "highValue")}
                                    />
                                </div>
                            </>}
                    </div>
                </div>}

        </div>
    );
}


export const SubDateFields = [
    {
        name: "Today",
        value: "TODAY",
    },
    {
        name: "Tomorrow",
        value: "TOMORROW",
    },
    {
        name: "Yesterday",
        value: "YESTERDAY",
    },
    {
        name: "Current week",
        value: "CURRENT_WEEK",
    },
    {
        name: "Current month",
        value: "CURRENT_MONTH",
    },
    {
        name: "Current year",
        value: "CURRENT_YEAR",
    },
    {
        name: "Last",
        value: "LAST",
    },
    {
        name: "Next",
        value: "NEXT",
    },
    {
        name: "Exact date",
        value: "EXACT_DATE",
    },
    {
        name: "Exact dates",
        value: "EXACT_DATES",
    }
];


export const DurationFields = [
    {
        name: "Days",
        value: "DAYS",
    },
    {
        name: "Weeks",
        value: "WEEKS",
    },
    {
        name: "Months",
        value: "MONTHS",
    },
    {
        name: "Years",
        value: "YEARS",
    },
];

export const DateDataTypes = ["datetime", "DateTime", "date", "Date", "dateTimeOffset", "DateTimeOffset"];

export const timeCondition = {
    startDay: ['Is Before', 'Is on or after', 'Is Within'],
    endDay: ['Is After', 'Is on or before']
  };