import React, { Fragment } from "react";
import { ASInput } from "../../../../shared/as-inputs/as-input";
import { FormControlLabel, Paper, Typography } from "@mui/material";
import CheckBox from '@mui/material/Checkbox';
import "./rest-api.scss";
import ProxySecretController from "../../../../controllers/connections/connection-details/rest-api/proxy-secret-controller";
import styles from  "../../../../../src/styles/_exports.module.scss"

const muiCheckboxStyle = {
    color: styles.secondaryColor,
    '&.Mui-checked': {
        color: styles.checkboxColor,
    },
    '&.Mui-disabled': {
        color: 'grey',
    },
    '&.MuiFormControlLabel-label': {
        fontSize: 16,
    },
    '&.MuiSvgIcon-root':{
        fontSize : 18
    }
}

const TallyConnector = (props) => {

    const { formData, tallyConnectorUrl, isProxyConnectorRequired, handleRequireProxyConnector, openSecretDialog, isDialogOpen, setIsDialogOpen} = props;

    return (
        <Paper
            sx={{
                minHeight: "100px",
                width: "900px",
                padding: "20px",
            }}
        >
        <Fragment>
            <div className="w-50">
                <ASInput
                            value={         (
                                !!formData &&
                                !!formData[tallyConnectorUrl.parameterType] &&
                                formData[tallyConnectorUrl.parameterType]
                            ).value}
                            fieldParameter={{
                                ...tallyConnectorUrl,
                                displayName: tallyConnectorUrl.displayName || "Complete Server Address or Put Just Port No.",
                            }}
                            errorMsg={
                                (
                                    !!formData &&
                                    !!formData[tallyConnectorUrl.parameterType] &&
                                    formData[tallyConnectorUrl.parameterType]
                                ).errorMsg
                            }
                        />
            </div>

            <div className="tally-link-div">
                <Typography className="tally-link"
                    sx={{cursor : "pointer"}}
                    onClick={()=>{openSecretDialog()}}>
                    {"Genrate Proxy Secret"}
                </Typography> 

                <ProxySecretController isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen}/>

                <div className='line-btw-tally-link'></div>

                <Typography className="tally-link"
                    sx={{cursor : "pointer"}}
                    onClick={()=>{}}>
                    {"Download Proxy Connector"}
                </Typography>
                
                <div className='line-btw-tally-link'></div>

                <Typography className="tally-link"
                    sx={{cursor : "pointer"}}
                    onClick={()=>{}}>
                    {"Vieo : How to Configure"}
                </Typography> 
            </div>
        </Fragment>
        </Paper>
    )
}

export default TallyConnector;