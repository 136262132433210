export const conditionarr = [
    {
    name : "Contains",
    value : "CONTAINS_TOKEN"
    },
    {
      name : "Not Contains",
      value : "NOT_CONTAINS_TOKEN"
    }
  ];

export const sortArr = [
    {
      name : "Ascending",
      value : "ASCENDING"
    },
    {
      name : "Descending",
      value : "DESCENDING"
    }
  ]