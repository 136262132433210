import { useContext, useEffect, useState } from "react";
import {
    BearerTokenView,
    ParametersFormView,
    ParametersView,
} from "../../../../components/connections/connection-details/rest-api/bearer-token-view";
import { RestServiceConstants } from "../../../../constants/rest-service-constant";
import { FormContext } from "../../../../contexts/form-context";
import { formValidationCheck, isNullOrEmpty } from "../../../../services/data-source-validation";
import * as collectionService from '../../../../services/data-collection.service';

export const BearerToken = (props) => {
    const { formData, bearerParameters, isFormInvalid,jwtTypeParameters,loginUrlParameters } = props;

    useEffect(() =>{
        getHeaderPrifix();
    },[])

    useEffect(() => {
        checkFormIsValid();
    },[formData])

    const sendAs = bearerParameters.find(
        (ele) => ele.parameterType === RestServiceConstants.BEARER_TOKEN.SEND_AS
    );
    const jsonBody = {
        ...bearerParameters.find(
            (ele) => ele.parameterType === RestServiceConstants.BEARER_TOKEN.JSON_BODY
        ), displayName: "JSON Body", isRequired: true
    };
    const parameters = bearerParameters.find(
        (ele) => ele.parameterType === RestServiceConstants.BEARER_TOKEN.PARAMETERS
    );

    const headerPrefixParameter = bearerParameters.find(
        (ele) => ele.parameterType === RestServiceConstants.BEARER_TOKEN.HEADER_PREFIX
    );

    const { handleChangeInput } = useContext(FormContext);

    const getHeaderPrifix = () => {
        formData[headerPrefixParameter.parameterType] = {
            value: formData[headerPrefixParameter.parameterType].value == null || formData[headerPrefixParameter.parameterType].value == "" ? "Bearer" : formData[headerPrefixParameter.parameterType].value,
            datasourceParameterTypeId: formData[headerPrefixParameter.parameterType].datasourceParameterTypeId,
            errorMsg: formData[headerPrefixParameter.parameterType].errorMsg,
            isValid: formData[headerPrefixParameter.parameterType].isValid
        };
    };

    const onRadioChange = (value) => {
        const inputEvent = {
            value: value,
            isValid: true,
            datasourceParameterTypeId: sendAs.id,
            errorMsg: "",
        };

        if (formData[jsonBody.parameterType]) {
            const json = formData[jsonBody.parameterType];

            if (value !== RestServiceConstants.BEARER_TOKEN.SEND_AS_OPTION[0].value) {
                json.value = "";
                json.isValid = true;
            } else {
                json.isValid = true;
            }
        }
        formValidationCheck(formData, isFormInvalid);
        handleChangeInput(sendAs, inputEvent);
    };


    const checkFormIsValid = () => {
        const sendAsValue = (!!formData && !!formData[sendAs.parameterType] && formData[sendAs.parameterType]).value
        const headerPrefix = (!!formData && !!formData[headerPrefixParameter.parameterType] && formData[headerPrefixParameter.parameterType]).value
        const loginUrl = (!!formData && !!formData[loginUrlParameters.parameterType] && formData[loginUrlParameters.parameterType]).value
        const jwtPath = (!!formData && !!formData[jwtTypeParameters.parameterType] && formData[jwtTypeParameters.parameterType]).value

        if(isNullOrEmpty(sendAsValue) || isNullOrEmpty(headerPrefix) || isNullOrEmpty(loginUrl) || isNullOrEmpty(jwtPath)){
            isFormInvalid(true);
        }
        else{
            isFormInvalid(false)
        }

    };

    return (
        <BearerTokenView
            formData={formData}
            onRadioChange={onRadioChange}
            sendAs={sendAs}
            jsonBody={jsonBody}
            parameters={parameters}
            isFormInvalid={isFormInvalid}
            jwtTypeParameters={jwtTypeParameters}
            loginUrlParameters={loginUrlParameters}
            headerPrefixParameter={headerPrefixParameter}
        />
    );
};

export const Parameters = (props) => {
    const { formData, parameters, isFormInvalid, isAuthRequest = false } = props;
    const _data = JSON.parse(formData[parameters.parameterType].value);
    const [data, setData] = useState(_data);

    const onAddRow = () => {
        isFormInvalid(true);
        setData([
            ...data,
            { key: "", value: "", type: "", valueType: "", edit: true },
        ]);
    }

    const update = (_d, index) => {
        formValidationCheck(formData, isFormInvalid);
        data[index] = { ..._d, edit: false };
        setData([...data]);
        formData[parameters.parameterType].value = JSON.stringify(data);
    };

    const remove = (index) => {
        data.splice(index, 1)
        setData([...data])
        formData[parameters.parameterType].value = JSON.stringify(data);
    };

    const edit = (index) => ((data[index].edit = true), setData([...data]));

    const isAddDisable = data.some((ele) => ele.edit);

    return (
        <ParametersView
            formData={formData}
            parameters={parameters}
            onAddRow={onAddRow}
            update={update}
            remove={remove}
            edit={edit}
            data={data}
            isAddDisable={isAddDisable}
            title={props.title}
            isAuthRequest={isAuthRequest}
        />
    );
};

export const ParametersForm = (props) => {
    const { data, update, index, remove, isAuthRequest } = props;
    let [parametersFormData, setParametersFormData] = useState({
        key: "",
        value: "",
        type: "",
        valueType: "",
        edit: true,
        displayName: ""
    });

    const [variableList,setVariableList] = useState([]);

    useEffect(() => {
        setParametersFormData({ ...data });
    }, [data]);

    useEffect(() => {
        getVariableList();
    },[])

    const getVariableList = () => {

        collectionService.getVariableList()
            .then((response) => {
                if (response?.data)
                    setVariableList(response.data);
            })
    }

    const newRowFlag =
        !parametersFormData.key &&
        !parametersFormData.value &&
        !parametersFormData.type &&
        !parametersFormData.valueType;
    const cancel = () => (newRowFlag ? remove(index) : update(data, index));

    const ok = () => update(parametersFormData, index);

    const isSaveEnable =
        !parametersFormData.key ||
        !parametersFormData.value ||
        !parametersFormData.type ||
        !parametersFormData.valueType;

    const onChange = (key, value) => {
        parametersFormData[key] = value;
        setParametersFormData({ ...parametersFormData });
    };

    const onSelectVariableHandler = (variable) => {

        if (variable) {
            onChange("value", variable.uId)
            onChange("displayName", variable.label)
        }
    }

    return (
        <ParametersFormView
            data={parametersFormData}
            ok={ok}
            cancel={cancel}
            handleChange={onChange}
            isSaveEnable={isSaveEnable}
            variableList={variableList}
            onSelectVariableHandler={onSelectVariableHandler}
            isAuthRequest={isAuthRequest}
        />
    );
};
