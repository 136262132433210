import React, { Fragment, useEffect } from "react";
import BasicAuth from "../../../../components/connections/connection-details/rest-api/basic-auth";
import { RestServiceConstants } from "../../../../constants/rest-service-constant";
import { isNullOrEmpty } from "../../../../services/data-source-validation";

const BasicAuthController = (props) => {

    const { basicAuthParameters, formData, isFormInvalid } = props;

    useEffect(() => {
        checkFormIsValid();
    },[formData])

    const usernameParameterType = basicAuthParameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.BASIC_AUTH_INPUTS.USERNAME)
    );

    const passwordParameterType = basicAuthParameters.find((ele) =>
        ele.parameterType.includes(RestServiceConstants.BASIC_AUTH_INPUTS.PASSWORD)
    );

    const checkFormIsValid = () => {
        const username = (!!formData && !!formData[usernameParameterType.parameterType] && formData[usernameParameterType.parameterType]).value
        const password = (!!formData && !!formData[passwordParameterType.parameterType] && formData[passwordParameterType.parameterType]).value

        if(isNullOrEmpty(username) || isNullOrEmpty(password)){
            isFormInvalid(true);
        }
        else{
            isFormInvalid(false)
        }

    };

    return (
        <Fragment>
            <BasicAuth
                usernameParameterType={usernameParameterType}
                passwordParameterType={passwordParameterType}
                formData={formData}
            />
        </Fragment>
    )
}

export default BasicAuthController;
