export class ForgetPasswordModel {

    email = "";
    password = "";
    confirmPassword = "";
    resetToken = "";
    isEmailValid = true;
    isPasswordValidated = true;
    isPasswordVisible = false;
    isConfirmPasswordVisible = false;
    errorMessage = null;
}