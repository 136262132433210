import { Paper } from "@mui/material";
import { Fragment } from "react";
import { FormContext } from "../../../../contexts/form-context";
import { ASInput } from "../../../../shared/as-inputs/as-input";

export const PostgreSqlServerView = (props) => {
    const { handleChangeInput, formData, datasourceTypeParameters } = props;

    return (
        <Paper
            sx={{
                minHeight: "100px",
                width: "900px",
                padding: "20px"

            }}
        >

            <FormContext.Provider value={{ handleChangeInput }}>

                <form>

                    {datasourceTypeParameters?.map((dataSource) => {

                        return (
                            <Fragment key={dataSource.parameterType}>

                                <div
                                    style={{ width: "80%" }}
                                    className=""
                                >
                                    <ASInput
                                        value={
                                            (
                                                !!formData &&
                                                !!formData[dataSource.parameterType] &&
                                                formData[dataSource.parameterType]
                                            ).value
                                        }
                                        fieldParameter={dataSource}
                                        errorMsg={
                                            (
                                                !!formData &&
                                                !!formData[dataSource.parameterType] &&
                                                formData[dataSource.parameterType]
                                            ).errorMsg
                                        }
                                    />

                                </div>

                            </Fragment>
                        );
                    })}

                </form>

            </FormContext.Provider>

        </Paper>
    );
};