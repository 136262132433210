import { Navigate } from 'react-router';
import { Environment } from "../core/environment";
import { getItem } from "../shared/local-storage-handler/local-storage-handler";
import { http } from "./http-request.service";
import querystring from 'querystring';

export const refreshToken = async () => {
    const parentApp = sessionStorage.getItem("parentApp");
    if (parentApp && parentApp == "excel-plugin") {
        return refreshTokenFromExcelPlugin();
    } else {
        return refreshTokenDefault();
    }
}

async function sleep(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
}

export const refreshTokenFromExcelPlugin = async () => {

    const messageId = Date.now();    
    window.chrome?.webview?.postMessage(`get_identity-messaged-${messageId}`);

    return new Promise(async (resolve, e) => {
        let checkCount = 0;
        while (checkCount <= 15) {            
            const messageFromPlugin = sessionStorage.getItem("messageId");    
            if (messageFromPlugin && messageFromPlugin == messageId.toString()) {
                resolve({
                    access_token: localStorage.getItem("token"),
                    refreshToken: localStorage.getItem("refresh_token")
                });                
                break;
            } else {
                await sleep(500);
            }

            checkCount++;
        }
    })


};

export const refreshTokenDefault = async () => {


    const baseUrl = Environment.getTokenAPIUrl();
    const refreshToken = getItem("refresh_token");

    var loginRequest = {
        "client_id": "webportal",
        "grant_type": "refresh_token",
        "refresh_token": refreshToken
    }

    return fetch(`${baseUrl}/connect/token`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: '',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: querystring.stringify(loginRequest),
    })
        .then(async (response) => {
            if (!response.ok) {
                return;
            } else {
                return await http.modifyResponse(response);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
