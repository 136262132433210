import React from "react";
import "./message-toaster.scss";

const MessageToaster = (props) => {

    return (
        <div className={props?.showLessGap ? 'less-gap-error-div' : props.isTestData ? "test-data-error-div" : "error-div"}>
            {props.errorMessage}
        </div>
    )

}

export default MessageToaster;