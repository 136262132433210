import React from 'react'
import { ASButton } from '../../../../../shared/as-button/as-button';
import DateFilterController from '../../../../../controllers/data-collections/column-selection/date-filter-controller/date-filter-controller';
import { Autocomplete, Box, Chip, InputLabel, MenuItem, Paper, Tooltip, styled } from '@mui/material';
import { ASTextField } from '../../../../../shared/as-text-fields';
import "../filterPopup.scss";
import { DateDataTypes, DurationFields } from '../date-filter/date-filter';


export default function AddFilterForm(props) {

    const { filterObject, filterFields, handleOnChnageFilterInput, isSort, conditionOrArr, multiSelectedFields,
        handleMultiSelectedFields, SubDateFields, setFilterObject, handleAddCondtionCancel, addCondition, isDisabled, optionValues, 
        handleOnChangeFilterValuesInput, isEmptyConditionsApplied, handleAutoCompleteDropdownInputChange, isEditingFilter } = props;

    const customMenuItem = styled(MenuItem)({
        fontSize: '13px',
    });

    return (
        <div>
            <div className="fields-group">
                <div className="fiels-group-part" style={{ width: isEmptyConditionsApplied ? '50%' : '' }}>
                    <InputLabel>Field</InputLabel>
                    <Autocomplete
                        id="value"
                        freeSolo
                        options={filterFields?.map((option) => ({
                            columnName: option.columnName,
                            displayName: option.displayName,
                        }))}
                        getOptionLabel={(option) => option.displayName || ''}
                        isOptionEqualToValue={(option, value) => option.columnName === value}
                        sx={{ margin: "0px !important", height: "60px" }}
                        renderOption={(props, item) => (
                                <customMenuItem
                                    {...props}
                                    key={item.columnName}
                                    value={item.columnName}
                                    name={item.displayName}
                                >
                                    {item.displayName}
                                </customMenuItem>
                        )}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                handleOnChnageFilterInput({ target: { value: newValue.columnName } }, "field", filterFields);
                            } else {
                                handleOnChnageFilterInput({ target: { value: '' } }, "field", filterFields);
                            }
                        }}
                        value={filterFields.find(option => option.columnName === filterObject?.field) || null}
                        onInputChange={(event, newInputValue) => {
                            handleAutoCompleteDropdownInputChange({ target: { value: newInputValue } }, "field", filterFields, isEditingFilter);
                        }}
                        renderInput={(params) => (
                            <ASTextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: filterObject?.field && params.InputProps.endAdornment,
                                    className:"fields-inputs"
                                }}
                                sx={{ width: "100%"}}
                            />
                        )}
                    />
                </div>
                <div className="condition-group-part" style={{ width: isEmptyConditionsApplied ? '50%' : '' }}>
                    <InputLabel>Condition</InputLabel>
                    <ASTextField
                        className="fields-selection"
                        select={true}
                        onChange={(e) => handleOnChnageFilterInput(e, isSort ? "value" : "condition")}
                        value={isSort ? filterObject?.value : filterObject?.condition}
                    >
                        {conditionOrArr?.map((item) => (
                            <MenuItem
                                key={item.name}
                                value={item.value}
                                name={item.name}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </ASTextField>
                </div>
                {(isEmptyConditionsApplied) ? "" :
                ((filterObject?.collectionColunmFilterObject != null || optionValues?.length > 0)) ?
                    ((filterObject?.isMultiSelect) ?
                        <div className="add-condition-value-div">
                            <InputLabel>Values</InputLabel>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                options={optionValues}
                                getOptionLabel={(option) => option.label}
                                value={multiSelectedFields}
                                onChange={handleMultiSelectedFields}
                                renderTags={(value, getTagProps) =>
                                    <div className='multiselect-chip'>
                                        {value.map((option, index) => (
                                            <Chip
                                                key={option.value}
                                                label={option.label}
                                                {...getTagProps({ index })}
                                            />
                                        ))}
                                    </div>
                                }
                                renderOption={(props, option) => (

                                    <MenuItem
                                        {...props}
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                )}
                                renderInput={(params) => (
                                    <ASTextField
                                        {...params}
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            // endAdornment: (
                                            //   <React.Fragment>
                                            //     {filterObject?.value && params.InputProps.endAdornment}
                                            //   </React.Fragment>
                                            // ),
                                            sx: {
                                                padding:"0px !important",
                                                '& .MuiAutocomplete-clearIndicator': {
                                                    display: 'none',
                                                },
                                                '& .MuiAutocomplete-popupIndicator': {
                                                    display: 'none',
                                                }
                                            }
                                        }}
                                    />
                                )}
                                sx={{ width: "100%", margin: "0px !important", padding: "0px !important", }}
                            />
                        </div>
                        :
                        <div className="add-condition-value-div">
                            <InputLabel>Value</InputLabel>
                            <Autocomplete
                                id="values"
                                freeSolo
                                // className="fields-selection"
                                options={optionValues.map((option) => ({
                                    label: option.label,
                                    value: option.value,
                                }))}
                                sx={{ margin: "0px !important" }}
                                renderOption={(props, option) => {
                                    return (
                                        <MenuItem
                                            {...props}
                                            key={`option ${option.value}`}
                                            value={option?.value}
                                            name={option.label}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    );
                                }}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        handleOnChangeFilterValuesInput({ target: { value: newValue.value } }, "values", true);
                                    } else {
                                        handleOnChangeFilterValuesInput({ target: { value: '' } }, "values");
                                    }
                                }}
                                value={filterObject?.valueLabel}
                                renderInput={(params) => (
                                    <ASTextField
                                        {...params}
                                        // onChange={(e) => handleOnChnageFilterInput(e, "value")}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: filterObject?.value && params.InputProps.endAdornment,
                                            className:"fields-inputs"
                                        }}
                                        sx={{ width: "100%" }}
                                    />
                                )}
                            />
                        </div>)
                    :
                    <>
                        {DateDataTypes.includes(filterObject?.dataTypeName) &&
                            <DateFilterController
                                filterObject={filterObject}
                                handleOnChnageFilterInput={handleOnChnageFilterInput}
                                setFilterObject={setFilterObject}
                                filterCondtions={props?.filterCondtions}
                            />
                        }
                        {!DateDataTypes.includes(filterObject?.dataTypeName) && filterObject?.dataTypeName != "enumeration" &&
                            <div className="add-condition-value-div">
                                <InputLabel>{(filterObject?.condition == "BETWEEN" && filterObject?.dataTypeName == "number")  ? "From Value" : "Value"}</InputLabel>
                                <ASTextField autoComplete="off" value={filterObject?.value} onChange={(e) => handleOnChnageFilterInput(e, "value")}
                                            InputProps={{
                                                className:"fields-inputs"
                                            }}
                                            sx={{ width: "100%" }} />
                            </div>}
                        {(filterObject?.condition == "BETWEEN" && filterObject?.dataTypeName == "number") &&
                        <div className="add-condition-value-div">
                            <InputLabel>To Value</InputLabel>
                            <ASTextField autoComplete="off" value={filterObject?.highValue} onChange={(e) => handleOnChnageFilterInput(e, "highValue")}
                                        InputProps={{
                                            className:"fields-inputs"
                                        }}
                                        sx={{ width: "100%" }} />
                        </div>}
                        {filterObject?.dataTypeName == "enumeration" &&
                            <div className="add-condition-value-div">
                                <InputLabel>Values</InputLabel>
                                <ASTextField autoComplete="off" value={filterObject?.values} onChange={(e) => handleOnChangeFilterValuesInput(e, "values", false)}
                                            InputProps={{
                                                className:"fields-inputs"
                                            }}
                                            sx={{ width: "100%"}} />
                            </div>}
                    </> }
            </div>

            {/* <div className="add-condition-div">Click on Add to add condition</div> */}
            <div className="add-condition-button-div">
                <div className="cancel-button-div">
                    <button
                        className="cancel-link-button"
                        onClick={() => handleAddCondtionCancel()}
                    >
                        Cancel
                    </button>
                </div>

                <ASButton
                    onClick={() => addCondition()}
                    sx={{
                        color: "white !important", backgroundColor: "#6B859E !important",
                        borderRadius: "6px !important",
                        boxShadow: "0px 2px 4px #6B859E !important",
                        padding: "5px 25px !important"
                    }}
                    disabled={isDisabled}
                >
                    Ok
                </ASButton>
            </div>
        </div>
    );
}