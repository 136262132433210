import { AsheetLogoExcelSvg } from "../../../constants/common-svgs";
import { ASButton } from "../../../shared/as-button/as-button";
import './connecting-view.scss';

const ConnectingPageView = (props) => {
    const { selectedConnection, onPopUpClose } = props;

    return (
        <>
            <div className="connecting-dialog-overlay">
                <div className="connecting-dialog">

                    <div className="connecting-text">
                        Connecting to {selectedConnection?.description}...
                    </div>
                    <div className="logo-div">
                        <span className="asheetlogo">{AsheetLogoExcelSvg}</span>
                        <img className="arrow-icon-connecting" src={`/assets/back-arrow-animation.svg`} alt="" />
                        <img className="connection-logo" src={`/assets/${selectedConnection?.iconImage}`} alt={selectedConnection?.iconImage} />
                    </div>
                    <div className="cancel-button-view">
                        <ASButton primary={true} onClick={onPopUpClose}> Cancel </ASButton>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ConnectingPageView;