import React, { useEffect, useState } from "react";
import AcceptInvitationComponent from "../../../components/connections/acceptInvitation/AcceptInvitation";
import * as UserService from "../../../services/invitation.service";
import { useLocation, useNavigate } from "react-router-dom";
import { getItem, getToken, removeItem, setToken } from "../../../shared/local-storage-handler/local-storage-handler";
import { refreshTokenHandler } from "../../../core/axios.interceptor";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import jwtDecode from "jwt-decode";

export default function AcceptInvitation() {
  const [invitationData, setinvItationData] = useState({
    name: "",
    password: "",
    confirmPassword: "",
  });
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [emailId, setEmailId] = useState({
    email: "",
  });
  const location = useLocation();
  const [searchState, setSearchState] = useState();
  const [acceptInvitation, setAcceptInvitation] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const state = location.state;

  useEffect(() => {
    // Parse the query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    // Get the value of the 'inviteHash' parameter
    const inviteHash = queryParams.get("inviteHash");
    removeItem("invite-hash");
    if (inviteHash) {
      setSearchState(inviteHash);
      getInvitation(inviteHash);
    }
  }, [location.search]);

  // Move this inside the useEffect

  const getInvitation = async (inviteHash) => {
    try {
      // Set loading state to true
      setLoading(true);

      const response = await UserService.getInviteUsertDetail(inviteHash);
      if(response?.hasError){
        navigate("/login");
      }
      else if (response.data) {
        let fields = invitationData;
        let email = emailId;
        fields.name = response?.data.name;
        email.email = response?.data.emailId;
        setAcceptInvitation(response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const inviteHash = queryParams.get("inviteHash");

    if (inviteHash) {
      const decodedEmail = decodeURIComponent(
        atob(inviteHash.replace(/_/g, '/').replace(/-/g, '+'))
          .split('')
          .map(c => '%' + c.charCodeAt(0).toString(16).padStart(2, '0'))
          .join('')
      );

      const accessToken = getItem('token');
      const decodedToken = accessToken ? jwtDecode(accessToken) : "";

      const userExists = acceptInvitation?.userExists;
      const isMatchingUser = decodedEmail === decodedToken?.username;

      if (userExists && !getToken()) {
        setToken("invite-hash", inviteHash);
        navigate("/login", {
          state: `/acceptinvitation?inviteHash=${inviteHash}`
        });
      }
      else if (userExists && !isMatchingUser) {
        navigate("/");
      }
    }
  }, [acceptInvitation?.userExists, location.search, navigate]);

  const handleInputChange = (field) => (e) => {
    setErrorMessage("");
    setinvItationData({ ...invitationData, [field]: e.target.value });
    setEmailId({ ...emailId, [field]: e.target.value });
  };

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  }

  const handleToggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const handleSubmit = async () => {
    const isFormValid = !acceptInvitation?.userExists
      ? Object.values(invitationData).every((value) => value.trim() !== "")
      : true;
    const checkDetails = { ...invitationData }; 
    if(!acceptInvitation?.userExists && checkDetails?.confirmPassword !== checkDetails?.password){
      setErrorMessage("The new password does not match the confirm password");
    }
    else if (isFormValid) {
      // Perform submission logic
      setButtonDisable(true);
      let invite = { ...invitationData };

      if (acceptInvitation?.userExists) {
        delete invite.password;
        delete invite.confirmPassword;
        delete invite.name;
      }
      invite.inviteHash = searchState.toString();
      const response = await UserService.completeInvitation(invite);
      if (response?.data?.item1?.hasError) {
        setErrorMessage(
          response?.data?.item1?.validationErrors[0].errorMessage
        );
        toast.error(response?.data?.item1?.validationErrors[0].errorMessage);
        setButtonDisable(false);
      } else if (response?.hasError) {
        setErrorMessage(response?.errorMessage);
        toast.error(response?.errorMessage);
        setButtonDisable(false);
      } else {
        if (acceptInvitation?.userExists) {
          let response = await refreshTokenHandler(); //refreshing token 
          response ? navigate("/") : navigate("/login");          
        } else {
          navigate("/login");
        }
      }
    }
  };

  return (
    <div>
      <AcceptInvitationComponent
        handleToggleConfirmPasswordVisibility={
          handleToggleConfirmPasswordVisibility
        }
        handleSubmit={handleSubmit}
        handleTogglePasswordVisibility={handleTogglePasswordVisibility}
        handleInputChange={handleInputChange}
        formData={invitationData}
        isPasswordVisible={isPasswordVisible}
        isConfirmPasswordVisible={isConfirmPasswordVisible}
        acceptInvitation={acceptInvitation}
        emailId={emailId}
        errorMessage={errorMessage}
        loading={loading}
        buttonDisable={buttonDisable}
      />
    </div>
  );
}
