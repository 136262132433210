import React, { useEffect, useState } from 'react'
import TallyConnector from '../../../../components/connections/connection-details/rest-api/tally-connector'
import { RestServiceConstants } from '../../../../constants/rest-service-constant';
import { FormContext } from "../../../../contexts/form-context";
import { isNullOrEmpty } from '../../../../services/data-source-validation';

export default function TallyConnectorController(props) {


    const { basicAuthParameters, formData, isFormInvalid, tallyConnectorUrl, handleChangeInput, proxyConnectorParameter } = props;

    const [isProxyConnectorRequired,setIsProxyConnectorRequired] = useState(false);
    const [isDialogOpen,setIsDialogOpen] = useState(false);

    useEffect(() => {
        isFormInvalid(false);
        if(formData && formData[proxyConnectorParameter.parameterType]){
            setIsProxyConnectorRequired(formData[proxyConnectorParameter.parameterType].value == "false");
        }
        else{
            formData[proxyConnectorParameter.parameterType] ={
                value: "true",
                datasourceParameterTypeId: 2034,
                errorMsg: "",
                isValid: true
            };
        }
    },[])

    const openSecretDialog = () => {
        setIsDialogOpen(true);
    }
    const handleRequireProxyConnector = (event) => {
        setIsProxyConnectorRequired(event.target.checked);
    
        formData[proxyConnectorParameter.parameterType] ={
                value: `${!event.target.checked}`,
                datasourceParameterTypeId: formData[proxyConnectorParameter.parameterType].datasourceParameterTypeId,
                errorMsg: formData[proxyConnectorParameter.parameterType].errorMsg,
                isValid: formData[proxyConnectorParameter.parameterType].isValid
        };
    }

  return (
    <FormContext.Provider value={{ handleChangeInput }}>
        <TallyConnector formData={formData}
                        isDialogOpen={isDialogOpen}
                        openSecretDialog={openSecretDialog}
                        setIsDialogOpen={setIsDialogOpen}
                        tallyConnectorUrl={tallyConnectorUrl}
                        isProxyConnectorRequired={isProxyConnectorRequired}
                        handleRequireProxyConnector={handleRequireProxyConnector}
        />
    </FormContext.Provider>
  )
}
