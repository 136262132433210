import React from "react";
import './username-menu.scss';
import { Avatar } from "@mui/material";

const UserNameMenu = (props) => {

    return (
        <div className={props?.isUserLoggedIn ? 'username-menu-container' : ''}>
            <Avatar
                sx={{
                    display: "inline-block",
                    lineHeight: "2",
                    textAlign: "center",
                    bgcolor: "#E0CC4D",
                    color: props?.styles?.primaryColor,
                }}
                className={props?.isUserLoggedIn ? 'avatar' : 'avatar disable-hover'}
            >
                <span className="header-avatar">
                    {
                        props?.userName?.split(" ")
                            .map((digit) => digit[0]).join("")
                            .substring(0, 2).toUpperCase()
                    }
                </span>

            </Avatar>

            <div className='dropdown'>

                <div className='menu-arrow'></div>

                <ul className='dropdown-menu'>

                    {
                        props?.menus?.map((menu, index) =>

                            menu.isVisible &&

                            <li key={`menus ${index}`}>
                                <span onClick={() => props.onMenuClick(menu)}
                                    className={index === (props.menus?.length - 1) ? 'border-btm' : ''}
                                >{menu.label}</span>
                            </li>
                        )
                    }

                </ul>
            </div>
        </div>
    )

}

export default UserNameMenu;