import React, { Fragment } from "react";
import "./AddNewVariablePopup.scss";
import { ASTextField } from "../../../shared/as-text-fields";
import { InputConstants } from "../../../constants/input-constant";
import { selectedMenuItemBackgroundColor } from "../../data-collections/collection-parameters/collection-parameters";
import { Card, MenuItem, Dialog, Backdrop, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ASButton } from "../../../shared/as-button/as-button";
import MessageToaster from "../../../shared/message-toaster/message-toaster";
import { AppEnum } from "../../../constants/app-enum";
import { muiCheckboxStyle } from "../../user-permission/user-permission";

export default function AddNewVariablePopup(props) {
  const navigate = useNavigate();
  const { variableUId, open, handleCloseDialog } = props;
  return (
    <Fragment>
      <Dialog open={open} handleCloseDialog={handleCloseDialog} BackdropComponent={Backdrop}
        BackdropProps={{
          style: { backdropFilter: "blur(3px)" },
          onClick: handleCloseDialog,
        }}
        PaperProps={{ sx: { borderRadius: "10px", width: "540px" } }}
        sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
        <Card className="input-field-box">
          {variableUId?.length > 0 ? (<div className="variabe-header">Update Variable</div>) : (<div className="variabe-header">Add New Variable</div>)}

          {
            props.updateError?.length > 0 &&
            <div className="collection-error-div">
              <MessageToaster errorMessage={props.updateError} />
            </div>
          }

          <div className="input-field-box-padding">
            <div className="input-padding">
              <ASTextField
                className="text-field"
                disabled={variableUId?.length > 0 && !props.templateCompany &&
                props.formData.isTemplateVariable && props.formData.templateConnectionId !== null}
                type={InputConstants.text}
                fullWidth
                label="Variable Name"
                variant="outlined"
                value={props.formData.name}
                onChange={(event) => props.handleChange("name", event.target.value)}
                required={true}
              />

            </div>

            <div className="input-padding">
              <ASTextField
                className="text-field"
                type={InputConstants.text}
                fullWidth
                label="Data Type"
                variant="outlined"
                disabled={((props?.variableDataType) && (props?.variableDataType !== props?.USER_DEFINED_VARIABLE)) || ((variableUId?.length > 0) && (!props.templateCompany &&
                  props.formData.isTemplateVariable && props.formData.templateConnectionId !== null)) || (props.selectedDataType === props?.DYNAMIC_LIST) ? true : false}
                value={props.selectedDataType}
                onChange={props.handleDataTypeChange}
                select={true}
                SelectProps={{
                  MenuProps: {
                    className: "menu-role-div",
                    sx: selectedMenuItemBackgroundColor,
                  },
                }}
                required={true}
              >
                {props?.variableDataType !== "" ?
                (props.dataTypeOptions.map((option) => (
                  <MenuItem key={option.displayLabel} value={option.displayLabel} disabled={option?.disabled}>
                    {option.displayLabel}
                  </MenuItem>
                )))
                : ""}
              </ASTextField>
            </div>

            {!props.showAddValue ? (

              props.selectedDataType === AppEnum.DataBaseDataType.Date ? (
                <div className="input-padding">
                  <ASTextField
                    //key={index}
                    className="text-field"
                    type="date"
                    fullWidth
                    label="Variable Value"
                    variant="outlined"
                    value={props.formData.primitiveTypeValue}
                    onChange={(event) =>
                      props.handleChange("primitiveTypeValue", event.target.value)
                    }
                  />
                </div>
              ) :
                props.selectedDataType === "Date Time" ? (
                  <div className="input-padding">
                    <ASTextField
                      className="text-field"
                      type="datetime-local"
                      fullWidth
                      label="Variable Value"
                      variant="outlined"
                      value={props.formData.primitiveTypeValue}
                      onChange={(event) =>
                        props.handleChange("primitiveTypeValue", event.target.value)
                      }
                    />
                  </div>
                ) :
                props?.selectedDataType === "True or False" ? (
                      <div className="bolean-box">
                          <div className="true=checkbox-div" >
                                <Checkbox
                                    checked={props?.formData?.primitiveTypeValue}                                    
                                    onChange={(event) => props?.onVariableCheckBox("primitiveTypeValue",event?.target?.checked)}
                                    sx={muiCheckboxStyle}
                                />
                                Variable Value
                            </div>                            
                      </div>
                  ) :
                  (props.selectedDataType !== "Dynamic List" ?
                    <div>                                          
                      <div className="input-padding">
                        <ASTextField
                          className="text-field no-spinner"
                          type={props.showNumberInput ? "number" : InputConstants.text}
                          fullWidth
                          label="Variable Value"
                          required={true}
                          variant="outlined"
                          value={props.formData.primitiveTypeValue}
                          onChange={(event) =>
                            props.handleChange("primitiveTypeValue", event.target.value)
                          }
                        />
                      </div>
                    </div>
                    : "")
            ) : null}

            {props.isDynamic ?
              <div className="input-field-box-padding">
                <div className="input-padding">
                  <ASTextField
                    className="text-field"
                    type={InputConstants.text}
                    fullWidth
                    disabled={props?.showDynamicFields}
                    label="Select a Dynamic List"
                    variant="outlined"
                    value={props?.isDynamicListValues?.DataCollectionId}
                    onChange={(event) => props?.onChangeDynamicListHandler(event?.target?.value)}
                    select={true}
                    SelectProps={{
                      MenuProps: {
                        className: "menu-role-div",
                        sx: selectedMenuItemBackgroundColor,
                      },
                    }}
                  >
                    {props?.dynamicList?.map((list, index) => {
                      return (
                        <MenuItem value={list?.id} key={`dynamic ${index}`} >
                          {list.name}
                        </MenuItem>
                      );
                    })}
                  </ASTextField>
                </div>

                <div className="columns-div">
                  <div className="columns-input">
                    <ASTextField
                      className="text-field"
                      type={InputConstants.text}
                      fullWidth
                      disabled={props?.showDynamicFields}
                      label="Display Column"
                      variant="outlined"
                      value={props?.isDynamicListValues?.DisplayColumn}
                      onChange={(event) => props.onChangeValueColumnHandler("Display", event?.target?.value)}
                      select={true}
                      SelectProps={{
                        MenuProps: {
                          className: "menu-role-div",
                          sx: selectedMenuItemBackgroundColor,
                        },
                      }}
                    >
                      {props?.columnList?.map((column, index) => {
                        return (
                          <MenuItem value={column.columnName} key={`column-list ${index}`}>
                            {column.columnName}
                          </MenuItem>
                        );
                      })}
                    </ASTextField>
                  </div>

                  <div className="columns-input" >
                    <ASTextField
                      className="text-field"
                      type={InputConstants.text}
                      fullWidth
                      disabled={props?.showDynamicFields}
                      label="Value Column"
                      variant="outlined"
                      value={props?.isDynamicListValues?.ValueColumn}
                      onChange={(event) => props.onChangeValueColumnHandler("Value", event?.target?.value)}
                      select={true}
                      SelectProps={{
                        MenuProps: {
                          className: "menu-role-div",
                          sx: selectedMenuItemBackgroundColor,
                        },
                      }}
                    >
                      {props?.columnList?.map((variable, index) => {
                        return (
                          <MenuItem value={variable.displayName} key={`var-list ${index}`
                          }>
                            {variable.displayName}
                          </MenuItem>
                        );
                      })}
                    </ASTextField>
                  </div>
                </div>

                <div className="input-padding">
                  <ASTextField
                    className="text-field"
                    type={InputConstants.text}
                    fullWidth
                    label="Default Value"
                    variant="outlined"
                    value={props?.isDynamicListValues?.DefaultValue}
                    onChange={(event) => props.onChangeValueColumnHandler("Default", event.target.value)}
                  >
                  </ASTextField>
                </div>
              </div>
              : ""}

          {variableUId && props.showAddValue && props.userList ?
            <div className="user-defined-list">
                  <div className="user-defined-dropdown">
                    <ASTextField
                      className="text-field"
                      type={InputConstants.text}
                      fullWidth
                      label="User Defined List Labels"
                      variant="outlined"
                      value={props?.userDefinedValues[0]?.value}
                      select={true}
                      SelectProps={{
                        MenuProps: {
                          className: "menu-role-div",
                          sx: selectedMenuItemBackgroundColor,
                        },
                      }}
                      required={true}
                    >
                      {props.userDefinedValues.map((option) => (
                        <MenuItem key={option.Label} value={option.Label}>
                          {option.Label}
                        </MenuItem>
                      ))}
                    </ASTextField>
                  </div>
                  <div className="edit-value-btn" onClick={props.handleAddInput}>
                    <><i class="add-icon">+</i>Edit Values</>
                  </div>
            </div> : 
            props.showAddValue ? (
                <div className="add-value-btn" onClick={props.handleAddInput}>
                  <i class="add-icon">+</i>Add Values
                </div>
            ) : null }
            {props.templateCompany && (props.formData.templateConnectionId || !variableUId?.length) ?
            <div className="input-padding">
              <ASTextField
                className="text-field"
                type={InputConstants.text}
                fullWidth
                label="Connection Name"
                variant="outlined"
                value={props.formData.templateConnectionId}
                onChange={props.onConnectionChange}
                SelectProps={{
                  MenuProps: {
                    className: "menu-role-div",
                    sx: selectedMenuItemBackgroundColor,
                  },
                }}
                select={true}
              >
                {props.connectionList?.map((option) => (
                  <MenuItem key={option.name} value={option?.id} >
                    {option.name}
                  </MenuItem>
                ))}
              </ASTextField>
            </div>
            : null }

            <div className="button-flex">
              <ASButton onClick={handleCloseDialog} primary={false}>
                Cancel
              </ASButton>
              {props.saveDisabled ? (
                variableUId?.length > 0 ? (<ASButton primary={false}>Update</ASButton>
                ) : (
                  <ASButton primary={false}>Save</ASButton>)

              ) : (
                variableUId?.length > 0 ? (<ASButton onClick={() => props.updateHandler()} primary={true}>
                  Update
                </ASButton>) : ((<ASButton onClick={() => props.saveHandler()} primary={true}>
                  Save
                </ASButton>))
              )}
            </div>
          </div>
        </Card>
      </Dialog>
    </Fragment>
  );
}
