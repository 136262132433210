import { getToken } from "../shared/local-storage-handler/local-storage-handler";

const modifyResponse = (res) => {
	let json = res.json();

	if (res.status >= 200 && res.status < 300) {
		return json;
	} else {
		return json.then(Promise.reject.bind(Promise));
	}
};

function get(BASE_API, url) {

	let accessToken = getToken();

	return fetch(`${BASE_API}/${url}`,
		{
			method: "GET",
			headers: {
				Authorization: accessToken ? `bearer ${accessToken}` : ''
			}
		}

	).then((res) => {
		try {
			return modifyResponse(res);
		} catch (e) {
			return null;
		}
	});
}

function post(BASE_API, url, body, isFormData) {

	let accessToken = getToken();

	return fetch(`${BASE_API}/${url}`,
		isFormData ?
			// for formdata
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					Authorization: accessToken ? `bearer ${accessToken}` : ''
				},
				body: body,
			} :
			// for body
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: accessToken ? `bearer ${accessToken}` : ''
				},
				body: JSON.stringify(body),
			}

	).then((res) => {

		try {
			return modifyResponse(res);
		} catch (e) {
			return null;
		}
	});
}

function put(BASE_API, url, body) {

	let accessToken = getToken();

	return fetch(`${BASE_API}/${url}`,
		{
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: accessToken ? `bearer ${accessToken}` : ''
			},
			body: JSON.stringify(body),
		}
		
	).then((res) => {

		try {
			return modifyResponse(res);
		} catch (e) {
			return null;
		}
	});
}

export const http = { get, post, put, modifyResponse };
