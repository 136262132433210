import { axios } from "../core/axios.interceptor";

const baseURL = "sys";
const reportBaseURL = "collections";

// api for getting data collection
export async function getDataCollection() {
    const response = await axios.get(`${reportBaseURL}`);
    return response;
}

// api for getting category list
export async function getCategoryList() {
    const response = await axios.get(`categories`);
    return response;
}

// api for getting collection type list
export async function getCollectionTypeList(sysConnectionTypeId) {
    const response = await axios.get(`${baseURL}/collection-types?sysConnectionTypeId=${sysConnectionTypeId}`);
    return response;
}

// api for getting variables list
export async function getVariableList(isUserDefineVariableOnly) {
    const response = await axios.get(`variables${isUserDefineVariableOnly ? `?isUserDefineVariableOnly=true` : ''}`);
    return response;
}

// api for getting system define variables list
export async function getSystemDefineVariableList() {
    const response = await axios.get(`${baseURL}/variables`);
    return response;
}

// api for getting field type list
export async function getFieldTypeList(isVariable = false ,isCollection = false) {
    const response = await axios.get(`${baseURL}/datatypes?isVariable=${isVariable}&isCollection=${isCollection}`);
    return response;
}

// api for getting variable type by sysDataTypeId and parameterTypeUId
export async function getSysVariableList(parameterTypeUId, sysDataTypeId) {
    const param = sysDataTypeId > 0 ? `?sysDataTypeId=${sysDataTypeId}` : parameterTypeUId ? `?parameterTypeUId=${parameterTypeUId}` : "";
    const response = await axios.get(`variables${param}`);
    return response;
}

// api for getting dynamic list collection 
export async function getDynamicListCollection() {
    const response = await axios.get(`${reportBaseURL}/basic-details`);
    return response;
}

// api for getting column details 
export async function getColumnDetails(collectionId) {
    const response = await axios.get(`${reportBaseURL}/columns?collectionId=${collectionId}`);
    return response;
}

// api for saving data collection
export async function saveDataCollection(dataCollectionModal) {
    const response = await axios.post(`${reportBaseURL}`, dataCollectionModal);
    return response;
}

// api for updating data collection
export async function updateDataCollection(dataCollectionModal) {
    const response = await axios.put(`${reportBaseURL}/${dataCollectionModal.uId}`, dataCollectionModal);
    return response;
}

// api for testing data collection
export async function testDataCollection(dataCollectionModal) {
    const collectionUId = dataCollectionModal?.uId ? dataCollectionModal?.uId : "xxxxxxxxxx";
    const response = await axios.post(`${reportBaseURL}/${collectionUId}/test-data`, dataCollectionModal);
    return response;
}

// api for testing data collection
export async function executedDataCollection(dataCollectionModal) {
    const collectionUId = dataCollectionModal?.uId ? dataCollectionModal?.uId : "xxxxxxxxxx";
    const response = await axios.post(`${reportBaseURL}/${collectionUId}/executed-data`, dataCollectionModal);
    return response;
}

// api for column schema
export async function getColumnSchema(dataCollectionModal) {
    const collectionUId = dataCollectionModal?.uId ? dataCollectionModal?.uId : "xxxxxxxxxx";
    const response = await axios.post(`${reportBaseURL}/${collectionUId}/test-schema`, dataCollectionModal);
    return response;
}

// api for getting collection data
export async function getCollectionDetails(collectionUId) {
    const response = await axios.get(`${reportBaseURL}/${collectionUId}`);
    return response;
}

// api for getting display format list
export async function getDisplayFormats() {
    const response = await axios.get(`${baseURL}/display-formats`);
    return response;
}

// api for deleting collection through CollectionUId
export async function deleteDataCollection(collectionUId) {
    const response = await axios.delete(`${reportBaseURL}/${collectionUId}`);
    return response;
}

// api for get collection through CollectionUId
export async function getUserListByCollectionUId(collectionUId) {
    const response = await axios.get(`collections/${collectionUId}/users/details`);
    return response;
}

// api for validating whether the Data collection Name

export async function getCollectionByName(collectionName) {
    const response = await axios.get(`${reportBaseURL}/existance?collectionName=${collectionName}`);
    return response;
}

export async function saveCopyCollection(collectionUId,collectionName){
    const response = await axios.post(`${reportBaseURL}/${collectionUId}/copy?collectionName=${collectionName}`);
    return response;
}

//to get cloud collection objects
export async function getCollectionObjectByType(connectorName) {
    const response = await axios.get(`${reportBaseURL}/type/${connectorName}`);
    return response;
}

export async function getCollectionFilterConfig(connectionTypeId) {
    const response = await axios.get(`${reportBaseURL}/config/${connectionTypeId}`);
    return response;
}

export async function getCollectionFilterColumns() {
    const response = await axios.get(`collection-filter-column`);
    return response;
}