import React,{useEffect} from 'react'
import { RestServiceConstants } from '../../constants/rest-service-constant';

export default function BeginOAuth() {

  useEffect(() => {
    const queryString = window.location.search.substring(1);
    const params = new URLSearchParams(queryString);
    const stateObj = {};
    params.forEach((value, key) => {
      stateObj[key] = decodeURIComponent(value);
    });
    // var formData = JSON.parse(stateObj.formData);
    // var client_id = stateObj.client_id;
    // var redirect_uri = stateObj.redirect_uri;
    // var auth_url = stateObj.auth_url;
    // var scope = stateObj.scope;
    // var code_challenge = stateObj.code_challenge;
    // var code_challenge_method = stateObj.code_challenge_method;
    // window.location.href = `${auth_url}?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}&scope=${scope}${(code_challenge != "" && code_challenge!= null) ?  (code_challenge_method != "" && code_challenge_method != null) ? `&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}` : `&code_challenge=${code_challenge}` : ''}`;

    var client_id = stateObj.client_id;
    var redirect_uri = stateObj.redirect_uri;
    var auth_url = stateObj.auth_url;
    var scope = stateObj.scope;
    var code_challenge = stateObj.code_challenge;
    var code_challenge_method = stateObj.code_challenge_method;

    // Build the URL dynamically
    var url = `${auth_url}?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}&scope=${scope}`;

    // Loop through stateObj to add all key-value pairs to the URL
    for (const [key, value] of Object.entries(stateObj)) {
      // Skip the keys that are already used in the URL
      if (['client_id', 'redirect_uri', 'auth_url', 'scope', 'code_challenge', 'code_challenge_method'].includes(key)) {
        continue;
      }
      url += `&${key}=${encodeURIComponent(value)}`;
    }

    // Add code_challenge and code_challenge_method if they exist
    if (code_challenge && code_challenge !== "") {
      url += `&code_challenge=${code_challenge}`;
      if (code_challenge_method && code_challenge_method !== "") {
        url += `&code_challenge_method=${code_challenge_method}`;
      }
    }

    window.location.href = url;
  },[])
  

  return (
    <div>
      
    </div>
  )
}
