import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/system";
import styles from "../../../styles/_exports.module.scss";
import { Checkbox, FormControlLabel } from '@mui/material';

export const muiDataGridstyle = {
    '& .MuiDataGrid-columnHeaderTitle': {
        color: styles.secondaryColor,
        fontWeight: 600,
        fontSize: "15px",
    },
    '& .MuiDataGrid-cellContent': {
        color: "#6b859e",//styles.muted,
        fontSize: "14px",
    }
}

function DataGridTable({ rows, columns }) {

    function generateRandom() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const gridColumnKeyData = columns?.map(item => ({ id: item, headerName: item, field: item, description: item, width: 200 }));

    return (
        <Box sx={{ height: rows.length > 10 ? 400 : "100%", width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={gridColumnKeyData}
                getRowId={() => generateRandom()}
                sx={muiDataGridstyle}
                rowHeight={38}
                pagination={false}
                pageSize={1000}
                hideFooter={true}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                disableColumnSelector={true}
                
            />
        </Box>
    );
}
export default DataGridTable;
