import React from "react";
import "./page-not-found.scss";
import { ASButton } from "../../shared/as-button/as-button";
import { Link } from "react-router-dom";

const PageNotFound = (props) => {

    return (
        <div className="not-found-container">
            <div className="not-found-body">
                <h1 className="oops-heading">Oops!</h1>
                <h1>404 - Page Not Found</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
                <div className="not-found-button-div">
                    <Link to="#" className="go-back-link" onClick={() => props.handleGoBack()}><span >{"<- Go Back"}</span></Link>
                    <ASButton primary={true} onClick={() => props.handleGoToHome()}>Go to Dashboard</ASButton>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
