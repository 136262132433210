export const ASBookAnExpert = ({ flag, className }) => {
    return (
        <>
            <svg
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="21"
                viewBox="0 0 28 21"
            >
                <path
                    id="Layer_1"
                    d="m15,16.462l9,3.538v4H7.538l-1.821-1.628c-.917-.858-.96-2.307-.098-3.23.861-.922,2.313-.97,3.235-.109.034.032,1.069.898,2.145,1.784v-8.817c0-1.215,1.083-2.176,2.336-1.973.983.16,1.664,1.083,1.664,2.08v4.355Zm6-9.462v-2c0-1.654-1.346-3-3-3h-2V0h-2v2h-7V0h-2v2h-2c-1.654,0-3,1.346-3,3v2h21ZM4.159,17.776c1.269-1.358,3.224-1.705,4.841-1.005v-4.771c0-1.153.5-2.24,1.364-3H0v11h3.065c.133-.807.496-1.584,1.095-2.224Zm12.841-5.669v2.992l4,1.573v-7.671h-5.393c.853.762,1.393,1.884,1.393,3.107Z"
                    transform="translate(5 -2)"
                    fill={flag ? "#FFF" : "#16365f"}
                />
            </svg>
        </>
    );
};
