import React from "react";
import { useNavigate } from "react-router-dom";
import PageNotFound from "../../components/page-not-found/page-not-found";

const PageNotFoundController = (props) => {

    const navigate = useNavigate();

    const handleGoToHome = () => {
        navigate("/connections");
    };

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <PageNotFound handleGoToHome={handleGoToHome} handleGoBack={handleGoBack}/>
    );
};

export default PageNotFoundController;
