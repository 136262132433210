import { useEffect, useState } from 'react'
import BasicInformation from "../../../../components/data-collections/basic-information/basic-information";
import * as collectionService from "../../../../services/data-collection.service";
import { getDataSourceByUID } from "../../../../services/data-source.service";
import * as GroupService from '../../../../services/groups-service';
import BasicInformationPopup from '../../../../components/data-collections/basic-information/basic-information-popup';
import { DynamicList } from '../../../connections/variables/variables-Popup';
import {getTemplateCompany} from '../../../../services/variable-service'
const BasicInformationController = (props) => {

    const { state, setState, getCollectionTypeList, prevStepRef, initialCollectionName, saveCollection, setIsDialogOpen, isDialogOpen, isBasicInfoSubmitted, isUpdatingCollection, isCloudLoading, isDynamicListValues, setIsDynamicListValues,
        isVariableUsedInCollection, isRest, selectedConnection, showSaveCollection, isTestedAfterSaveAppears, setShowTestCollectionPopup
    } = props;
    const [groups, setGroups] = useState([]);
    const [subGroups, setSubGroups] = useState([]);
    const [isTemplateCompany, setIsTemplateCompany] = useState(false);

    useEffect(() => {
        getGroups();
    }, [])

    //Fetching all the groups and subgroups
    const getGroups = () => {
        GroupService.getAllGroups().then((response) => {
            if (response?.data) {
                setGroups(response?.data);
            }
        })
    }

    useEffect(() => {
        if (state.connectionUId) {
            getCurrentConnectionDetails(state.connectionUId);
        }
    }, [state.connectionUId])


    useEffect(() => {

        if (state.name && state.name != initialCollectionName) {
            const getCollectionByName = setTimeout(() => {
                collectionService.getCollectionByName(state.name)
                    .then((response) => {
                        if (response.data) {
                            setState((prevState) => { return { ...prevState, basicInfoError: "Collection with this name already exists" } });
                        }
                        else {
                            setState((prevState) => { return { ...prevState, basicInfoError: null } });
                        }
                    });
            }, 3000);

            return () => clearTimeout(getCollectionByName);
        }

        setState((prevState) => { return { ...prevState, basicInfoError: null } });

    }, [state.name])

    useEffect(() => {
        async function fetchTemplateCompany() {
            try {
                const response = await getTemplateCompany();
                if (response?.data == true && props?.isRest) {
                    setIsTemplateCompany(true);
                }
            } catch (error) {
                console.error("Error fetching template company details:", error);
            }
        }
        fetchTemplateCompany();
    }, []);

    // it sets the state of data collection name
    const onChangeDataCollectionNameHandler = (event) => {
        setState((prevState) => { return { ...prevState, name: event.target.value} });
    }

    // it sets the state of source name part 2
    const onChangeSourceNamePart2NameHandler = (event) => {
        setState((prevState) => { return { ...prevState, sourceNamePart2: event.target.value } });
    }

    // it sets the state of group name
    const onChangeGroupNameHandler = (event) => {
        setState((prevState) => { return { ...prevState, groupName: event?.target?.value } });
    }

    // it sets the state of sub group name
    const onChangeSubGroupNameHandler = (event) => {
        setState((prevState) => { return { ...prevState, childGroupName: event?.target?.value } });
    }

    // it sets the connection UId state
    const onSelectConnectionHandler = (connectionUId) => {

        // let connection = JSON.parse(event.target.value);
        setState((prevState) => { return { ...prevState, connectionUId: connectionUId } });
        let sysConnectionTypeId = state?.connectionList.filter(i => i.uId === connectionUId)[0]?.sysConnectionTypeId;
        let baseConnectionTypeId = state?.connectionList.filter(i => i.uId === connectionUId)[0]?.baseConnectionTypeId;
        getCollectionTypeList(baseConnectionTypeId ? baseConnectionTypeId : sysConnectionTypeId);
    }

    const getCurrentConnectionDetails = (connectionUId) => {
        getDataSourceByUID(connectionUId).then((res) => {
            if (res?.data) {
                props.setSelectedConnection(res.data);
            }
        });
    }

    // it sets the category UId state
    // const onSelectCategoryHandler = (categoryUId) => {
    //     setState((prevState) => { return { ...prevState, categoryUId: categoryUId } });
    // }

    // it allows the collection to be treated as connection
    const onCheckCollectionAsConnection = (event) => {
        if(! event?.target?.checked){
            setIsDynamicListValues(new DynamicList());
        }
        setState((prevState) => { return { ...prevState, isCollectionAsDataSource: event?.target?.checked } });
    }

    const onSelectGroupNameHandler = (selectedGroup) => {

        if (selectedGroup) {
            setState((prevState) => { return { ...prevState, groupName: selectedGroup?.groupName } });
            setState((prevState) => { return { ...prevState, childGroupName: "" } });
            const group = groups?.find(obj => obj.groupName === selectedGroup?.groupName);
            setSubGroups(group?.subGroups);
        }
        else
            setState((prevState) => { return { ...prevState, groupName: "" } });
    }

    const onSelectSubGroupNameHandler = (selectedSubGroup) => {

        if (selectedSubGroup) {
            setState((prevState) => { return { ...prevState, childGroupName: selectedSubGroup?.subGroupName } });
        }
        else
            setState((prevState) => { return { ...prevState, childGroupName: "" } });
    }

    const handleCloseDialog = () => {
        setIsDialogOpen(false)
        setState((prevState) => { return { ...prevState, name: "", groupName:"", childGroupName:"" } });
    }

    const handleOnChnageFilterInput = (event,input) => {
        setIsDynamicListValues((prevState) => {
        return {
        ...prevState, [input] : event?.target?.value
        }
        });
    }

    const onChangeColumnEndpointApiHandler = (event) => {
        setState((prevState) => { return { ...prevState, columnApiEndpoint: event?.target?.value } });
    }

    const onToggleFilterSupported = (event) => {
        setState((prevState) => { return {
            ...prevState,
            isFilterSupported: event?.target?.checked,
            areAllFieldsFilterable: !event?.target?.checked ? false : prevState?.areAllFieldsFilterable,
        }});
    };

    const onToggleSortSupported = (event) => {
        setState((prevState) => ({
            ...prevState,
            isSortSupported: event?.target?.checked,
            areAllFieldsSortable: !event?.target.checked ? false : prevState?.areAllFieldsSortable,
        }));
    };
    
    const onTogglePagingSupported = (event) => {
        setState((prevState) => ({
            ...prevState,
            isPagingSupported: event?.target?.checked,
        }));
    };
    

    const onToggleAllFieldsFilterable = (event) => {
        setState((prevState) => {return {
            ...prevState,
            areAllFieldsFilterable: event?.target?.checked,
        }});
    };

    const onToggleAllFieldsSortable = (event) => {
        setState((prevState) => {return {
            ...prevState,
            areAllFieldsSortable: event?.target?.checked,
        }});
    };


    return (
        <>
            {
                isDialogOpen ? (
                    <BasicInformationPopup
                        state={state}
                        prevStepRef={prevStepRef}
                        onChangeDataCollectionNameHandler={onChangeDataCollectionNameHandler}
                        onChangeGroupNameHandler={onChangeGroupNameHandler}
                        onChangeSubGroupNameHandler={onChangeSubGroupNameHandler}
                        //onSelectConnectionHandler={onSelectConnectionHandler}
                        // onSelectCategoryHandler={onSelectCategoryHandler}
                        onCheckCollectionAsConnection={onCheckCollectionAsConnection}
                        groups={groups}
                        onSelectGroupNameHandler={onSelectGroupNameHandler}
                        onSelectSubGroupNameHandler={onSelectSubGroupNameHandler}
                        subGroups={subGroups}
                        open={isDialogOpen}
                        handleCloseDialog={handleCloseDialog}
                        saveCollection={saveCollection}
                        isBasicInfoSubmitted={isBasicInfoSubmitted}
                        isUpdatingCollection={isUpdatingCollection}
                        isCloudLoading={isCloudLoading}
                        onToggleAllFieldsFilterable = {onToggleAllFieldsFilterable}
                        onToggleAllFieldsSortable = {onToggleAllFieldsSortable}
                    />
                ) : (
                    <BasicInformation
                        state={state}
                        prevStepRef={prevStepRef}
                        onChangeDataCollectionNameHandler={onChangeDataCollectionNameHandler}
                        onChangeGroupNameHandler={onChangeGroupNameHandler}
                        onChangeSubGroupNameHandler={onChangeSubGroupNameHandler}
                        onSelectConnectionHandler={onSelectConnectionHandler}
                        onChangeSourceNamePart2NameHandler={onChangeSourceNamePart2NameHandler}
                        // onSelectCategoryHandler={onSelectCategoryHandler}
                        onCheckCollectionAsConnection={onCheckCollectionAsConnection}
                        groups={groups}
                        onSelectGroupNameHandler={onSelectGroupNameHandler}
                        onSelectSubGroupNameHandler={onSelectSubGroupNameHandler}
                        subGroups={subGroups}
                        isDynamicListValues={isDynamicListValues}
                        handleOnChnageFilterInput={handleOnChnageFilterInput}
                        isVariableUsedInCollection={isVariableUsedInCollection}
                        onChangeColumnEndpointApiHandler = {onChangeColumnEndpointApiHandler}
                        onToggleFilterSupported = {onToggleFilterSupported}
                        onToggleSortSupported = {onToggleSortSupported}
                        onTogglePagingSupported = {onTogglePagingSupported}
                        onToggleAllFieldsFilterable = {onToggleAllFieldsFilterable}
                        onToggleAllFieldsSortable = {onToggleAllFieldsSortable}
                        isTemplateCompany = {isTemplateCompany}
                        isCloudStep={props?.isCloudStep}
                        selectedConnection={selectedConnection}
                        isTestedAfterSaveAppears={isTestedAfterSaveAppears}
                    />
                )
            }
        </>
    );
}

export default BasicInformationController;
