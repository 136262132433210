import Dialog from "@mui/material/Dialog";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ASTextField } from "../../../../shared/as-text-fields";
import { Typography } from "@mui/material";
import "./proxy-secret.scss";
import { ASButton } from "../../../../shared/as-button/as-button";

const ProxySecretComponent = (props) => {
    const { open, onClose, secretExists, regenerateSecret, secret, secretCreatedDuration, handleCancel, handleOk, copySecret, passRef } = props;
    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: "10px",width :'550px',height: "300px"} }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1299'}}>
        
            <DialogTitle className="proxy-title" sx={{paddingBottom : '0px'}}>{secretExists ? "Regenerate Proxy Secret" : "Generate Proxy Secret"}</DialogTitle>
            <DialogContent sx={{ paddingRight: '35px', height: "180px", paddingLeft: '35px'}}>
            
                <div className="d-flex align-items-center">
                    <ASTextField
                        className="text-field"
                        type={"text"}
                        fullWidth
                        label=""
                        variant="outlined"
                        value={(!secretExists || regenerateSecret) ? secret : secretCreatedDuration}
                        disabled={false}
                        inputRef={passRef}
                    />

                    {
                        (!secretExists || regenerateSecret) && <ASButton sx={{height:'55px',textTransform:'none'}} primary={true} onClick={()=>{copySecret()}}>
                                                                    Copy
                                                                </ASButton>
                    }                    

                </div>
                
                <div className="tally-link-div">
                    <Typography className="tally-link"
                        sx={{cursor : "pointer"}}
                        onClick={()=>{}}>
                        {"Download Proxy Connector"}
                    </Typography> 

                    <div className='line-btw-tally-link'></div>

                    <Typography className="tally-link"
                        sx={{cursor : "pointer"}}
                        onClick={()=>{}}>
                        {"Video : How to Configure"}
                    </Typography>
                </div>

                <div className="buttons">
                    <div className="proxy-dialog-cancel-button">
                    <ASButton primary={false} onClick={()=>{handleCancel()}}>
                        Cancel
                    </ASButton>
                    </div>
                    <div  className="proxy-dialog-ok-button">
                        <ASButton primary={true} onClick={()=>{handleOk()}}>
                        {secretExists ? "Regenerate"  : "Generate"}
                        </ASButton>
                    </div>
                </div>
            </DialogContent>
            
        </Dialog>
    );
}
export default ProxySecretComponent;