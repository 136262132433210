import React, { useEffect, useState } from "react";
import DataTransformationScript from "../../../../components/data-collections/data-transformation-script/data-transformation-script";


const defaultValue = "function dataTransformation(rows) {\n  //write your own logic...\n  return rows;\n}"

const DataTransformationScriptController = (props) => {

    const { state, setState, prevStepRef, showSaveChangesButton} = props;

    useEffect(() => {
        if(state.restDataTransformationScript == null){
            setState((prevState) => { return { ...prevState, restDataTransformationScript : defaultValue } });
        }
    },[])

    const handleEditor = (e) => {
        setState((prevState) => { return { ...prevState, restDataTransformationScript : e } });
        showSaveChangesButton(false);
    }

    return (
        <DataTransformationScript state={state}
                                  prevStepRef={prevStepRef}
                                  handleEditor={handleEditor}/>
    );
}

export default DataTransformationScriptController;
