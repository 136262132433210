import { styled } from "@mui/material/styles";
import { Tabs, Tab } from "@mui/material";
import themeColor from "./../../styles/_exports.module.scss";

export const ASTabs = styled(Tabs)({
    borderBottom: themeColor.borderBottom,
    "& .MuiTabs-indicator": {
        backgroundColor: themeColor.primaryColor,
    },
});

export const ASTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        color: themeColor.text_2,
        "&:hover": {
            color: themeColor.primaryColor,
            opacity: 1,
        },
        "&.Mui-selected": {
            color: themeColor.primaryColor,
            fontWeight: theme.typography.fontWeightMedium,
        },
        "&.Mui-focusVisible": {
            backgroundColor: themeColor.primaryColor,
        },
        "& .MuiTabs-indicatorSpan": {
            maxWidth: 40,
            width: "100%",
            backgroundColor: themeColor.primaryColor,
        },
    })
);
