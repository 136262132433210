import axios from 'axios';
import { Environment } from './environment';
import SpinnerContext from '../controllers/common/spinner-context/spinner-context';
import { getErrorMessageOrStatusMessage } from './api-error-status-code';
import jwtDecode from 'jwt-decode';
import { Navigate } from 'react-router';
import { removeItem, removeToken, setToken } from '../shared/local-storage-handler/local-storage-handler';
import { refreshToken } from '../services/refresh-token.service';

const url = Environment.getUrl();
let isRefreshing = false;
let refreshTokenPromise = null;

const axiosInstance = axios.create({
    baseURL: url,
    timeout: 180000,
    headers: {
        'Accept': 'text/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});

const validateToken = (token) => {
    try {
        const decodedToken = jwtDecode(token);

        // Check if the token has an expiration date
        if (decodedToken && decodedToken.exp) {
            // Get the current time in seconds
            const currentTime = Math.floor(Date.now() / 1000);

            // Compare the expiration time with the current time
            const expired= decodedToken.exp-10 < currentTime;            
            return expired;
        }
        
        return false; // No expiration information in the token
    } catch (error) {
        // Handle decoding errors
        console.error('Error decoding token:', error);
        return true; // Treat decoding errors as expired
    }
};

export const refreshTokenHandler = async (url="") => {
    const refreshTokenResult = await refreshToken();
        try {
            if (!refreshTokenResult) {
                removeItem("token");
                removeItem("refresh_token");
                <Navigate to="/login" replace />;
                return false;
            }
            else {              
                setToken("token", refreshTokenResult?.access_token);
                setToken("refresh_token", refreshTokenResult?.refresh_token);
                return true;
            }
        } catch (error) {            
            removeItem("token");
            removeItem("refresh_token");
            <Navigate to="/login" replace />;
        }
    return false;
};
let accessToken="";
async function sleep(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
}

axiosInstance.defaults.headers.common['Authorization'] = '';
axiosInstance.defaults.headers.common['ASTenantId'] = '';

axiosInstance.interceptors.request.use(async function (config) {

    // show spinner if any http request is pending
    SpinnerContext.asContext(true);    
    accessToken = localStorage.getItem('token');

    if (accessToken && validateToken(accessToken)) {
        if (isRefreshing === false) {
            isRefreshing = true;
             await refreshTokenHandler(config.url);             
            accessToken = localStorage.getItem('token');
            isRefreshing = false;
        } else {
            console.log("Another network request");
            let x=0;

            while(x<=15 && isRefreshing)
            {            
                await sleep(1000);
            }
        }
    }
    
    
    if (accessToken) {
        config.headers.Authorization = accessToken ? `bearer ${accessToken}` : '';

        const aSTenantId = sessionStorage.getItem('ASTenantId') || localStorage.getItem('ASTenantId');
        // const aSTenantId = localStorage.getItem('ASTenantId');
        const decodedToken = jwtDecode(accessToken);
        config.headers.ASTenantId = aSTenantId ? aSTenantId : decodedToken?.ASTenantUId;
    }
    return config;

}, function (error) {
    // Do something with request error
    return Promise.reject(error);

});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {

    setTimeout(() => {
        SpinnerContext.asContext(false);
    }, 200)
    return response;
},
    function (error) {

        setTimeout(() => {
            SpinnerContext.asContext(false);
        }, 200)

        if (error?.response?.status === 401) {
            removeItem("token");
            removeItem("refresh_token");
            <Navigate to="/login" replace />;
        }

        if (error) {
            let errorMessage = getErrorMessageOrStatusMessage(error);
            return errorMessage;
        }
        return Promise.reject(error);  
    });

export { axiosInstance as axios };