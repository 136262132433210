import { Button } from "@mui/material";
import "./as-button.scss";

export function ASButton({
    onClick,
    variant,
    children,
    disabled = false,
    sx,
    rounded,
    primary,
    buttonType = undefined,
}) {
    let classNames = "";
    if (rounded) classNames += " button-rounded";
    if (primary) classNames += " button-primary";
    else if (variant !== "text") classNames += "button-secondary";

    if (disabled) classNames = "";
    return (
        <Button
            disabled={disabled}
            className={buttonType || classNames}
            variant={variant}
            onClick={onClick}
            sx={sx}
        >
            {children}
        </Button>
    );
}
