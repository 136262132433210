import {
  List,
  ListItem,
  ListItemIcon,
  Collapse,
  IconButton
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Menu as MenuIcon
} from "@mui/icons-material";
import "./excel-connection.scss";
import { ASheetCompleteLogo } from '../../../constants/common-svgs';
import { ASButton } from "../../../shared/as-button/as-button";
import { Link } from "react-router-dom";
import ConnectionScreenExcel from "../connection-screen-excel/connection-screen-excel";

export function ExcelConnection({
    usedCloudApplicationsId,
    sourceType = [],
    handleListItem,
    handleConnectButtonClick,
    handleConnectionClick,
    showWelcome,
    openCloud,
    openNatural,
    selectedConnection,
    onCancel,
    testConnection,
    isConnected,
    isConnecting,
    disconnectConnection 
}) {

    const cloudSources = sourceType.filter(card => card.templateConnectionId !== null);
    const naturalSources = sourceType.filter(card => card.templateConnectionId === null);

    return (
        <>
        <div className="app-container-excel" style={{ backgroundColor: "white" }}>
            {showWelcome ? (
                <div className="centered-content">
                        <div component="div" className="no-connection-message" style={{'fontSize' : "12.5px", 'color' : '#16365F', 'marginTop' : '80px'}}>
                            <div className="no-connection-message-2" style={{'fontSize' : "15px", 'color' : 'black'}}>
                                Welcome to <span className="asheet-logo-welcome-excel"> {ASheetCompleteLogo}</span> 
                            </div>
                            
                            <div>There is no connection made yet.</div>
                            
                            <div className="excel-connect-link">
                                {"Please"}
                                <span
                                className="excel-connect-link"
                                onClick={handleConnectButtonClick}
                                style={{color: "#4D53E0", cursor: "pointer", margin: '0px 5px'}}
                                >
                                connect
                                </span>
                               {"to your favorite data source"}
                                
                            </div>
                            <br/>
                            <ASButton className="excel-connect-button" sx={{padding: "4px 8px", borderRadius: "10px"}}
                            onClick={handleConnectButtonClick}
                            primary={true}
                            >
                            <Link className="add-new-link excel-connect-button-text">
                                Connect to Your Data Source
                            </Link>
                        </ASButton>      
                        </div>
                    </div>
                ) : ((!selectedConnection ? 
                    <div className= {`sidebar open`} >
                        
                        <div className="sidebar-label">
                            Connect to your data source
                        </div>
                        <List>
                            {cloudSources.length > 0 && (
                                <>
                                    <ListItem onClick={() => handleListItem("cloud")} sx={{cursor:"pointer", paddingBottom: "0px"}}>
                                        <ListItemIcon>
                                        <IconButton
                                                style={{
                                                    transform: openCloud ? 'rotate(90deg) ScaleY(0.8)' : 'rotate(180deg) ScaleX(0.9)',
                                                    marginLeft: '-35px',
                                                    color: '#16365F',
                                                }}
                                            >
                                                {openCloud ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        </ListItemIcon>
                                        <div className="excel-connection-type" >
                                                Cloud Native Sources
                                        </div>
                                    </ListItem>
                                    <Collapse in={openCloud} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding style={{marginTop: '-8px', marginBottom: '-19px'}}>
                                            {cloudSources.map((item, index) => (
                                                <ListItem button disabled={usedCloudApplicationsId.has(item?.templateConnectionId)}
                                                key={index} onClick={() => handleConnectionClick(item)} style={{marginBottom: '-5px'}}>
                                                    <ListItemIcon>
                                                        <img className="excel-connection-image" src={`/assets/${item.iconImage}`} alt={item.iconImage} style={{ width: 23, height: 23, marginLeft: '3px'}} />
                                                    </ListItemIcon>
                                                    <div className="excel-connection-description"style={{marginLeft: '-25px'}}>
                                                        {item.description}
                                                    </div>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </>
                            )}
                            {naturalSources.length > 0 && (
                                <>
                                    <ListItem onClick={() => handleListItem("natural")} sx={{cursor:"pointer",paddingBottom: "0px"}}>
                                        <ListItemIcon>
                                            <IconButton
                                                style={{
                                                    transform: openNatural ? 'rotate(90deg) ScaleY(0.8)' : 'rotate(180deg) ScaleX(0.9)',
                                                    marginLeft: '-35px',
                                                    color: '#16365F',
                                                }}
                                            >
                                                {openNatural ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        </ListItemIcon>
                                        <div className="excel-connection-type">
                                                Natural Sources
                                        </div>
                                    </ListItem>
                                    <Collapse in={openNatural} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding style={{marginTop: '-8px'}}>
                                            {naturalSources.map((item, index) => (
                                                <ListItem button key={index} onClick={() => handleConnectionClick(item)} style={{marginBottom: '-5px'}}>
                                                    <ListItemIcon >
                                                        <img className="excel-connection-image" src={`/assets/${item.iconImage}`} alt={item.iconImage} style={{ width: 23, height: 23, marginLeft: '3px'}} />
                                                    </ListItemIcon>
                                                    <div className="excel-connection-description" style={{marginLeft: '-25px'}}>
                                                        {item.description}
                                                    </div>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </>
                            )}
                        </List>
                    </div>
                    : 
                    <div>
                    <ConnectionScreenExcel
                        disconnectConnection={disconnectConnection}
                        isConnected={isConnected}
                        isConnecting={isConnecting}
                        testConnection={testConnection}
                        onCancel={onCancel}
                        selectedConnection ={selectedConnection}
                        />
                </div>)
                )
            }
        </div>        
        </>
    );
}


