import { HashRouter, useNavigate } from "react-router-dom";
import "./App.scss";
import {
  ASContextProvider,
  useASContext,
} from "./controllers/context-api/as-context";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CustomNavigator from "./controllers/common/custom-navigator/custom-navigator";
import AppRouting from "./app-routing";
import SpinnerContext from "./controllers/common/spinner-context/spinner-context";
import "./styles/common-styles.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
ModuleRegistry.registerModules([ ClientSideRowModelModule ]);


// global theme css for material ui elements( caution: will effect all the mui elements in the whole app)
const muiTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Poppins",
    },
  },
});

// sets the navigation history object for custom routing
const CustomNavigatorSetter = () => {
  CustomNavigator.navigateHistory = useNavigate();
  return null;
};

// sets the spinner context state manually inside the axios interceptor
const SpinnerContextSetter = () => {
  const asContext = useASContext();
  SpinnerContext.contextHistory = asContext;
  return null;
};

function App() {
  return (
    <div className="app-container">
      <ASContextProvider>
        <ThemeProvider theme={muiTheme}>
          <CustomNavigatorSetter />
          <SpinnerContextSetter />
          <AppRouting />
          <ToastContainer/>
        </ThemeProvider>
      </ASContextProvider>
    </div>
  );
}

export default App;
