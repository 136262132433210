import axios from 'axios';
import { Environment } from './environment';
import SpinnerContext from '../controllers/common/spinner-context/spinner-context';
import { getErrorMessageOrStatusMessage } from './api-error-status-code';

const url = Environment.getTokenAPIUrl();

const axiosInstance = axios.create({
    baseURL: url,
    timeout: 180000,
    headers: {
        'Accept': 'text/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});

axiosInstance.defaults.headers.common['Authorization'] = '';

axiosInstance.interceptors.request.use(function (config) {

    // show spinner if any http request is pending
    SpinnerContext.asContext(true);

    const accessToken = localStorage.getItem('token');
    if (accessToken) {
        config.headers.Authorization = accessToken ? `bearer ${accessToken}` : '';
    }
    return config;

}, function (error) {
    // Do something with request error
    return Promise.reject(error);

});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {

    SpinnerContext.asContext(false);
    return response;
},
    function (error) {

        SpinnerContext.asContext(false);

        if (error) {
            let errorMessage = getErrorMessageOrStatusMessage(error);
            return errorMessage;
        }
        return Promise.reject(error);
    });

export { axiosInstance as tokenAxios };
