// src/ConnectedSources.js
import React, { useEffect, useState } from 'react';
import _ from "lodash";
import './plugin.scss';
// import './plugin-home.scss';
import { Button, CircularProgress, IconButton, Typography } from '@mui/material';
import PluginConnections from '../plugin-collections/plugin-collections.js';
import { ASheetCompleteLogoSvg } from '../../../constants/common-svgs';
import { HiMenuAlt1 } from 'react-icons/hi';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { truncateString } from '../../../shared/code-challanges/code-challanges';

const PluginHome = (props) => {

  const {selectedCollection,setSelectedConnection,dataCollections,activeTab,collectionRenderList,onChangeTabs,connectionList,handleBack} = props;

  return (
    <div className="gsheetContainer">
      {!selectedCollection && <PluginHeader Icon={selectedCollection ? ArrowBackIcon : HiMenuAlt1} onClickIcon={() => { setSelectedConnection(null) }} />}
      {!selectedCollection ?

        dataCollections?.length ?

          <div>
            <div className="tabs">
              <div
                className={`tab ${activeTab === 'connection' ? 'active' : ''}`}
                onClick={() => onChangeTabs('connection')}
              >
                By Connections
              </div>
              <div
                className={`tab ${activeTab === 'groupName' ? 'active' : ''}`}
                onClick={() => onChangeTabs('groupName')}
              >
                By Groups Name
              </div>
              <div className='connect-new-btn'>
                Connect New
              </div>
            </div>

            <div className="content">

              {activeTab === 'connection' && collectionRenderList(connectionList, 'connection')}
              {activeTab === 'groupName' && collectionRenderList(connectionList, 'groupName')}

            </div>
          </div>

          :
          (<CircularProgress
            className='circularLoader'
            size={24} />)
        :

        <PluginConnections
          back={handleBack}
          dataCollections={dataCollections}
          selectedCollection={selectedCollection}
          activeTab={activeTab}
        />
      }


    </div>
    );
};

export default PluginHome;


export const PluginHeader = ({ Icon, onClickIcon }) => {

  return (
    <>
      <div className='plugin-header'>
        <IconButton edge="start" color="inherit" aria-label="open drawer" sx={{ fontSize: '1.8rem' }}>
          <Icon style={{ 'color': 'black', }} onClick={onClickIcon} />
        </IconButton>

        {ASheetCompleteLogoSvg}
      </div>

    </>
  )

}
