import * as collectionService from '../../../services/data-collection.service';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Parameters } from "../../../components/asheetPlugin/search-parameters/parameters";
import { testData } from '../../../services/test-data.service';
import { AppEnum } from '../../../constants/app-enum';

export const SearchParameterController = ({ collection, index, dataCollection, setDatacollection, setSearchOptionsParameters, searchOptionsParameters, isPopup }) => {

    const complexTypeValue = JSON.parse(collection?.complexTypeValue);
    const [dynamicList, setDynamicList] = useState();
    const [multiSelectedFields, setMultiSelectedFields] = useState([]);
    const [optionValues, setOptionValues] = useState([]);
    const dateString = collection?.isSystemDefineVariable ? collection?.parameterValue : collection?.defaultValue;
    const dateObject = new Date(dateString);


    useEffect(() => {
        if (collection?.sysDataTypeId == AppEnum.DataTypeId.DynamicList) {
            getCollectionDetail(complexTypeValue);
        }
    }, [collection])

    useEffect(() => {
        if (collection.isMultiSelectList && complexTypeValue && collection?.sysDataTypeId === AppEnum.DataTypeId.List) {

            let options = complexTypeValue.map(item => ({
                label: item.Label,
                value: item.Value,
                default: item.Default
            }));
            const paramWithDefaultValue  = options?.filter((i) => i.default);
            handleMultiSelectParameterValues(options, paramWithDefaultValue);
        }
    }, [])

    const getCollectionDetail = async (parsedComplexTypeValue) => {
        try {
            const response = await collectionService.getCollectionDetails(parsedComplexTypeValue?.DataCollectionUId);
            if (response?.hasError) {
                toast.error(response?.errorMessage);
            }
            else if (response?.data) {
                let testDataCollectionModal = {
                    connectionUId: response?.data?.connectionUId,
                    sysCollectionTypeId: response?.data?.sysCollectionTypeId,
                    sourceName: response?.data?.sourceName,
                    restRequestMethod: response?.data?.restRequestMethod,
                    restRequestIsAsync: response?.data?.restRequestIsAsync,
                    restBody: response?.data?.restBody,
                    restSendAsCD: response?.data?.restSendAsCD,
                    collectionParameters: response?.data?.collectionParameters,
                    collectionColumns: response?.data?.collectionColumns,
                    uId: response?.data?.uId,
                    returnFlatternData: response?.data?.sysCollectionTypeId === 7 ? true : false,
                    sourceNamePart4: response?.data?.sourceNamePart4,
                    IsASTenantUIdApplicable: false,
                    restDataTransformationScript: response?.data?.restDataTransformationScript
                }
                return getDynamicList(testDataCollectionModal, parsedComplexTypeValue);
            }
        } catch (error) {

        }
    }

    const getDynamicList = async (model, parsedComplexTypeValue) => {
        try {
            testData(model, null, getDynamicListOptions, parsedComplexTypeValue);
        } catch (error) {
            toast.error(error);
        }
    }

    const getDynamicListOptions = (data, parsedComplexTypeValue) => {
        const displayColumn = parsedComplexTypeValue?.DisplayColumn;
        const valueColumn = parsedComplexTypeValue?.ValueColumn;
        let options = [];

        if (!displayColumn || !valueColumn) {
            return;
        }

        if (data?.length === undefined) {
            var displayColumnList = data[displayColumn]?.filter(value => value !== null)
            var valueColumnList = data[valueColumn]?.filter(value => value !== null)

            options = displayColumnList.map((label, index) => ({
                label,
                value: valueColumnList[index]
            }));
        }
        else if (data?.length) {
            options = data?.map(item => ({
                label: item[displayColumn],
                value: item[valueColumn]
            }));
        }

        setDynamicList(options);

        if (collection.isMultiSelectList) {
            const paramWithDefaultValue = options?.filter((i) => i.value == complexTypeValue?.DefaultValue);
            handleMultiSelectParameterValues(options, paramWithDefaultValue);
        }
    };
    const handleOnInputChange = (e, index, isComplexTypeValue, isDynamicList) => {

        let value = e?.target?.value;

        let prevParams = isPopup ? [...searchOptionsParameters] : [...dataCollection?.collectionParameters];

        if (index >= 0 && index < prevParams?.length) {

            if (isComplexTypeValue) {
                let complexTypeValue = JSON.parse(prevParams[index]?.complexTypeValue);
                let complexParamValue = null;

                if (!isDynamicList) {
                    complexParamValue = complexTypeValue?.find(i => i.Label == value)?.Value;
                }

                prevParams[index].parameterValue = isDynamicList ? value : complexParamValue;
            }
            else {
                prevParams[index].parameterValue = value;
            }
        }

        if (isPopup) {
            setSearchOptionsParameters(prevParams);
        }
        else {
            setDatacollection((prevState) => { return { ...prevState, collectionParameters: prevParams } });
        }
    }

    const handleMultiSelectedFields = (e, value, index, isDefaultValue) => {

        if (!isDefaultValue) {
            setMultiSelectedFields(value);
        }
        let prevParams = isPopup ? [...searchOptionsParameters] : [...dataCollection?.collectionParameters];

        if (value && value?.length > 0) {
            const values = value?.map(item => item.value);

            if (index >= 0 && index < prevParams?.length) {
                prevParams[index].parameterValue = JSON.stringify(values);;
            }
        }
        else {
            if (index >= 0 && index < prevParams?.length) {
                prevParams[index].parameterValue = "";
            }
        }
        if (isPopup) {
            setSearchOptionsParameters(prevParams);
        }
        else {
            setDatacollection((prevState) => { return { ...prevState, collectionParameters: prevParams } });
        }
    }

    const handleMultiSelectParameterValues = (options, paramWithDefaultValue) => {
        let paramValues = [];
        if (collection?.parameterValue) {
            try {
                // Attempt to parse the parameterValue
                paramValues = JSON.parse(collection.parameterValue);
        
                // If parsing succeeds, ensure it is an array
                if (!Array.isArray(paramValues)) {
                    paramValues = [collection.parameterValue];
                }
            } catch (e) {
                // If parsing fails, treat it as a single value in an array
                paramValues = [collection.parameterValue];
            }
        }
        setOptionValues(options);

        const containsOnlyDefault = paramValues.length > 0 &&
                                    paramValues?.every(value =>
                                        paramWithDefaultValue?.some(option => option.value === value)
                                    );

        if (containsOnlyDefault) {
            setMultiSelectedFields(paramWithDefaultValue);
            handleMultiSelectedFields(null, paramWithDefaultValue, index, true)
        }
        else {
            const selectedOptions = options?.filter(option => paramValues?.includes(option.value))
                                .sort((a, b) => paramValues.indexOf(a.value) - paramValues.indexOf(b.value));
            handleMultiSelectedFields(null, selectedOptions, index, false);
        }
    }

    return (
        <Parameters
            collection={collection}
            index={index}
            dateObject={dateObject}
            complexTypeValue={complexTypeValue}
            dynamicList={dynamicList}
            handleOnInputChange={handleOnInputChange}
            handleMultiSelectedFields={handleMultiSelectedFields}
            multiSelectedFields={multiSelectedFields}
            optionValues={optionValues} />
    )
}