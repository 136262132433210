import React, { useMemo } from 'react'
import "./UpdateSubscriptionHeader.scss"
import moment from 'moment';
import { AppEnum } from '../../../constants/app-enum';

export function UpdateSubscriptionHeader(props) {
    const { boughtPlanDetails } = props;

    const isSubscriptionTypeFree = useMemo(() => {
        return boughtPlanDetails?.licenseTypeUId?.toUpperCase() === AppEnum?.LicenseTypeUIds?.FREE.toUpperCase();
    }, [boughtPlanDetails?.licenseTypeUId]);

    return (
            <div className='header-part'>
                <div className={`plan-description ${(isSubscriptionTypeFree) ? 'free-subscription' : ''}`}>
                    <div className='current-plan-label'>Current Plan</div>
                    <div className='content-in-line'>
                        <div className='bold-plan-name'>{boughtPlanDetails?.planName}</div>
                        <div className='content-in-line'>
                            <div>USD</div>
                            <div className='bold-price-name'>{boughtPlanDetails?.amount}</div>
                        </div>
                    </div>
                    <div className='content-in-line'>
                        <div className='content-in-line'>
                            <div className='bold-user-number'>{boughtPlanDetails?.noOfUsers}</div>
                            <div>users</div>
                        </div>
                        <div className='peryear-text'>(per year)</div>
                    </div>
                    {boughtPlanDetails?.licenseTypeUId?.length > 0 && !isSubscriptionTypeFree && (
                        <div className='subscription-date-range'>{moment(boughtPlanDetails?.startDate).format("DD-MMM-YYYY") + " To " + moment(boughtPlanDetails?.endDate).format("DD-MMM-YYYY")}</div>
                    )}
                </div>
                <div className='verticleLine'></div>
                <div className={`title-part ${(isSubscriptionTypeFree) ? 'free-subscription' : ''}`}>
                    <div className='upgrade-title'>Upgrade or Change Your Plan</div>
                    <div>Your plan will be upgraded immediately after making the payment</div>
                    {boughtPlanDetails?.licenseTypeUId?.length > 0 && !isSubscriptionTypeFree && (
                        <div className='content-in-line-without-jc'>
                            <div>Next Payment due on </div>
                            <div className='bold-price-name'>{moment(boughtPlanDetails?.endDate).format('DD-MMM-YYYY')}</div>
                        </div>
                    )}
                </div>
            </div>
    );
}
