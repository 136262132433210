import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import styles from "./../styles/_exports.module.scss";

export function ASRadioGroup({ radioInputs, onChange}) {

    return (
        <RadioGroup
            row
            aria-label="position"
            name={radioInputs.name}
            value={radioInputs.defaultValue}
            onChange={onChange}
        >
            {radioInputs.radioButtons.map((radio, index) => {
                return (
                    <FormControlLabel
                        // onChange={radio.onChange}
                        checked={radioInputs.defaultValue === radio.value}
                        value={radio.value}
                        key={Date.now() + index}
                        sx={{
                            color:
                                (radioInputs.defaultValue === radio.value &&
                                    radioInputs.checkedLabelColor) ||
                                radioInputs.labelColor ||
                                "",
                        }}
                        control={
                            <Radio
                                sx={{
                                    // color: pink[800],
                                    "&.Mui-checked": {
                                        color: styles.primaryColor,
                                    },
                                }}
                            />
                        }
                        label={radio.label}
                    />
                );
            })}
        </RadioGroup>
    );
}
