import React, { useEffect, useState } from 'react'
import DateFilter, { timeCondition } from '../../../../components/data-collections/column-selection-popup/filterPopup/date-filter/date-filter';
import { getDateRange, getFiltrableUTCTime } from './date-filter-helper';

export default function DateFilterController(props) {

    const { filterObject, setFilterObject } = props;
    const [dateFilterObject, setDatefilterObject] = useState({
        subDateLabel: "",
        daysCount: 0,
        dateDuration: "",
        exactDate: null,
        exactFromDate: null
    });

    useEffect(() => {
        if ((dateFilterObject.subDateLabel == "NEXT" || dateFilterObject.subDateLabel == "LAST") && dateFilterObject.daysCount != 0 && dateFilterObject.dateDuration) {
            const dateLabelValue = `${dateFilterObject.dateDuration} ${dateFilterObject.daysCount} ${dateFilterObject.subDateLabel}`;
            const dateRange = getDateRange(dateLabelValue, filterObject?.conditionLabel);
            if (dateRange?.startDate != null) {
                setFilterObjectHandler(dateRange?.startDate, "value");
            }
            if (dateRange?.endDate != null) {
                setFilterObjectHandler(dateRange?.endDate, "highValue");
            }
        }

            let dateFormatedLabel = getFormattedDuration(dateFilterObject);
            if (dateFormatedLabel){
                setFilterObjectHandler(dateFormatedLabel, "dateLabel");
                if ((dateFilterObject.subDateLabel == "NEXT" || dateFilterObject.subDateLabel == "LAST")){
                    setFilterObjectHandler(dateFormatedLabel?.toUpperCase(), "dateLabelValue");
                }
                else {
                    setFilterObjectHandler(dateFilterObject.subDateLabel?.toUpperCase(), "dateLabelValue");
                } 
            }
            if (dateFilterObject?.subDateLabel == "EXACT_DATE" || dateFilterObject?.subDateLabel == "EXACT_DATES"){
                setFilterObjectHandler(dateFilterObject.subDateLabel?.toUpperCase(), "dateLabelValue");

                const isoFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
                if (isoFormat.test(filterObject?.value)) {
                    const date = new Date(filterObject?.value);

                    const year = date.getUTCFullYear();
                    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
                    const day = String(date.getUTCDate()).padStart(2, '0');
            
                    const formattedDate = `${year}-${month}-${day}`;
                    setFilterObjectHandler(formattedDate, "dateLabel");
                }
                else {
                    setFilterObjectHandler("", "dateLabel");
                }
            }

    }, [dateFilterObject.subDateLabel, dateFilterObject.daysCount, dateFilterObject.dateDuration]);

    useEffect(() => {
        setDatefilterObject({
            subDateLabel: "",
            daysCount: 0,
            dateDuration: "",
            exactDate: null,
            exactFromDate: null
        });

    }, [filterObject?.field]);

    useEffect(() => {

        if (filterObject?.dateLabelValue){

            let subLabel = filterObject?.dateLabelValue;
            let duration = "";
            let daysCounts = 0;
            let direction = "";
            let exactDateValue = null;
            let exactFromDateValue = null;

            if (subLabel.includes(" ")) {
                const splitString = subLabel.split(" ");
                if (splitString.length === 3) {
                    direction = splitString[0];
                    daysCounts = splitString[1];
                    duration = splitString[2];
                }
            }

            if (filterObject?.dateLabelValue == "EXACT_DATE" || filterObject?.dateLabelValue == "EXACT_DATES"){
                 exactDateValue = filterObject?.value ? filterObject?.value?.split('T')[0] : null;
                 exactFromDateValue = filterObject?.highValue ? filterObject?.highValue?.split('T')[0] : null;
            }

            setDatefilterObject({
                subDateLabel: direction ? direction : subLabel,
                daysCount: daysCounts,
                dateDuration: duration,
                exactDate: exactDateValue,
                exactFromDate: exactFromDateValue
            });
        }
    }, []);

    useEffect(() => {
        if ((filterObject?.value || filterObject?.highValue) && filterObject?.conditionLabel){
            let updatedDates = null;
            if (filterObject?.dateLabelValue == "EXACT_DATES" || filterObject?.dateLabelValue == "EXACT_DATE"){
                updatedDates = getFiltrableUTCTime(filterObject?.value, filterObject?.highValue, filterObject?.conditionLabel, false);
                if (filterObject?.condition == "BETWEEN"){
                    if (updatedDates && updatedDates?.date != null) {
                        updatedDates?.date?.setUTCHours(0, 0, 0, 0);
                    }
                    if (updatedDates && updatedDates?.endDate != null) {
                        updatedDates?.endDate?.setUTCHours(23, 59, 59, 999);
                    }
                }
            }
            else {
                updatedDates = getFiltrableUTCTime(filterObject?.value, filterObject?.highValue, filterObject?.conditionLabel, true);
            }

            if (updatedDates) {
                if (updatedDates?.date != null) {
                    let updatedStartDate = updatedDates?.date?.toISOString();
                    setFilterObjectHandler(updatedStartDate, "value");
                }
                if (updatedDates?.endDate != null) {
                    let updatedEndDate = updatedDates?.endDate?.toISOString();
                    setFilterObjectHandler(updatedEndDate, "highValue");
                }
            }
        }

    }, [filterObject?.condition]);

    const handleOnChangeDateInput = (event, input) => {

        let dateFilterInput = input == "value" ? "exactDate" : "exactFromDate"
        setDatefilterObject((prevState) => {
            return {
                ...prevState, [dateFilterInput]: event?.target?.value
            }
        });
        if (input == "value"){
            setFilterObjectHandler(event?.target?.value, "dateLabel");
        }
        let value = convertToISO8601(event?.target?.value, input);
        setFilterObject((prevState) => {
            return {
                ...prevState, [input]: value
            }
        });
    }

    const setFilterObjectHandler = (value, input) => {
        setFilterObject((prevState) => {
            return {
                ...prevState, [input]: value
            }
        });
    }

    const getDateByDateFilters = (event, input) => {

        setDatefilterObject((prevState) => {
            return {
                ...prevState, [input]: event?.target?.value
            }
        });

        if (input == "subDateLabel" && (event?.target?.value != "NEXT" && event?.target?.value != "LAST" && (event?.target?.value != "EXACT_DATE" || event?.target?.value != "EXACT_DATES"))) {
            const dateRange = getDateRange(event?.target?.value, filterObject?.conditionLabel);
            if (dateRange?.startDate != null) {
                setFilterObjectHandler(dateRange?.startDate, "value");
            }
            if (dateRange?.endDate != null) {
                setFilterObjectHandler(dateRange?.endDate, "highValue");
            }

            setDatefilterObject((prevState) => ({
                ...prevState,
                daysCount: 0,
                dateDuration: "",
                exactDate: null,
                exactFromDate: null
            }));
        }
    }

    function getFormattedDuration(data) {
        let { subDateLabel, daysCount, dateDuration } = data;

        if (subDateLabel == "EXACT_DATE" || subDateLabel == "EXACT_DATES"){
            return;
        }

        if (subDateLabel && subDateLabel.includes('_')) {
              subDateLabel = subDateLabel.replace(/_/g, ' ');
          }

        if (subDateLabel && !daysCount && !dateDuration) {
            return `${subDateLabel?.charAt(0).toUpperCase() + subDateLabel?.slice(1).toLowerCase()}`;
        }
    
        if (subDateLabel && daysCount && dateDuration) {
            return `${subDateLabel?.charAt(0).toUpperCase() + subDateLabel?.slice(1).toLowerCase()} ${daysCount} ${dateDuration?.toLowerCase()}`;
        }
    
        return '';
    }


    const convertToISO8601 = (dateStr, input) => {
        const [year, month, day] = dateStr.split('-');

        const date = new Date(Date.UTC(year, month - 1, day));

        let updatedTime = getFiltrableUTCTime(date, null, filterObject?.conditionLabel, false);
        if (!updatedTime || input == "highValue"){
            date.setUTCHours(23, 59, 59, 999);
        }
        else{
            if (updatedTime?.date != null) {
                let updatedStartDate = updatedTime?.date?.toISOString();
                return updatedStartDate;
            }
        }
        return date.toISOString();
    };

    return (
        <DateFilter
            filterObject={filterObject}
            handleOnChangeDateInput={handleOnChangeDateInput}
            getDateByDateFilters={getDateByDateFilters}
            dateFilterObject={dateFilterObject}
        />
    )
}
