import { useState } from 'react';
import { Box } from "@mui/material";
import { AsheetLogoExcelSvg } from '../../../constants/common-svgs';
import "./connecting-stage-excel.scss";
import { ASButton } from '../../../shared/as-button/as-button';
import SuccessfulConnectionExcel from '../successfull-connection-excel/successful-connection-excel';

const ConnectingStageComponent = ({ isConnected, selectedConnection: initialSelectedConnection, onCancel,disconnectConnection }) => {

  const [selectedConnection, setSelectedConnection] = useState(initialSelectedConnection);  

  if(isConnected) {
    return <SuccessfulConnectionExcel selectedConnection={selectedConnection} disconnectConnection={disconnectConnection}/>
  }

  return (
    <div className="connecting-stage-overlay">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="95vh">
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" bgcolor="white" p={4} borderRadius={2} boxShadow={3} marginTop={-20}>
          <div className="connecting-stage-message">
            Connecting to {selectedConnection.description}...
          </div>
          <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
            <span className="connecting-screen-asheet-logo">{AsheetLogoExcelSvg}</span>
            <img className="arrow-icon-connecting-satge" src= {`/assets/back-arrow-animation.svg`} alt=""/>
            <img className="connection-logo-cs" src={`/assets/${selectedConnection.image}`} alt={selectedConnection.description} style={{ width: 46, height: 46, marginTop: '0.6rem', marginLeft: '0.4rem' }} />
          </Box>
          <ASButton className="connecting-screen-connect-button" sx={{ padding: '0.2rem 4rem', borderRadius: "10px" }} primary={true} onClick={onCancel}>
            Cancel
          </ASButton>
        </Box>
      </Box>
    </div>
    
  );
};

export default ConnectingStageComponent;
