import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
    Toolbar,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { NavLink, useLocation } from "react-router-dom";
import { ASheetCompleteLogoSvg, ASheetLogoSvg, HueSheetLogoSvg } from "../../constants/common-svgs";
import { DRAWER_WIDTH, DRAWER_WIDTH_EXP } from "../../shared/header/header";
import { ASConnection } from "../../shared/menu-icons/as-connection";
import { ASDataCollections } from "../../shared/menu-icons/as-data-collections";
import { ASSettings } from "../../shared/menu-icons/as-settings";
import { ASUsers } from "../../shared/menu-icons/as-users";
import "./side-menu.scss";
import { ASBookAnExpert } from "../../shared/menu-icons/as-book-an-expert";
import { useNavigate } from "react-router-dom";

const listItems = [
    {
        name: "Connections",
        iconName: (flag) => <ASConnection className="menu-image" flag={flag} />,
        to: "/connections",
    },
    {
        name: "Data Collections",
        iconName: (flag) => <ASDataCollections className="menu-image" flag={flag} />,
        to: "/data-collections",
    },
    {
        name: "Users and Permissions",
        iconName: (flag) => <ASUsers className="menu-image" flag={flag} />,
        to: "/users",
    },
    {
        name: "Settings",
        iconName: (flag) => <ASSettings className="menu-image" flag={flag} />,
        to: "/settings",
    },
    {
        name: "Book an Expert",
        iconName: (flag) => <ASBookAnExpert className="menu-image" flag={flag} />,
        to: "/book-an-expert",
    },
];

const openedMixin = (theme) => ({
    width: DRAWER_WIDTH_EXP,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(9)} + 0px)`,
    },
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export function SideMenu({ openDrawer, setOpenDrawer }) {

    return (
        <Drawer open={openDrawer} variant="permanent">
            <Toolbar
                sx={{
                    padding: !openDrawer ? 0 : "0 10px",
                }}
            >
                {openDrawer ? (
                    <div className="a-sheet-complete-svg" onClick={() => setOpenDrawer(!openDrawer)}>
                        <div>{ASheetCompleteLogoSvg}</div>
                    </div>
                    // <img
                    //     className="pointer"
                    //     onClick={() => setOpenDrawer(!openDrawer)}
                    //     src="./assets/logo.svg"
                    //     alt=""
                    // />
                ) : (
                    <div className="a-sheet-svg" onClick={() => setOpenDrawer(!openDrawer)}>
                        {ASheetLogoSvg}
                    </div>
                    // <img
                    //     className="pointer menu-image"
                    //     onClick={() => setOpenDrawer(!openDrawer)}
                    //     src="./assets/_logo.svg"
                    //     alt=""
                    // />
                )}
            </Toolbar>

            <Divider />

            <List>
                <ASListItems openDrawer={openDrawer} />
            </List>

            <Divider />
        </Drawer>
    );
}
const ASListItems = ({ openDrawer }) => {
    const navigate = useNavigate();
    const handleOnclick = (to) => {
        navigate(to)
    }
    return (
        <>
            {listItems.map(({ name, iconName, to }) => (
                <ListItem onClick={() => handleOnclick(to)} className='nav-link-item' button key={name} sx={{ paddingTop: '4px', paddingBottom: '4px', paddingLeft: '13px' }} >
                    {
                        <ASNavLink
                            name={name}
                            openDrawer={openDrawer}
                            to={to}
                            iconName={iconName}
                        />
                    }
                </ListItem>
            ))}
        </>
    );
};

const ASNavLink = ({ name, openDrawer, to, iconName }) => {
    const _isActive = useLocation().pathname.includes(to);

    return (
        <NavLink
            className={({ isActive }) => (isActive ?
                (openDrawer ? "is-active link" : "is-active-closed link-closed") :
                (openDrawer ? "link" : 'link-closed')
            )}
            to={to}
        >
            <ListItemIcon
                sx={{
                    padding: openDrawer ? 0 : "10px",
                    alignSelf: "center",
                    minWidth: "fit-content",
                    marginRight: openDrawer ? "10px" : "auto",
                }}
            >
                <span className="menu-svg">{iconName(_isActive)}</span>

            </ListItemIcon>

            {openDrawer && (
                <ListItemText
                    primary={name}
                    primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: "700",
                        fontFamily: 'Poppins'
                    }}
                />
            )}
        </NavLink>
    );
};
