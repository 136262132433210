import { useContext, useState } from "react";
import { selectedMenuItemBackgroundColor } from "../../components/data-collections/collection-parameters/collection-parameters";
import { InputConstants } from "../../constants/input-constant";
import { FormContext } from "../../contexts/form-context";
import { ASTextField } from "../as-text-fields";
import "./as-input.scss";

export function ASInput({
    fieldParameter,
    value,
    errorMsg,
    selectInput = false,
    children,
    multiline,
    rows,
    disabled,
    onFocus,
    isRefreshUrl,
    overwriteValue
}) {
    const type =
        fieldParameter.dataType === InputConstants.stext &&
            fieldParameter.isSecretParameter
            ? InputConstants.password
            : InputConstants.text;
    const [touched, setTouched] = useState(!!value || !!errorMsg);
    const [isInputValid, setInputState] = useState(
        (!!value && !!errorMsg === false) || !errorMsg
    );
    const context = useContext(FormContext);
    const handleChange = (_event) => {
        const inputEvent = {
            value: isRefreshUrl ? value == "" ? overwriteValue : value == _event.target.value ? value : _event.target.value : _event.target.value,
            isValid: false,
            datasourceParameterTypeId: fieldParameter.id,
            errorMsg: errorMsg,
        };
        const regex =
            (!!fieldParameter.validationRegExp || fieldParameter.validateJSON) &&
            (() => {
                if (!!fieldParameter.validateJSON) {
                    try {
                        JSON.parse(inputEvent.value.trim());
                    } catch (e) {
                        return false;
                    }
                    return true;
                }

                const re = new RegExp(fieldParameter.validationRegExp);
                return re.test(inputEvent.value.trim());
            })();

        const isValid = !(
            (fieldParameter.isRequired && !inputEvent.value) ||
            (fieldParameter.maxLength &&
                inputEvent.value.length > +fieldParameter.maxLength) ||
            ((!!fieldParameter.validationRegExp || fieldParameter.validateJSON) && !regex)
        );
        setInputState(isValid);
        inputEvent.isValid = isValid;
        context?.handleChangeInput(fieldParameter, inputEvent);
    };

    return (
        <ASTextField
            className="text-field"
            type={type}
            fullWidth
            error={touched && !isInputValid}
            label={`${fieldParameter.displayName} ${fieldParameter.isRequired ? "*" : ""}`}
            variant="outlined"
            value={value || ""}
            onBlur={(event) => {
                setTouched(true);
                handleChange(event);
            }}
            onChange={handleChange}
            helperText={touched && !isInputValid && errorMsg}
            select={selectInput}
            SelectProps={selectInput ? { MenuProps: { className: 'menu-role-div', sx: selectedMenuItemBackgroundColor } } : {}}
            multiline={multiline}
            rows={rows}
            disabled={disabled}
            onFocus={onFocus ? () => onFocus() : null}
        >
            {children}
        </ASTextField>
    );
}
