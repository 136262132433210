import { useEffect, useState } from 'react'
import { getDataSourceByUID } from "../../../../services/data-source.service";
import SelectConnection from '../../../../components/data-collections/select-connection/select-connection';
import { CollectionParametersState } from '../new-data-collection-state';


const SelectConnectionController = (props) => {

    const { state, setState, getCollectionTypeList, prevStepRef, setParametersState } = props;

    useEffect(() => {
        if (state.connectionUId) {
            getCurrentConnectionDetails(state.connectionUId);
        }
    }, [state.connectionUId])

    // it sets the connection UId state
    const onSelectConnectionHandler = (connection) => {
        let connectionUId = connection?.uId;
        // let connection = JSON.parse(event.target.value);
        setState((prevState) => { return { ...prevState, templateCollectionId: null } });
        setState((prevState) => { return { ...prevState, connectionUId: connectionUId } });
        let sysConnectionTypeId = state?.connectionList.filter(i => i.uId === connectionUId)[0]?.sysConnectionTypeId;
        let baseConnectionTypeId = state?.connectionList.filter(i => i.uId === connectionUId)[0]?.baseConnectionTypeId;
        getCollectionTypeList(sysConnectionTypeId);

        setState((prevState) => { return { ...prevState, sourceName: "", sourceNamePart4:"", sysCollectionSubTypeId:'', returnRawData:false, restRequestMethod:null, uiLabel:'', collectionParameters:[] } });
        resetParameterState();
    }

    const getCurrentConnectionDetails = (connectionUId) => {
        getDataSourceByUID(connectionUId).then((res) => {
            if (res?.data) {
                props.setSelectedConnection(res.data);
            }
        });
    }

    const resetParameterState = () => {
        let parametersState = new CollectionParametersState();

        setParametersState(() => { return { ...parametersState, } });
    }


    return (
        <SelectConnection
            state={state}
            prevStepRef={prevStepRef}
            onSelectConnectionHandler={onSelectConnectionHandler}
        />
    );
}

export default SelectConnectionController;
