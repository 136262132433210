import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./commonConfirmation.scss";
import { ASButton } from "../as-button/as-button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minwidth: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  paddingRight: "12px",
  paddingLeft: "32px",
  paddingBottom: "17px",
  paddingTop: "12px",
  height: 122,
};

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  userDefinedState,
  message,
  noButtonLabel,
  yesButtonLabel,
  isDeleteUserList,
 
}) => {
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {message}
          </Typography>
          <div className="confirmation-btn">
            <ASButton onClick={onClose}>{noButtonLabel}</ASButton>
            <div className="confirm-btn-div"></div>
            <ASButton
            onClick={(event, index,user) => {
              if (isDeleteUserList) {
                onConfirm(event, user, true);
              } else {
                onConfirm(event, userDefinedState?.users[index]?.uId, false);
             
              }
            }}
              primary={true}
            >
              {yesButtonLabel}
            </ASButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
