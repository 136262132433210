import React, { useCallback, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react'; // React Grid Logic
//import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "@ag-grid-community/styles/ag-grid.css";
//import "@ag-grid-community/styles/ag-theme-quartz.css"; // Mandatory CSS required by the grid
// import "ag-grid-community/styles/ag-theme-quartz.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "./ag-grid.scss";
import { debounce, getReadableDateFormate, updateDisplayOrder } from './grid-data-helper';
import { useSelector } from 'react-redux';

function AgGridTable({ rows, columns, isShowHeader = false, isColumnSelection, setState, setColumnList, setIsColumnOrderChanged, isColumnMoveable = false }) {

    const isExpandedView = useSelector((state) => state.asState.isExpandedView);
    let gridColumnKeyData = null;
    let updatedRows = null;

    if(isShowHeader){
        gridColumnKeyData = columns?.map(item => ({ 
            id: isShowHeader ? item.columnName : item,
            headerName: isShowHeader ? item.displayName : item,
            field: isShowHeader ? item.columnName : item,
            description: isShowHeader ? item : item,
            filter: true,
            sortable: true,
            resizable: true,
            headerTooltip: isShowHeader ? item.displayName : item, }));
    }
    else if(isColumnSelection){
        gridColumnKeyData = columns?.filter(item => {
            if (isColumnSelection && !item?.isSelected) {
                return false; // Filter out items that don't satisfy the condition
            }
            return true; // Include items that satisfy the condition
        }).map(item => ({
            id: isColumnSelection ? item.columnName : item,
            headerName: isColumnSelection ? item.displayName : item,
            field: isColumnSelection ? item.columnName : item,
            description: item,
            filter: false,
            sortable: false,
            resizable: true,
            displayOrder: item.displayOrder,
            headerTooltip: isColumnSelection ? item.displayName : item,
            suppressMovable : isColumnMoveable
        }));

        if (gridColumnKeyData !== null && gridColumnKeyData?.length > 0 && rows && updatedRows == null){
            updatedRows = getReadableDateFormate(gridColumnKeyData, rows, true);
        }
        if (gridColumnKeyData){
            gridColumnKeyData = gridColumnKeyData?.sort((a, b) => a.displayOrder - b.displayOrder);
        }
    }
    else{
        gridColumnKeyData = columns?.map(item => ({ 
            id: item,
            headerName: item,
            field: item,
            description: item,
            filter: false,
            sortable: false,
            resizable: true,
            headerTooltip: item,
            suppressMovable: true,
        }));
            if (rows && updatedRows == null){
                updatedRows = getReadableDateFormate(null, rows, false);
             }
    }
    const defaultColDef = {
        flex: 1,
        minWidth: 200,
        cellClass: 'border-right',
        headerTooltip: params => params.colDef.headerName,
    };

    const onColumnMoved = useCallback(debounce((event) => {
            setIsColumnOrderChanged(true);
            const newColumnDefs = event.columnApi.getAllGridColumns().map(col => ({
                id: col.getColId(),
                headerName: col.getColDef().headerName,
                field: col.getColDef().field,
            }));
            if (newColumnDefs && columns && isColumnSelection){ //for cloud collection step grid
                let updatedColumns = updateDisplayOrder(newColumnDefs, columns);
                setState((prevState) => { return { ...prevState, collectionColumnSchema: updatedColumns } });
                setColumnList(updatedColumns);
            }
    }, 300), [columns, isColumnSelection]);

    // const heightCal =   isColumnSelection ? rows.length * 43.06 : rows?.length == 0 ? 150 : rows.length > 10 ? 400 : rows.length * 103;
    return (
        <div style={{ height: isExpandedView ? 'calc(-228px + 100vh)' : 'calc(100vh - 370px)' , width: '100%', minHeight: '100%' }}>
         {/* <div style={{ height: rows?.length == 0 ? 150 : rows.length > 10 ? 400 : rows.length * 103, width: '100%' }}> */}
            <div className={"ag-theme-alpine"} style={{ width: '100%', height: '100%' }}>
                <AgGridReact rowData={updatedRows ? updatedRows : rows} columnDefs={gridColumnKeyData} suppressDragLeaveHidesColumns={true} defaultColDef={defaultColDef} onColumnMoved={onColumnMoved}/>
            </div>
        </div>
    );
}
export default AgGridTable;
