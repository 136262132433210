import React from "react";
import { ASButton } from "../../../../shared/as-button/as-button";
import "../filterPopup/filterPopup.scss";
import { ASTextField } from "../../../../shared/as-text-fields";
import InputLabel from "@mui/material/InputLabel";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import "./limitPopup.scss"

export default function LimitPopup(props) {
    const {
        openAddCondition,
        limitObject,
        handleOnChangeLimitInput,
        addLimitCondition,
        deleteLimitCondition,
        addNewCondition,
        executeData,
        openConditional,
        isDisabled,
        limitData,
        closeLimitModal
    } = props;

    console.log(limitData);

    return (
        <>
            {(openConditional) && (
                <div className="limit-add-filter-modal">
                    <div
                        className="filter-item-container"
                    >
                        {openAddCondition && (
                            <div className="filter-paper-div">
                                {limitData && <div className="fields-group-label">
                                    <div className="limit-field-label-div">
                                        <InputLabel sx={{padding: "0px 12px 18px 12px", fontSize:"14px !important"}}>Limit :</InputLabel>
                                        <label className="fields-group-label-value">
                                            {limitData?.limit} rows
                                        </label>
                                    </div>
                                    <div className="delete-icon-div">
                                        <DeleteForeverOutlinedIcon
                                            onClick={() => deleteLimitCondition()}
                                        />
                                    </div>
                                </div>}

                                {(!limitData) &&
                                    (
                                        <div>
                                            <div className="limit-fields-group">
                                                <div>
                                                    <div className="limit-add-condition-value-div">
                                                        <InputLabel>Set Limit</InputLabel>
                                                        <ASTextField autoComplete="off" type="number" value={limitObject?.limit} onChange={(e) => handleOnChangeLimitInput(e, "limit")}
                                                                    InputProps={{
                                                                       className:"fields-inputs",
                                                                       sx:{padding:"3px 0px 1px 3px !important;"}
                                                                    }}
                                                                    inputProps={{
                                                                        min: 0
                                                                    }}
                                                                    sx={{ width: "100%" }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="add-condition-button-div">
                                                <div className="cancel-button-div">
                                                    <button
                                                        className="cancel-link-button"
                                                        onClick={() => closeLimitModal()}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>

                                                <ASButton
                                                    onClick={() => addLimitCondition()}
                                                    sx={{
                                                        color: "white !important",
                                                        backgroundColor: "#6B859E !important",
                                                        borderRadius: "6px !important",
                                                        boxShadow: "0px 2px 4px #6B859E !important",
                                                        padding: "5px 25px !important",
                                                    }}
                                                    // disabled={isDisabled}
                                                >
                                                    Ok
                                                </ASButton>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )}
                    </div>

                    {limitData && !addNewCondition && (
                        <div className="apply-changes-div" style={{ justifyContent: "flex-end" }}>
                            <div className="cancel-add-button-div">
                                <div className="cancel-button-div">
                                    <button
                                        className="cancel-link-button"
                                        onClick={() => closeLimitModal()}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <ASButton
                                        variant="contained"
                                        sx={{
                                            mr: 1,
                                            marginRight: "0px",
                                            textTransform: "none",
                                            borderRadius: "7px !important",
                                        }}
                                        primary={true}
                                        onClick={() => {
                                            closeLimitModal();
                                            executeData();
                                        }}
                                    >
                                        Apply Changes
                                    </ASButton>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
