import { ASInput } from "../../../shared/as-inputs/as-input";
import { ASTooltip } from "../../../shared/as-tooltip/as-tooltip";
import TooltipData from "../../common/tooltip-en-us/tooltip-en-us.json"; 
import { useASContext } from "../../../controllers/context-api/as-context";
import { FormControlLabel } from "@mui/material";
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import Checkbox from '@mui/material/Checkbox';
import { RestApiNavData } from "../../../services/data-source.utility.service";

export const CommonConnectionInputs = (props) => {
    const { loginUrlParameters, formData, apiEndPointParameters, requireProxyConnector, onCheckRequireProxyConnector, selectedAuth} = props;
    const asContext = useASContext();

    return (
        <div className="w-75">
            <div className="d-flex w-100">
                
                {apiEndPointParameters && <div className="w-50">
                    <div className="pe-2">
                        <ASInput
                            value={         (
                                !!formData &&
                                !!formData[apiEndPointParameters.parameterType] &&
                                formData[apiEndPointParameters.parameterType]
                            ).value}
                            fieldParameter={{
                                ...apiEndPointParameters,
                                displayName: apiEndPointParameters.displayName || "Base Endpoint URL",
                            }}
                            errorMsg={
                                (
                                    !!formData &&
                                    !!formData[apiEndPointParameters.parameterType] &&
                                    formData[apiEndPointParameters.parameterType]
                                ).errorMsg
                            }
                        />
                    </div>
                </div>}

                <div>
                    <ASTooltip text={TooltipData.Connection.BaseAPIEndpoint} />
                </div>

                {asContext.templateCompany.isTemplateCompany && selectedAuth === RestApiNavData[4].label && <div className="w-50 d-flex justify-content-end align-items-center">
                    <div className="pe-2">
                        <FormControlLabel control={
                                        <Checkbox checked={requireProxyConnector}
                                        onChange={(event) => onCheckRequireProxyConnector(event)}
                                        sx={muiCheckboxStyle}
                                        />
                                        }
                                        label = "Require Proxy Connector"
                            />
                    </div>
                </div>}

            </div>
            
        </div>
    );
};
