import { axios } from "../core/axios.interceptor";

export async function changePassword(form) {
  const response = await axios.put(`/accounts/password`, form);
  return response;
}

export async function checkPasswordExistsApi(userName) {
  const response = await axios.get(`/accounts/password/exists?userName=${userName}`);
  return response;
}