import React, { useEffect, useState } from "react";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ASheetCompleteLogoSvg } from "../../constants/common-svgs";
import { HiMenuAlt1 } from "react-icons/hi";
import "./layout-excel.scss";
import { useASContext } from "../context-api/as-context";
import { useNavigate } from "react-router-dom";

const LayoutExcel = ({ children }) => {
  const asContext = useASContext();
  const [isBackIconVisible, setIsBackIconVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsBackIconVisible(asContext.plugin.connectionSelected);
  }, [asContext.plugin]);

  const handleBackClick = () => {
    navigate('/connections/add-connection-excel', { state: { selectedConnectionState: null } }); // Pass selectedConnection as null
    asContext.plugin.setConnectionSelected(false);
  };

  return (
    <Box sx={{ display: "flex", height: "5vh" }}>
      <CssBaseline />
      <AppBar
        className="excel-layout-header"
        position="static"
        sx={{
          backgroundColor: "#ffffff",
          borderBottom: "0.1px solid #ccc",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          {isBackIconVisible ? (
              <img 
              src={`/assets/back-button.svg`} 
              alt="Back" 
              onClick={handleBackClick}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ fontSize: "1.8rem" }}
            >
              <HiMenuAlt1 style={{ color: "black", margin: "5 0 0 -8" }} />
            </IconButton>
          )}
          <Typography
            variant="h2"
            component="div"
            className="asheet-logo-excel"
            sx={{ flexGrow: 1, textAlign: "right" }}
          >
            {ASheetCompleteLogoSvg}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default LayoutExcel;
