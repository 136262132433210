import { Paper, Typography } from "@mui/material";
import { ConnectingAnimationElement } from "../../../constants/common-svgs";
import { ASButton } from "../../../shared/as-button/as-button";
import './test-data.scss';
import MessageToaster from "../../../shared/message-toaster/message-toaster";
import DataGridTable from "../../common/data-grid/data-grid";
import AgGridTable from "../../common/ag-grid/ag-grid";
import styles from "./../../../styles/_exports.module.scss";
import ExpandableView from "../../common/expandableView/expandableView";
import ReactJson from "react-json-view";

const TestData = (props) => {
    const { testCollectionError, name, isTestingConnection, testedDataList, testedDataColumnKeys, collectionColumnSchema, returnRawData } = props.state;
    const { activeTab, setActiveTab, isChangedDataParams, isShowHeader, isTestedData } = props;


    return (
        <Paper className='test-data-paper' sx={{width: "1250px"}} >
            <ExpandableView>
            {/* <Typography>{name}</Typography>

            {
                isTestingConnection &&
                <div className="connecting-spinner-div">
                    {ConnectingAnimationElement}

                    <Typography className="connecting-dots">Connecting</Typography>
                </div>
            }
            {
                testCollectionError &&
                <MessageToaster errorMessage={testCollectionError} />
            }
            {

                ((testedDataList.length > 0 && activeTab === 0) || isTestedData) && (
                    // <DataGridTable
                    //     rows={testedDataList}
                    //     columns={testedDataColumnKeys}
                    // />
                    <AgGridTable
                        rows={isShowHeader ? [] : testedDataList}
                        columns={isShowHeader ? collectionColumnSchema : testedDataColumnKeys}
                        isShowHeader={isShowHeader}
                    />
                )
            }

            <div className={isTestingConnection ? 'test-connection-div' : 'test-btn'}>

                {props.isAuthorizeAgain &&
                    <ASButton variant="contained" onClick={props.handleAuthorizeAgain} primary={true} disabled={isTestingConnection}>
                        Authorize Again
                    </ASButton>}

                <ASButton onClick={props.testDataCollection} buttonType="button-basic" disabled={isTestingConnection}>
                    {isChangedDataParams ? 'Run Test Again' :'Test Collection'}
                </ASButton>

            </div> */}

            <div className="test-data-container">
                <div className="test-data-header">
                    <Typography>Test Collection</Typography>
                    <Typography
                        className="font-weight-bold"
                        sx={{
                            marginBottom: 0, color: styles.secondaryColor, fontFamily: styles.poppins,
                            fontSize: '20px', fontWeight: 'bold'
                        }}
                        gutterBottom
                        variant="body1"
                        component="div"
                    >
                        {name}
                    </Typography>
                </div>

                <div className={testCollectionError ? 'test-button-div-width' : 'test-button-div'}>

                    {
                        // Check This Css error-div
                        testCollectionError ?
                        <div className="test-data-message-div"><MessageToaster errorMessage={testCollectionError} isTestData={true}/></div>
                    :
                    <div className={isTestingConnection ? 'test-connection-div' : 'test-btn'}>
                        {props.isAuthorizeAgain &&
                            <ASButton variant="contained" onClick={props.handleAuthorizeAgain} primary={true} disabled={isTestingConnection}>
                                Authorize Again
                            </ASButton>}

                        <ASButton onClick={props.testDataCollection} buttonType="button-basic" disabled={isTestingConnection}>
                            {isChangedDataParams ? 'Run Test Again' :'Test Collection'}
                        </ASButton>
                    </div>
                    }
                </div>
            </div>

            {
                returnRawData && testedDataList.length > 0 ? (    
                        <ReactJson src={testedDataList} theme="monokai" name={false}  style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'auto', marginTop: '5px' }} displayDataTypes={false}/>
                )
                : ((testedDataList.length > 0 && activeTab === 0) || isTestedData) && (
                    <div className="test-data-table">
                        <AgGridTable
                            rows={isShowHeader ? [] : testedDataList}
                            columns={isShowHeader ? collectionColumnSchema : testedDataColumnKeys}
                            isShowHeader={isShowHeader}
                        />
                    </div>
                )  
            }

            {testCollectionError &&
                <div className={isTestingConnection ? 'test-connection-div' : 'test-btn'}>
                    {props.isAuthorizeAgain &&
                        <ASButton variant="contained" onClick={props.handleAuthorizeAgain} primary={true} disabled={isTestingConnection}>
                            Authorize Again
                        </ASButton>}

                    <ASButton onClick={props.testDataCollection} buttonType="button-basic" disabled={isTestingConnection}>
                        {isChangedDataParams ? 'Run Test Again' :'Test Collection'}
                    </ASButton>
                </div>}
                </ExpandableView>
        </Paper>
    );
}

export default TestData;