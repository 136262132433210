import React from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const ConnectionMessage = ({ title, firstPart, secondPart, buttonText, buttonLink}) => {
  return (
    <div>
      <div sx={{ width: '50%', backgroundColor: '#00000000' }}>
        <CardContent className="error-div" sx={{ marginTop: '5.5%' }}>
          <Typography variant="h4" sx={{ fontSize: 24, textAlign: "center" }}>
            {title}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: 24, textAlign: "center" }}>
            {firstPart}
            <Link to={buttonLink} className="custom-link" style={{textDecoration: 'none', color: "#1616D2"}}>
              {buttonText}
            </Link>
            {secondPart}
          </Typography>
        </CardContent>
      </div>
    </div>
  );
};

export default ConnectionMessage;
