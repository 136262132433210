import { Box, Button } from "@mui/material";
import "./successful-connection-excel.scss";
import EastIcon from '@mui/icons-material/East';
import { AsheetLogoExcelSvg } from '../../../constants/common-svgs';
import { ASButton } from '../../../shared/as-button/as-button';

const SuccessfulConnectionExcel = ({ selectedConnection,disconnectConnection }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="95vh">
      <div>
        <div className='succesful-connection-message'>Successfully connected to {selectedConnection.description}</div>
        <Box display="flex" alignItems="center" justifyContent="center" mb={2} marginLeft={-0.5}>
            <span className="connecting-screen-asheet-logo">{AsheetLogoExcelSvg}</span>
            <div className='arrow-icon'><EastIcon style={{ marginLeft: '13px' }} /></div>
            <img src={`/assets/${selectedConnection.image}`} alt="Connection Logo" style={{ width: 46, height: 46, marginTop: '0.6rem', marginLeft: '0.4rem' }} />
        </Box>
        <ASButton sx={{ padding: '0.2rem 1.5rem', borderRadius: "10px", textTransform:'none', marginLeft: '1.2rem' }} primary={true}>
          Add New Collection
        </ASButton>
        <br/>
        <Button className="disconnect-button" style={{textTransform: 'none'}} stylecolor="secondary" onClick={disconnectConnection}>
          Disconnect
        </Button>
      </div>
    </Box>
  );
};

export default SuccessfulConnectionExcel;
